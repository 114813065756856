import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  useNotify,
  SimpleForm,
  SelectInput,
  useUpdate,
  RaRecord,
  useRefresh,
  BooleanInput,
  FormDataConsumer,
  maxLength,
  TextField,
  ReferenceField,
  Labeled,
  useGetList,
  useGetOne,
} from 'react-admin';
import IconContentEdit from '@mui/icons-material/Edit';
import IconCancel from '@mui/icons-material/Cancel';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  Alert,
  AlertTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  Typography,
} from '@mui/material';
import { cleanEmptyValues } from '../../utils/general';

export const TAKE_STATUS_CHOICES = [
  { id: 'PENDING', name: '⏱ Pending' },
  { id: 'REJECTED', name: '⛔️ Rejected' },
  { id: 'VETTED', name: '✅ Vetted' },
];

export const TAKE_STATUS_REASON_CHOICES = [
  { id: 'POOR_QUALITY', name: 'Poor Quality' },
  { id: 'FRAUD', name: 'Fraud' },
  { id: 'NO_CONSENT', name: 'No consent' },
  { id: 'OTHER', name: 'Other' },
];

const STATUS_EMOJIES = {
  REJECTED: '⛔️',
  VETTED: '✅',
  PENDING: '⏱',
};

export const getTakeStatusEmoji = (status: string) =>
  // @ts-ignore
  status && status in STATUS_EMOJIES ? STATUS_EMOJIES[status] : '?';

const QuickEditTake: React.FC<{
  record: RaRecord;
  showEmoji?: boolean;
  isDisabled?: boolean;
  disableTakeVetting: boolean;
}> = ({
  record,
  showEmoji = false,
  isDisabled = false,
  disableTakeVetting,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [targetCampaignId, setTargetCampaignId] = useState<string | null>(null);
  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const { data: videoTake, refetch } = useGetOne(
    'videotakes',
    { id: record.id },
    { enabled: !!record.id && showDialog },
  );

  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const onSubmit = async (data: FieldValues) => {
    try {
      await update(
        'videotakes',
        { id: record.id, data: cleanEmptyValues(data), previousData: record },
        { mutationMode: 'pessimistic', returnPromise: true },
      );
      refresh();
      notify('Video Take updated', { type: 'success' });
      handleCloseClick();
    } catch (err) {
      console.error(err);
      notify('Video Take update failed', { type: 'error' });
    } finally {
      refetch();
    }
  };

  const { data: campaigns, isLoading: isCampaignLoading } = useGetList(
    'campaigns',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'desc' },
    },
  );

  useEffect(() => {
    if (videoTake?.videoScene?.videoSession?.campaignId)
      setTargetCampaignId(videoTake?.videoScene?.videoSession?.campaignId);
  }, [videoTake]);

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={isDisabled}
        label={showEmoji ? getTakeStatusEmoji(record?.status) : 'Edit'}
      >
        {showEmoji ? <span /> : <IconContentEdit />}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit VideoTake"
      >
        <DialogTitle>Edit VideoTake</DialogTitle>
        <SimpleForm
          record={record}
          onSubmit={onSubmit}
          redirect={false}
          toolbar={false}
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <SelectInput
                  source="status"
                  choices={TAKE_STATUS_CHOICES}
                  validate={required()}
                  isRequired
                  disabled={disableTakeVetting}
                />
                <SelectInput
                  source="statusReason"
                  choices={TAKE_STATUS_REASON_CHOICES}
                  emptyValue={null}
                />
                <TextInput source="statusText" fullWidth />
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <>
                      <BooleanInput
                        source="sendRetakeEmail"
                        defaultValue={true}
                        disabled={formData.status !== 'REJECTED'}
                      />
                      <TextInput
                        source="retakeNote"
                        label="Note to creator"
                        multiline
                        fullWidth
                        validate={[maxLength(2048)]}
                        disabled={
                          formData.status !== 'REJECTED' ||
                          formData.sendRetakeEmail !== true
                        }
                      />
                    </>
                  )}
                </FormDataConsumer>
                <Labeled label="Updated By">
                  <ReferenceField
                    label="Updated By"
                    source="statusUpdatedById"
                    reference="users"
                    link={false}
                  >
                    <TextField source="email" />
                  </ReferenceField>
                </Labeled>
              </Grid>
              <Grid item xs={6}>
                {record.autoStatus && (
                  <Alert severity="info" icon={'🤖'}>
                    <AlertTitle>Simon says...</AlertTitle>
                    <div>
                      <strong>Status: </strong>
                      <span>{record.autoStatus}</span>
                    </div>
                    <div>
                      <strong>Status Reason: </strong>
                      <span>{record.autoStatusReason}</span>
                    </div>
                    <div>
                      <strong>Status Text: </strong>
                      <span>{record.autoStatusText}</span>
                    </div>
                    <Button
                      label="Apply"
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        onSubmit({
                          status: record.autoStatus,
                          statusReason: record.autoStatusReason,
                          statusText: record.autoStatusText,
                          sendRetakeEmail: true,
                        })
                      }
                    />
                  </Alert>
                )}
                <Paper sx={{ padding: '15px', marginTop: '10px' }}>
                  <Typography variant="body1">Use as inspiration:</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="target-campaign-select-label">
                      Target Campaign Id
                    </InputLabel>
                    <Select
                      labelId="target-campaign-select-label"
                      id="target-campaign-select"
                      value={targetCampaignId}
                      label="Age"
                      onChange={e => setTargetCampaignId(e.target.value)}
                    >
                      {!isCampaignLoading &&
                        campaigns &&
                        campaigns?.length > 0 &&
                        campaigns?.map(c => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              label="Cancel"
              onClick={handleCloseClick}
              disabled={isLoading}
            >
              <IconCancel />
            </Button>
            <SaveButton saving={isLoading} disabled={isLoading} />
          </DialogActions>
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default QuickEditTake;
