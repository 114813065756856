import React from 'react';

const StepPreview: React.FC<{
  step: any;
  width: number;
  className?: any;
  enableFakeCamera?: boolean;
  enablePreview?: boolean;
}> = ({ width, step, className, enableFakeCamera, enablePreview }) => {
  const height = width / (9 / 16);

  let paramSettings = {};
  if (enableFakeCamera) {
    paramSettings = { ...paramSettings, fake: true };
  }
  if (enablePreview) {
    paramSettings = { ...paramSettings, preview: true };
  }

  const searchParams = new URLSearchParams(paramSettings);

  return (
    <iframe
      style={{ pointerEvents: enableFakeCamera ? 'none' : 'initial' }}
      className={className}
      title="camera-url"
      key={step.updatedAt} // updatedAt is used to trigger change detection after making changes to the campaign step
      src={`${process.env.REACT_APP_CAMERA_URL}/${step.campaignId}/step/${step.id}?${searchParams}`}
      height={height}
      width={width}
    />
  );
};

export default StepPreview;
