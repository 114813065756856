/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { email as emailValidator, useGetList, useNotify } from 'react-admin';
import {
  Autocomplete,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';

type Props = {
  accountId?: string;
  onChange: (value: string | null) => void;
  selectedEmails: string[];
};
const UserEmailsChipInput = ({
  accountId,
  onChange,
  selectedEmails,
}: Props) => {
  const notify = useNotify();
  const updateQueryValue = (value: string) => setQueryValue(value);

  const updateDebounce = debounce(updateQueryValue, 500);
  const [queryValue, setQueryValue] = useState('');

  const { data, isLoading, isError, error } = useGetList(
    'users',
    {
      filter: { accountId, q: queryValue },
      sort: { field: 'email', order: 'ASC' },
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    },
  );

  const onInputChange = (value: any) => {
    // newValue can be a user object or a string from the text field.
    const currValue = value?.email || value;
    if (currValue) {
      if (currValue.length > 0) {
        const emailCheck = emailValidator(`${currValue} is not an email`)(
          currValue,
        );
        if (emailCheck !== undefined) {
          const message =
            typeof emailCheck === 'string' ? emailCheck : emailCheck?.message;
          notify(message, {
            type: 'error',
          });
          setQueryValue('');
          return;
        }
        onChange(currValue);
      }
      setQueryValue('');
      return '';
    }
  };
  useEffect(() => {
    if (!isLoading && isError) {
      notify(error?.message || error?.toString() || "Couldn't fetch users", {
        type: 'error',
      });
    }
  }, [error, isError]);

  return (
    <Grid
      container
      direction="column"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      <Grid justifyContent={'center'}>
        <InputLabel htmlFor="component-text">Addressees</InputLabel>
        <Grid item display={'flex'} alignItems={'center'}>
          <Autocomplete
            freeSolo
            style={{ width: '50%' }}
            loading={isLoading}
            resource="users"
            options={data || []}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option.id}>
                <span>{option?.email || ''}</span>
              </li>
            )}
            onChange={(event: any, newValue) => onInputChange(newValue)}
            clearOnBlur={true}
            blurOnSelect={true}
            onReset={() => setQueryValue('')}
            getOptionLabel={option => option.email || ''}
            renderInput={params => (
              <TextField
                {...params}
                label="Emails"
                onChange={e => updateDebounce(e.target.value)}
              />
            )}
          />
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              onChange(null);
              setQueryValue('');
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container direction="row">
        {selectedEmails.length > 0 &&
          selectedEmails.map((data: string) => {
            return (
              <ListItem key={`${data}_li`}>
                <Chip
                  id={data}
                  key={`${data}_chip`}
                  label={data}
                  onDelete={() => {
                    onChange(data);
                  }}
                />
              </ListItem>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default UserEmailsChipInput;
