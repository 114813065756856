import { useRecordContext } from 'ra-core';
import {
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from 'react-admin';

export const CreatorBatchList = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField
      reference="batches"
      target="member"
      filter={{ memberEmail: record?.email }}
    >
      <Datagrid>
        <ReferenceField source="campaignId" reference="campaigns">
          <TextField source="title" />
          <TextField source="name" resource="brands" />
        </ReferenceField>
        <DateField source="createdAt" />
        <TextField source="status" />
      </Datagrid>
    </ReferenceManyField>
  );
};
