import * as React from 'react';
import {
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  FunctionField,
  ReferenceField,
  useRecordContext,
  Button,
  useNotify,
  ArrayField,
  SelectField,
  SingleFieldList,
  List,
  Pagination,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  SelectInput,
  Exporter,
  downloadCSV,
} from 'react-admin';
import { uniqBy } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { blue, red, green, grey, yellow } from '@mui/material/colors';
import { format } from 'date-fns';
import { CreatorDisplay } from './CreatorDisplay';
import Chip from '@mui/material/Chip';
import { Send } from '@mui/icons-material';
import { resendIncentive } from '../../../providers/dataProvider';
import { TAKE_STATUS_CHOICES } from '../../VideoSessions/QuickEditTake';
import { IncentiveTypeChoices } from '../IncentiveSetup/IncentiveSetup';
import { endOfMonth, startOfMonth } from 'date-fns';
import jsonExport from 'jsonexport/dist';

const chipStyles = makeStyles({
  chipSuccess: { backgroundColor: green[500], color: 'white' },
  chipFailure: { backgroundColor: red[500], color: 'white' },
  chipPending: { backgroundColor: yellow[800], color: 'white' },
  chipNotSet: { backgroundColor: grey[500], color: 'white' },
  chipTimeout: { backgroundColor: 'black', color: 'white' },
  chipOther: { backgroundColor: blue[500], color: 'white' },
});

const BooleanDateIndicator: React.FC<{
  record?: any;
  label: string;
  booleanField: string;
  dateField: string;
  [key: string]: any;
}> = props => (
  <FunctionField
    render={(record: any) => {
      return (
        <div>
          <BooleanField
            record={record}
            source={props.booleanField}
            looseValue
          />
          <DateField
            source={props.dateField}
            record={record}
            sortable={false}
            showTime
          />
        </div>
      );
    }}
    // {...props}
    label={props.label}
  ></FunctionField>
);

const inconsistentStateDisplay = {
  id: 'Inconsistent State',
  style: () => chipStyles().chipNotSet,
};

const statesToDisplay = [
  {
    desiredState: 'CANCELLED',
    currentState: 'CANCELLED',
    id: 'Cancelled',
    style: () => chipStyles().chipFailure,
  },
  {
    desiredState: 'CANCELLED',
    currentState: 'TIMEDOUT',
    id: 'Cancellation Timed Out',
    style: () => chipStyles().chipTimeout,
  },
  {
    desiredState: 'CANCELLED',
    currentState: null,
    id: 'Pending Cancellation',
    style: () => chipStyles().chipPending,
  },
  {
    desiredState: 'DELIVERED',
    currentState: 'DELIVERED',
    id: 'Delivered',
    style: () => chipStyles().chipSuccess,
  },
  {
    desiredState: 'DELIVERED',
    currentState: 'TIMEDOUT',
    id: 'Delivery Timed Out',
    style: () => chipStyles().chipTimeout,
  },
  {
    desiredState: 'DELIVERED',
    currentState: 'BEING_CANCELLED',
    ...inconsistentStateDisplay,
  },
  {
    desiredState: 'DELIVERED',
    currentState: 'CANCELLED',
    id: 'Cancelled',
    style: () => chipStyles().chipFailure,
  },
  {
    desiredState: 'DELIVERED',
    currentState: null,
    id: 'Pending Delivery',
    style: () => chipStyles().chipPending,
  },
  {
    desiredState: null,
    currentState: null,
    ...inconsistentStateDisplay,
  },
  {
    desiredState: 'PRODUCED',
    currentState: 'PRODUCED',
    id: 'Produced',
    style: () => chipStyles().chipSuccess,
  },
];

export const StatusField = (props: any) => {
  const record = useRecordContext(props);

  let displayRecord = statesToDisplay.find(entry => {
    return (
      entry.desiredState === record?.desiredState &&
      entry.currentState === record?.currentState
    );
  });

  if (!displayRecord) {
    displayRecord = statesToDisplay.find(entry => {
      return (
        entry.desiredState === record?.desiredState &&
        entry.currentState === null
      );
    });
  }

  if (!displayRecord) {
    displayRecord = statesToDisplay.find(entry => {
      return entry.desiredState === null && entry.currentState === null;
    });
  }

  return <Chip label={displayRecord?.id} className={displayRecord?.style()} />;
};

export const CreatorField = (props: any) => {
  const record = useRecordContext(props);
  return record?.videoScene?.videoSession?.creator ? (
    <CreatorDisplay creator={record?.videoScene?.videoSession?.creator} />
  ) : (
    <span>-</span>
  );
};

const ResendButton = (props: any) => {
  const { id, currentState, type } = useRecordContext(props);
  const notify = useNotify();
  return (
    <Button
      label="Resend"
      disabled={currentState !== 'DELIVERED' || type === 'OTHER'}
      onClick={async () => {
        try {
          await resendIncentive(id.toString());
          notify('Incentive sent!', { type: 'success' });
        } catch (err) {
          console.error(err);
          notify(`Failed to send incentive`, { type: 'error' });
        }
      }}
    >
      <Send />
    </Button>
  );
};

export const IncentivesDatagrid: React.FC = () => (
  <Datagrid
    contentEditable={false}
    empty={<div>No incentives issued for this campaign</div>}
  >
    <StatusField label="Status" />
    <TextField source="type" sortable={false} label="Type" emptyText={'-'} />
    <TextField
      source="incentiveSetting.incentiveProvision"
      label="Incentive Condition"
    />
    <ReferenceField
      label="Video Session"
      source="videoScene.videoSessionId"
      link="show"
      reference="videosessions"
    >
      <TextField source="id" />
    </ReferenceField>
    <ArrayField source="videoScene.takes" label="Video Qualification">
      <SingleFieldList linkType={false}>
        <SelectField source="status" choices={TAKE_STATUS_CHOICES} />
      </SingleFieldList>
    </ArrayField>
    <CreatorField label="Creator" />
    <TextField source="metadata.gcClaimCode" label="Claim Code" />
    <TextField source="metadata.value" label="Value" />
    <TextField source="metadata.currency" label="Currency" />
    <TextField
      source="verificationCode"
      sortable={false}
      label="Verification Code"
      emptyText={'-'}
    />
    <BooleanDateIndicator
      label="Delivered"
      dateField="deliveredAt"
      booleanField="deliveredAt"
    />
    <BooleanDateIndicator
      label="Cancelled"
      dateField="cancelledAt"
      booleanField="cancelledAt"
    />
    <ResendButton />
  </Datagrid>
);

const exporter: Exporter = (incentives: Record<string, any>[]) => {
  const incentivesForExport = uniqBy(incentives, 'id').map(incentive => {
    return {
      'Video Session Id': incentive?.videoScene?.videoSessionId,
      Brand: `${incentive?.campaign?.brand?.name || ''} (ID:${
        incentive?.campaign?.brand?.id
      })`,
      Campaign: `${incentive?.campaign?.title || ''} (ID:${
        incentive?.campaign?.id
      })`,
      'Custom Params': incentive?.videoScene?.videoSession?.customParams
        ? JSON.stringify(incentive?.videoScene?.videoSession?.customParams)
        : '',
      'Creator Email': incentive?.videoScene?.videoSession?.creator?.email,
      'Creator Full Name':
        incentive?.videoScene.videoSession?.creator?.fullname,
      'Incentive type': incentive?.type,
      Amount: incentive?.metadata?.value,
      Currency: incentive?.metadata?.currency,
      'Current State': incentive?.currentState,
      'Delivered At': incentive?.deliveredAt
        ? format(new Date(incentive?.deliveredAt), 'dd/MM/yyyy hh:mm:ss a')
        : '',
    };
  });

  jsonExport(
    incentivesForExport,
    {
      headers: [
        'Video Session Id',
        'Brand',
        'Campaign',
        'Custom Params',
        'Creator Email',
        'Creator Full Name',
        'Incentive type',
        'Amount',
        'Currency',
        'Current State',
        'Delivered At',
      ],
    },
    (err, csv) => {
      downloadCSV(csv, 'incentives');
    },
  );
};

export const IncentivesList: React.FC = () => (
  <List
    exporter={exporter}
    filters={[
      <SelectInput
        choices={IncentiveTypeChoices}
        key="type"
        source="type"
        label="Type"
        alwaysOn
      />,
      <ReferenceInput
        key="campaignId"
        source="campaignId"
        reference="campaigns"
        alwaysOn
      >
        <AutocompleteInput key="campaignId_input" optionText="title" />
      </ReferenceInput>,
      <ReferenceInput
        key="brandId"
        source="brandId"
        reference="brands"
        alwaysOn
      >
        <AutocompleteInput key="brandId_input" optionText="name" />
      </ReferenceInput>,
      <ReferenceInput
        key="creatorId"
        source="creatorId"
        reference="creators"
        alwaysOn
      >
        <AutocompleteInput
          key="creatorId_input"
          optionText={creator => creator.fullname || creator.email}
        />
      </ReferenceInput>,
      <DateInput key="fromDate" source="fromDate" label="From" alwaysOn />,
      <DateInput key="toDate" source="toDate" label="To" alwaysOn />,
    ]}
    filterDefaultValues={{
      fromDate: startOfMonth(new Date()),
      toDate: endOfMonth(new Date()),
    }}
    resource="incentives"
    perPage={25}
    pagination={<Pagination />}
  >
    <Datagrid contentEditable={false} empty={<div>No incentives</div>}>
      <StatusField label="Status" />
      <TextField source="type" label="Type" emptyText={'-'} />
      <ReferenceField
        label="Brand"
        sortable={false}
        source="campaign.brandId"
        link="show"
        reference="brands"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Video Session"
        sortable={false}
        source="videoScene.videoSessionId"
        link="show"
        reference="videosessions"
      >
        <TextField source="id" />
      </ReferenceField>

      <ArrayField
        sortable={false}
        source="videoScene.takes"
        label="Video Qualification"
      >
        <SingleFieldList linkType={false}>
          <SelectField source="status" choices={TAKE_STATUS_CHOICES} />
        </SingleFieldList>
      </ArrayField>
      <CreatorField label="Creator" />
      <TextField
        sortable={false}
        source="metadata.gcClaimCode"
        label="Claim Code"
      />
      <TextField sortable={false} source="metadata.value" label="Value" />
      <TextField sortable={false} source="metadata.currency" label="Currency" />
      <TextField
        source="verificationCode"
        sortable={false}
        label="Verification Code"
        emptyText={'-'}
      />
      <BooleanDateIndicator
        label="Delivered"
        dateField="deliveredAt"
        booleanField="deliveredAt"
      />
      <BooleanDateIndicator
        label="Cancelled"
        dateField="cancelledAt"
        booleanField="cancelledAt"
      />
      <ResendButton />
    </Datagrid>
  </List>
);
