// eslint-disable-next-line
export default {
  // called when the user attempts to log in
  login: ({ username, password }: any) => {
    const request = new Request(
      `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
      {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem("auth", JSON.stringify(auth));
        
        // decoding base64 to get user json string
        const user = atob(auth.access_token.split(".")[1]);

        // saving user role
        const {role} = JSON.parse(user)
        localStorage.setItem("user_role", role);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("user_role");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }: any) => {
    if (status === 401) {
      localStorage.removeItem("auth");
      localStorage.removeItem("user_role");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: (params: any) => {
    return Promise.resolve(localStorage.getItem("user_role"));
  },
};
