import {
  BooleanInput,
  Create,
  Edit,
  FormDataConsumer,
  Link,
  minLength,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { facingModeChoices, SupportedLocalesChoices } from '../consts';
import {
  cleanEmptyValues,
  EmptyTextualFieldProps,
  useQuery,
} from '../utils/general';

const httpUrlPattern =
  '(https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*))';
const deepLinkPattern = '(\\w+?:\\/\\/[-a-zA-Z0-9()@:%_+.~#?&//=]*)';
const placeholderPattern = '\\{\\{c_brand_website\\}\\}';

const combinedUrlFieldPattern = `(${httpUrlPattern}|${deepLinkPattern}|${placeholderPattern})`;

export const validateDestURLOrMobileDeepLink = regex(
  new RegExp(combinedUrlFieldPattern),
  'Must be a valid URL, mobile deep link, or {{c_brand_website}}',
);

const PLATFORM_CHOCIES = [
  { id: 'DESKTOP', name: 'Desktop' },
  { id: 'IOS', name: 'iOS' },
  { id: 'ANDROID', name: 'Android' },
];

const PURPOSE_CHOICES = [
  { id: 'CONSENT', name: 'Consent' },
  { id: 'UPLOAD', name: 'Upload Video' },
  { id: 'UPLOAD_IMAGE', name: 'Upload Image' },
  { id: 'UPLOAD_MEDIA', name: 'Upload Video/Image' },
  { id: 'CREATOR', name: 'Subscribe Creator' },
  { id: 'TOS', name: 'Terms of Service' },
];

const ButtonTitle: React.FC = (props: any) => {
  const record = useRecordContext(props);
  return !record ? null : (
    <Link
      to={`/steps/${record.stepId}/1`}
    >{`Step ${record.stepId} (Button "${record.text}")`}</Link>
  );
};

const validateText = [required(), minLength(1, 'Text cannot be empty')];

export const ButtonEdit: React.FC = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (response: any) => {
    notify(`Changes saved`);
    redirect(`/steps/${response?.stepId || response.data.stepId}/1`);
    refresh();
  };
  return (
    <Edit
      transform={data => cleanEmptyValues(data, true, true)}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      title={<ButtonTitle />}
      {...props}
    >
      <SimpleForm>
        <TextInput source="id" disabled {...EmptyTextualFieldProps} />
        <TextInput source="text" isRequired validate={validateText} />
        {SupportedLocalesChoices.filter(lang => lang.shortId !== 'en').map(
          lang => (
            <TextInput
              source={`translations.${lang.shortId}.text`}
              label={`Text (${lang.shortEngName})`}
            />
          ),
        )}
        <ReferenceInput
          source="stepId"
          reference="steps"
          disabled
          label="Step"
          {...EmptyTextualFieldProps}
        >
          <SelectInput optionText="name" {...EmptyTextualFieldProps} />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <ReferenceInput
              source="destStepId"
              reference="steps"
              label="Destination Step"
              filter={
                formData.step?.campaignId
                  ? { campaignId: formData.step?.campaignId }
                  : formData.step?.campaignTemplateId
                  ? { campaignTemplateId: formData.step?.campaignTemplateId }
                  : {}
              }
              {...EmptyTextualFieldProps}
            >
              <SelectInput optionText="name" {...EmptyTextualFieldProps} />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <TextInput
          source="destURL"
          label="Destination URL/Deep link"
          validate={[validateDestURLOrMobileDeepLink]}
        />
        <BooleanInput source="isPrimary" />
        <SelectInput source="purpose" choices={PURPOSE_CHOICES} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <SelectInput
              disabled={!['UPLOAD', 'UPLOAD_IMAGE'].includes(formData.purpose)}
              source="facingMode"
              label="Facing Mode"
              choices={facingModeChoices}
              resettable
              {...rest}
            />
          )}
        </FormDataConsumer>
        <SelectArrayInput
          label="Platforms"
          source="platforms"
          choices={PLATFORM_CHOCIES}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ButtonCreate = (props: any) => {
  const query = useQuery();
  const stepId = query.get('stepId');
  const campaignId = query.get('campaignId');
  const campaignTemplateId = query.get('campaignTemplateId');

  const redirect = useRedirect();
  return (
    <Create
      transform={cleanEmptyValues}
      mutationOptions={{
        onSuccess: () => {
          if (stepId) redirect(`/steps/${stepId}/1`);
        },
      }}
      {...props}
    >
      <SimpleForm
        defaultValues={{
          stepId,
          isPrimary: true,
        }}
      >
        <TextInput source="id" disabled />
        <TextInput source="text" isRequired validate={[required()]} />
        <ReferenceInput source="stepId" reference="steps" disabled label="Step">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="destStepId"
          reference="steps"
          label="Destination Step"
          filter={
            campaignId
              ? { campaignId }
              : campaignTemplateId
              ? { campaignTemplateId }
              : {}
          }
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="destURL"
          label="Destination URL/Deep link"
          validate={[validateDestURLOrMobileDeepLink]}
        />
        <BooleanInput source="isPrimary" />
        <SelectInput source="purpose" choices={PURPOSE_CHOICES} />
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <SelectInput
              disabled={!['UPLOAD', 'UPLOAD_IMAGE'].includes(formData.purpose)}
              source="facingMode"
              label="Facing Mode"
              choices={facingModeChoices}
              resettable
              {...rest}
            />
          )}
        </FormDataConsumer>
        <SelectArrayInput
          label="Platforms"
          source="platforms"
          choices={PLATFORM_CHOCIES}
        />
      </SimpleForm>
    </Create>
  );
};
