import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const TranscriptionDialog: React.FC<{
  open: boolean;
  onClose: Function;
  text: string;
}> = ({ open, onClose, text }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="trans-dialog-title"
    >
      <DialogTitle id="trans-dialog-title">Transcription</DialogTitle>
      <DialogContent>
        <p style={{ whiteSpace: 'pre-line' }}>
          {text && text.replace(/\./g, '.\n\n')}
        </p>
      </DialogContent>
    </Dialog>
  );
};
export default TranscriptionDialog;
