export const isMissingVariablesByEmailType = (
  emailType: string,
  content: string,
): any => {
  switch (emailType) {
    case 'CAMPAIGN_REQUEST':
    case 'CAMPAIGN_REMINDER':
    case 'CAMPAIGN_RETAKE':
    case 'CAMPAIGN_RETAKE_REMINDER':
      return !(
        ['{{c_brand_name}}', '{{c_email_CTA}}'].every(variable =>
          content.includes(variable),
        ) &&
        ['{{c_first_name_or_there}}', '{{c_first_name}}'].some(variable =>
          content.includes(variable),
        ) &&
        [
          '{{c_incentive}}',
          '{{c_incentive_value}}',
          '{{c_participation_incentive}}',
          '{{c_participation_incentive_value}}',
          '{{c_total_incentives}}',
          '{{c_total_incentives_value}}',
        ].some(variable => content.includes(variable))
      );

    case 'VIDEO_REJECTION':
      return !(
        ['{{c_first_name_or_there}}', '{{c_first_name}}'].some(variable =>
          content.includes(variable),
        ) && content.includes('{{c_brand_name}}')
      );

    case 'CAMPAIGN_PARTICIPATION_REWARD':
      return ![
        '{{c_coupon_code}}',
        '{{c_participation_incentive}}',
        '{{c_participation_incentive_value}}',
      ].some(variable => content.includes(variable));

    case 'CAMPAIGN_INCENTIVE':
      return ![
        '{{c_incentive}}',
        '{{c_incentive_value}}',
        '{{c_coupon_code}}',
      ].some(variable => content.includes(variable));

    case 'CAMPAIGN_POST_SUBMISSION':
      return !content.includes('{{c_brand_name}}');

    default:
      return false;
  }
};

export const getVariablesListByEmailType = (emailType: string): any => {
  switch (emailType) {
    case 'CAMPAIGN_REQUEST':
    case 'CAMPAIGN_REMINDER':
    case 'CAMPAIGN_RETAKE':
    case 'CAMPAIGN_RETAKE_REMINDER':
      return [
        '{{c_brand_name}}',
        '{{c_email_CTA}}',
        '{{c_first_name_or_there}}',
        '{{c_first_name}}',
        '{{c_incentive}}',
        '{{c_incentive_value}}',
        '{{c_participation_incentive}}',
        '{{c_participation_incentive_value}}',
        '{{c_total_incentives}}',
        '{{c_total_incentives_value}}',
      ];

    case 'VIDEO_REJECTION':
      return [
        '{{c_first_name_or_there}}',
        '{{c_first_name}}',
        '{{c_brand_name}}',
      ];

    case 'CAMPAIGN_PARTICIPATION_REWARD':
      return [
        '{{c_coupon_code}}',
        '{{c_participation_incentive}}',
        '{{c_participation_incentive_value}}',
      ];

    case 'CAMPAIGN_INCENTIVE':
      return ['{{c_incentive}}', '{{c_incentive_value}}', '{{c_coupon_code}}'];

    case 'CAMPAIGN_POST_SUBMISSION':
      return ['{{c_brand_name}}'];

    default:
      return [];
  }
};

export const emailTypeToVariablesMap: { [key: string]: string[][] } = {
  CAMPAIGN_REQUEST: [
    ['{{c_brand_name}}'],
    ['{{c_email_CTA}}'],
    ['{{c_first_name_or_there}}', '{{c_first_name}}'],
    [
      '{{c_incentive}}',
      '{{c_incentive_value}}',
      '{{c_participation_incentive}}',
      '{{c_participation_incentive_value}}',
    ],
  ],
  CAMPAIGN_REMINDER: [
    ['{{c_brand_name}}'],
    ['{{c_email_CTA}}'],
    ['{{c_first_name_or_there}}', '{{c_first_name}}'],
    [
      '{{c_incentive}}',
      '{{c_incentive_value}}',
      '{{c_participation_incentive}}',
      '{{c_participation_incentive_value}}',
      '{{c_total_incentives}}',
      '{{c_total_incentives_value}}',
    ],
  ],
  CAMPAIGN_RETAKE: [
    ['{{c_brand_name}}'],
    ['{{c_email_CTA}}'],
    ['{{c_first_name_or_there}}', '{{c_first_name}}'],
    [
      '{{c_incentive}}',
      '{{c_incentive_value}}',
      '{{c_participation_incentive}}',
      '{{c_participation_incentive_value}}',
      '{{c_total_incentives}}',
      '{{c_total_incentives_value}}',
    ],
  ],
  CAMPAIGN_RETAKE_REMINDER: [
    ['{{c_brand_name}}'],
    ['{{c_email_CTA}}'],
    ['{{c_first_name_or_there}}', '{{c_first_name}}'],
    [
      '{{c_incentive}}',
      '{{c_incentive_value}}',
      '{{c_participation_incentive}}',
      '{{c_participation_incentive_value}}',
      '{{c_total_incentives}}',
      '{{c_total_incentives_value}}',
    ],
  ],
  VIDEO_REJECTION: [
    ['{{c_first_name_or_there}}', '{{c_first_name}}'],
    ['{{c_brand_name}}'],
  ],
  CAMPAIGN_PARTICIPATION_REWARD: [
    [
      '{{c_coupon_code}}',
      '{{c_participation_incentive}}',
      '{{c_participation_incentive_value}}',
    ],
  ],
  CAMPAIGN_INCENTIVE: [
    ['{{c_incentive}}', '{{c_incentive_value}}', '{{c_coupon_code}}'],
  ],
  CAMPAIGN_POST_SUBMISSION: [['{{c_brand_name}}']],
};

export const getMissingVariablesByType = (
  emailType: string,
  content: string,
): string[] => {
  const missingVariables = [];

  const variableLists = emailTypeToVariablesMap[emailType];

  for (const varList of variableLists) {
    if (!varList.some(variable => content.includes(variable))) {
      missingVariables.push(...varList);
    }
  }
  return missingVariables;
};
