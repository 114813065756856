import React from 'react';
import { Tooltip } from '@mui/material';
import { ReactComponent as chromeSvg } from '../assets/browser/chrome.svg';
import { ReactComponent as edgeSvg } from '../assets/browser/edge.svg';
import { ReactComponent as firefoxSvg } from '../assets/browser/firefox.svg';
import { ReactComponent as operaSvg } from '../assets/browser/opera.svg';
import { ReactComponent as safariSvg } from '../assets/browser/safari.svg';

type Props = {
  browserName: string;
};

const browserAssets: Record<string, React.FunctionComponent> = {
  chrome: chromeSvg,
  edge: edgeSvg,
  firefox: firefoxSvg,
  ie: edgeSvg,
  opera: operaSvg,
  safari: safariSvg,
};

const BrowserDisplay: React.FC<Props> = (props) => {
  const { browserName } = props;
  const filteredBrowserAssets = Object.keys(browserAssets).filter(
    (browserAsset) =>
      browserName && browserName.toLowerCase().indexOf(browserAsset) >= 0
  );

  if (filteredBrowserAssets.length === 0 || filteredBrowserAssets.length > 1)
    return <span>N/A</span>;

  const Element = browserAssets[filteredBrowserAssets[0]];

  if (Element === undefined) return <span>N/A</span>;

  return (
    <Tooltip title={browserName}>
      <Element />
    </Tooltip>
  );
};

export default BrowserDisplay;
