import {
  AutocompleteArrayInput,
  Create,
  ImageInput,
  number,
  RaRecord,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  usePermissions,
  useRedirect,
} from 'react-admin';
import { AccordionActions, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, FormHelperText } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import {
  platformsChoices,
  VariationsChoices,
  DimensionChoices,
} from './consts';
import React, { useState } from 'react';
import { createVideoAd, getTakeById } from '../../providers/dataProvider';
import { extractTakeFromName } from './utils';
import { WithFile } from './WithFile';
import { useLocation } from 'react-router-dom';
import { Role } from '../../resources/users';
import { uploadToS3 } from '../../utils/assets';

const myStyles = makeStyles({
  fileInput: {
    '& .previews': {
      visibility: 'collapse',
      width: '0px',
      height: '0px',
    },
  },
  nameInput: {
    '& .MuiFormHelperText-contained': {
      visibility: 'collapse',
      width: '0px',
      height: '0px',
    },
  },
});

const DEFAULT_PLATFORM = ['FACEBOOK'];
const BLOB_ID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
const UPLOAD_PREFIX = 'upload_';

const validateCreateVideoAd = (values: any) => {
  const errors: any = {};

  if (values.taskId === undefined) {
    if (values.brandId === undefined || typeof values.brandId === 'string') {
      errors.brandId = 'Must set a brand name or takes';
    }

    if (typeof values.brandId === 'number') {
      const error = number('Brand id must be a number')(values.brandId, values);
      if (error) errors.brandId = error;
    }
  }

  if (values.dimension === undefined || values.dimension === '') {
    errors.dimension = 'ra.validation.required';
  }

  if (values.variation === undefined || values.variation === '') {
    errors.variation = 'ra.validation.required';
  }

  if (values.platforms === undefined || values.platforms?.length === 0) {
    errors.platforms = 'ra.validation.required';
  }

  if (values.sessionIds === undefined || values.sessionIds?.length === 0) {
    errors.sessionIds = 'ra.validation.required';
  }

  return errors;
};

export const VideoAdsCreate: React.FC = (props: any) => {
  const [savedFiles, setSavedFiles] = React.useState<string[]>([]);
  const [fileToSave, setFileToSave] = React.useState<string>('');
  const [selectedBrandId, setSelectedBrandId] = useState('0');
  const [checkedTakes, setCheckedTakes] = React.useState<
    Record<string, number | string>
  >({});

  const userPermissions = usePermissions();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const notify = useNotify();
  const redirect = useRedirect();

  const createNewVideoAd = async (values: any, file: File) => {
    // Build videoAd body
    const createVideoAdBody = {
      ...(values.name === undefined || values.name.length === 0
        ? { name: undefined }
        : { name: values.name }),
      ...(values.brandId === undefined || values.brandId?.length === 0
        ? {}
        : { brandId: values.brandId }),
      platforms: values.platforms,
      taskId: values.taskId,
      variation: values.variation,
      dimension: values.dimension,
      sessionIds: values.sessionIds || [],
      size: file.size,
      contentType: file.type,
    };

    // Create video ad
    const createdVideoAd = await createVideoAd(createVideoAdBody);

    await uploadToS3(createdVideoAd.signData, file);

    return file.name;
  };

  const validateAll = (data: any) => {
    const errors = Object.keys(data)
      .filter(
        key =>
          key.startsWith(UPLOAD_PREFIX) &&
          (fileToSave === '' || key === fileToSave) &&
          savedFiles.indexOf(key) === -1,
      )
      .map(key => {
        const errors = validateCreateVideoAd(data[key]);
        return Object.keys(errors).length > 0 ? { [key]: errors } : {};
      })
      .filter(obj => Object.keys(obj).length > 0)
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});

    return errors;
  };

  const brandIsSelected = selectedBrandId && selectedBrandId !== '0';

  const saveAll = async (data: any) => {
    const uploads = Object.keys(data)
      .filter(
        key =>
          key.startsWith(UPLOAD_PREFIX) &&
          (fileToSave === '' || key === fileToSave) &&
          savedFiles.indexOf(key) === -1,
      )
      .map(async key => {
        return {
          key,
          result: await createNewVideoAd(
            data[key],
            data.uploads.filter((fileObj: any) => {
              return fileObj.src.endsWith(key.substring(UPLOAD_PREFIX.length));
            })[0].rawFile,
          ),
        };
      });

    const results = await Promise.allSettled(uploads);
    return results.map(res => {
      return {
        result: res.status === 'fulfilled',
        key: res.status === 'fulfilled' ? res.value.key : 'null',
        name: res.status === 'fulfilled' ? res.value.result : 'null',
        message: res.status === 'rejected' ? res.reason?.message : null,
      };
    });
  };

  return (
    <Create resource="videocreatives" {...props}>
      <SimpleForm
        validate={validateAll}
        onSubmit={async data => {
          const uploads = await saveAll(data);

          setSavedFiles(
            savedFiles.concat(
              uploads.filter(upload => upload.result).map(upload => upload.key),
            ),
          );

          if (uploads.filter(upload => !upload.result).length === 0) {
            if (fileToSave === '') {
              notify('Successfully uploaded all ads', { type: 'success' });
              redirect('list', 'videocreatives');
            } else notify('Successfully video ad', { type: 'success' });
          } else {
            const messages: string[] = uploads
              .filter(upload => !upload.result && !!upload.message)
              .map(upload => upload.message);
            const errorMessage =
              messages.length > 0 ? ':\n' + messages.join('\n') : '';
            if (fileToSave === '')
              notify(`Some ads could not be uploaded${errorMessage}`, {
                type: 'error',
              });
            else
              notify(`Video ad could not be uploaded${errorMessage}`, {
                type: 'error',
              });
          }

          setFileToSave('');
        }}
        toolbar={
          <Toolbar>
            <SaveButton label="Save All" onClick={() => setFileToSave('')} />
          </Toolbar>
        }
      >
        <Typography variant="h5">Upload Video Ads</Typography>
        <ImageInput
          source="uploads"
          multiple
          placeholder={<p>Click here, or drop one or more videos.</p>}
          accept="video/*"
          maxSize={1024 * 1024 * 100}
          helperText="Files above 100MB will be rejected"
        >
          <WithFile
            source="src"
            render={(
              fileName: string,
              fileType: string,
              fileBlob: string,
              rawFile: File,
              record: RaRecord,
            ) => {
              const blobIdMatch = fileBlob.match(BLOB_ID_REGEX);
              const uploadId =
                blobIdMatch && blobIdMatch.length > 0
                  ? `${UPLOAD_PREFIX}${blobIdMatch[0]}`
                  : null;

              if (!uploadId) {
                notify('Something went wrong', { type: 'error' });
                return <></>;
              }

              const disabled = savedFiles.indexOf(uploadId) > -1;
              const takeId = extractTakeFromName(fileName);
              let assumedBrandId: any = '';

              if (takeId && takeId.length === 1) {
                if (Object.keys(checkedTakes).indexOf(takeId[0]) === -1) {
                  getTakeById(takeId[0])
                    .then(res => {
                      assumedBrandId = res.json?.brand?.id;
                      setCheckedTakes({
                        ...checkedTakes,
                        [takeId[0]]: assumedBrandId,
                      });
                    })
                    .catch(() => {
                      setCheckedTakes({
                        ...checkedTakes,
                        [takeId[0]]: '',
                      });
                    });
                } else {
                  assumedBrandId = checkedTakes[takeId[0]];
                }
              }

              return (
                <Accordion
                  disabled={disabled}
                  defaultExpanded={true}
                  expanded={!disabled}
                  style={{ height: '25%', marginBottom: '1%' }}
                >
                  <AccordionSummary>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>Upload {fileName}</Typography>
                      </Grid>
                      <Grid item>
                        {disabled ? <CheckCircle color="success" /> : null}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item width={'50%'}>
                        <video
                          src={fileBlob}
                          controls
                          height="100%"
                          width="100%"
                        />
                      </Grid>
                      <Grid item width={'50%'}>
                        {userPermissions.permissions === Role.ADMIN && (
                          <Grid>
                            <Grid
                              container
                              display="flex"
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Grid item width={'80%'}>
                                <TextInput
                                  fullWidth
                                  label="Name"
                                  source={`${uploadId}.name`}
                                  resettable
                                  hidden={true}
                                  className={myStyles().nameInput}
                                  sx={{ display: 'flex' }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item>
                              <FormHelperText>
                                {`Default template: Uplifted_{Brand}_{Num}_{Variation}_{Dimension}_{Platforms}`}
                              </FormHelperText>
                              <FormHelperText>
                                Example: "Uplifted_Mixtiles_1_A_9X16_FB"
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        )}

                        {userPermissions.permissions === Role.ADMIN && (
                          <ReferenceInput
                            source={`${uploadId}.brandId`}
                            reference="brands"
                            isRequired
                            label="Brand"
                            perPage={1000}
                          >
                            <SelectInput
                              label="Brand"
                              optionText="name"
                              onChange={e => {
                                setSelectedBrandId(e.target.value);
                              }}
                              format={v => {
                                if (typeof v !== 'number' && assumedBrandId)
                                  return assumedBrandId;

                                return v;
                              }}
                            />
                          </ReferenceInput>
                        )}
                        <ReferenceArrayInput
                          source={`${uploadId}.sessionIds`}
                          sort={{ field: 'createdAt', order: 'DESC' }}
                          filter={{
                            brandId: selectedBrandId,
                            includeHidden: true,
                          }}
                          reference={`videosessions`}
                        >
                          <AutocompleteArrayInput
                            label="Video Sessions"
                            multiple
                            isRequired={true}
                            validate={required()}
                            defaultValue={[]}
                            disabled={!brandIsSelected}
                            optionText="name"
                            style={{
                              maxWidth: '400px',
                            }}
                          />
                        </ReferenceArrayInput>
                        {!brandIsSelected && (
                          <div
                            style={{
                              fontSize: '12px',
                              marginTop: '-25px',
                              color: 'crimson',
                            }}
                          >
                            You must select a brand before choosing a video
                            session
                          </div>
                        )}
                        <p>Ad settings:</p>
                        <SelectInput
                          isRequired
                          fullWidth
                          label="Variation"
                          source={`${uploadId}.variation`}
                          choices={VariationsChoices}
                          optionText="name"
                          defaultValue={VariationsChoices[0].id}
                        />
                        <SelectInput
                          isRequired
                          fullWidth
                          label="Dimension"
                          source={`${uploadId}.dimension`}
                          choices={DimensionChoices}
                          optionText="name"
                          defaultValue={DimensionChoices[0].id}
                        />
                        <SelectArrayInput
                          isRequired
                          fullWidth
                          label="Platform"
                          source={`${uploadId}.platforms`}
                          choices={platformsChoices}
                          optionText="name"
                          defaultValue={DEFAULT_PLATFORM}
                        />
                        <TextInput
                          source={`${uploadId}.taskId`}
                          defaultValue={taskId}
                          style={{ display: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  <AccordionActions>
                    <SaveButton
                      type="submit"
                      style={{
                        display: 'flex',
                        position: 'relative',
                        opacity: 1,
                        marginBottom: '1%',
                      }}
                      onClick={e => {
                        setFileToSave(uploadId);
                      }}
                    />
                  </AccordionActions>
                </Accordion>
              );
            }}
          />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
