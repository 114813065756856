import { Pagination, ReferenceManyField } from 'react-admin';
import { IncentivesDatagrid } from './IncentivesList';

export const CampaignIncentives: React.FC = () => (
  <ReferenceManyField
    reference="incentives"
    target="campaignId"
    resource="incentives"
    sort={{ field: 'assignedAt', order: 'desc' }}
    filter={{
      not: {
        assignedAt: null,
      },
    }}
    pagination={<Pagination />}
  >
    <IncentivesDatagrid />
  </ReferenceManyField>
);
