import React from 'react';
import { ChipField, Labeled } from 'react-admin';

export const CastBrandVaribalesList = [
  { id: 'c_brand_name', title: 'Brand Name' },
  { id: 'c_full_logo', title: 'Full Logo' },
  { id: 'c_brand_website', title: 'Brand Website' },
  { id: 'c_txt_color', title: 'Text Color' },
  { id: 'c_bg_color', title: 'Background Color' },
  { id: 'c_btn_txt_color', title: 'Button Text Color' },
  { id: 'c_btn_bg_color', title: 'Button Background Color' },
];
export const CastCampaignVaribalesList = [
  { id: 'c_campaign_link', title: 'Campaign Link' },
];

export const CastIncentiveVaribalesList = [
  { id: 'c_incentive', title: 'Incentive' },
  { id: 'c_incentive_value', title: 'Incentive Value' },
  { id: 'c_step_incentive', title: 'Incentive' },
  { id: 'c_step_incentive_value', title: 'Incentive Value' },
  { id: 'c_participation_incentive', title: 'Incentive' },
  { id: 'c_participation_incentive_value', title: 'Incentive Value' },
  { id: 'c_total_incentives', title: 'Total Incentives' },
  { id: 'c_total_incentives_value', title: 'Total Incentives Value' },
  { id: 'c_coupon_code', title: 'Coupon Code' },
];
export const CastEmailProviderVaribalesList = [
  { id: 'c_email', title: 'Email' },
  { id: 'c_first_name', title: 'First Name' },
  { id: 'c_first_name_or_there', title: 'First Name (Default "there")' },
  { id: 'c_unsubscribe', title: 'Unsubscribe' },
];

export const CastOtherVariables = [
  { id: 'c_retake_note', title: 'Retake Note' },
  { id: 'c_remaining_retakes', title: 'Remaining Retakes' },
];

export const CastVariablesList = [
  ...CastBrandVaribalesList,
  ...CastCampaignVaribalesList,
  ...CastIncentiveVaribalesList,
  ...CastEmailProviderVaribalesList,
  ...CastOtherVariables,
];

export const VarsLegendItems = (items: { id: string; title: string }[]) =>
  items
    .map(currVar => ({ ...currVar, display: `{{${currVar.id}}}` }))
    .map(currVar => (
      <Labeled label={currVar.title}>
        <ChipField record={currVar} source="display" />
      </Labeled>
    ));
