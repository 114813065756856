import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useRecordContext } from 'ra-core';
import StepPreview from '../../../resources/step-preview';
import { useMemo } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = makeStyles({
  galleryPage: {
    width: 1600,
    position: 'relative',
    marginTop: 10,
  },
  logo: {
    position: 'absolute',
    top: -10,
    right: 0,
    width: 150,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    marginLeft: 16,
  },
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    transform: 'scale(0.75)',
    transformOrigin: 'left top',
  },
  step: {
    marginRight: 10,
    marginTop: 5,
  },
  iframe: {
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: '#eeeeee',
    marginTop: -10,
  },
  printInstructions: {
    marginBottom: 50,
    lineHeight: 1.33,
    borderBottom: '1px solid',
  },
});
const CURRENT_TIME = new Date().toLocaleString();

const StepsGallery: React.FC = () => {
  const isPrintMode = useMediaQuery('print');
  const record = useRecordContext();
  const classes = styles();

  const memoizedStyles = useMemo(
    () => ({
      ...classes,
    }),
    [classes],
  );

  const memoizedSteps = useMemo(() => {
    return record.steps?.sort(
      (a: any, b: any) => a.funnelSequence - b.funnelSequence,
    );
  }, [record]);

  return (
    <>
      <div className={memoizedStyles.galleryPage}>
        <img src="./uplifted-logo.svg" className={memoizedStyles.logo} alt="" />
        {!isPrintMode && (
          <div className={memoizedStyles.printInstructions}>
            <h4>Important</h4>
            <ul>
              <li>
                <strong>Use Chrome!</strong>
              </li>
              <li>
                Wait for all steps to load, for longer campaigns it might take a
                few extra seconds
              </li>
            </ul>
            <h4>Printing Instructions</h4>
            <ol>
              <li>
                Use <code>cmd+P</code> (Mac) or <code>ctrl+P</code> (Windows) to
                use the browser's print functionality
              </li>
              <li>
                Make sure to choose <code>Save as PDF</code> as the{' '}
                <code>Destination</code>
              </li>
              <li>
                Click <code>More settings</code> and select{' '}
                <code>Background graphics</code>
              </li>
              <li>
                Change <code>Paper size</code> to <code>A3</code> to fit the
                steps
              </li>
            </ol>
          </div>
        )}
        <div className={memoizedStyles.header}>
          <img src={record.brand?.logoURL} height="50px" alt="" />
          <div className={memoizedStyles.title}>
            <strong>{record.title}</strong>
            <br />
            <small>Creation time: {CURRENT_TIME} </small>
          </div>
        </div>
        <div className={memoizedStyles.gallery}>
          {memoizedSteps?.map((step: any, index: number) => (
            <div className={memoizedStyles.step} key={index}>
              <h3>
                Step Sequence {index + 1} ({step.type})
              </h3>
              <StepPreview
                className={memoizedStyles.iframe}
                step={step}
                width={375}
                enableFakeCamera={true}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default StepsGallery;
