import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

const myTheme = merge({}, defaultTheme, {
  palette: {
    secondary: {
      main: process.env.REACT_APP_THEME_SECONDARY || '#2196f3',
    },
  },
});

export default myTheme;
