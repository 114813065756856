import { SelectInput } from 'react-admin';

export const textAlignmentOptions = [
  { id: 'LEFT', name: 'Left' },
  { id: 'CENTER', name: 'Center' },
  { id: 'RIGHT', name: 'Right' },
];
const StepTextAlignment: React.FC<{
  getSource?: (src: string) => string;
}> = ({ getSource = src => `props.${src}` }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SelectInput
        source={getSource('titleTextAlign')}
        defaultValue={'CENTER'}
        label="Title text alignment"
        choices={textAlignmentOptions}
      />
      <SelectInput
        source={getSource('contentTextAlign')}
        defaultValue={'CENTER'}
        label="Content text alignment"
        choices={textAlignmentOptions}
      />
    </div>
  );
};

export default StepTextAlignment;
