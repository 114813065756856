import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';
import { Button, RaRecord, useNotify, useRecordContext } from 'react-admin';
import { transcribeVideoTake } from '../../providers/dataProvider';
import QuickEditTake from './QuickEditTake';
import AssistantIcon from '@mui/icons-material/Assistant';
import TranscriptionDialog from '../TranscriptionDisalog';
import GalleryItem, { ItemType } from '../GalleryItem';
import { galleryStyles } from './MediaGallery';
import SceneIntelligenceDialog from '../SceneIntelligenceDialog';

const getDefaultPosterURL = (url: string) =>
  url ? url.replace(/\.[^.]+$/, '.jpg') : undefined;

interface TakeProps {
  Footer?: React.ComponentType<any>;
  getTakeUrl?: (record: RaRecord) => string;
  record: RaRecord;
  isSelected?: boolean;
  onClick?: (record?: RaRecord) => void;
  selectedVideoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  canDownload?: boolean;
  length?: number;
  type?: 'VIDEO' | 'IMAGE';
  disableTakeVetting: boolean;
}

const thumbStyles = makeStyles({
  tile: {
    marginRight: '0.25%',
    padding: '5px',
  },
  videoContainer: {
    height: '450px',
    width: '400px',
    maxHeight: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    marginBottom: '50px',
  },
  thumbContainer: {
    height: '150px',
    width: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#000',
  },
  video: {
    backgroundColor: '#000',
  },
  image: {
    height: '450px',
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    border: '1px solid black',
  },
  thumbStatusContainer: {
    position: 'absolute',
    top: '2%',
    left: '65%',
    zIndex: 10,
    display: 'flex',
    fontSize: '20pt !important',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  videoStatusContainer: {
    position: 'absolute',
    top: '1%',
    left: '87%',
    zIndex: 10,
  },
  statusBox: {
    width: '1.5rem',
    height: '1.5rem',
  },
  selectedVideo: {
    border: '3px solid blue',
  },
  takeDuration: {
    fontSize: '12px',
    background: 'white',
    position: 'absolute',
    top: 3,
    left: 3,
    padding: '0 4%',
    borderRadius: '5px',
  },
});

const Thumb: React.FC<Partial<TakeProps>> = ({
  Footer,
  getTakeUrl,
  record,
  isSelected = true,
  onClick,
  disableTakeVetting = false,
}) => {
  const styles = thumbStyles();
  const url = useMemo(
    () =>
      getTakeUrl === undefined
        ? record?.url
        : record
        ? getTakeUrl(record)
        : null,
    [record, getTakeUrl],
  );
  const poster = useMemo(() => {
    if (record?.posterURL) {
      return record?.posterURL;
    } else if (!url?.includes('cloudinary')) {
      return url;
    } else if (url?.includes('cloudinary')) {
      return getDefaultPosterURL(url);
    }
  }, [record, url]);
  if (!record) return null;
  const selectedTakeLength = () => {
    if (record?.length) {
      return Intl.DateTimeFormat('en-US', {
        minute: 'numeric',
        second: 'numeric',
      }).format(record.length * 1000);
    }
    return 'N/A';
  };

  return (
    <div
      key={`${record.id}_container`}
      className={`${styles.tile} ${isSelected ? styles.selectedVideo : ''}`}
    >
      <div
        key={`${record.id}_thumb`}
        className={styles.thumbContainer}
        style={
          record?.type === 'IMAGE'
            ? {
                background: 'white',
                border: '1px solid black',
              }
            : {}
        }
      >
        <div className={styles.thumbStatusContainer}>
          <QuickEditTake
            key={`${record.id}_edit`}
            record={record}
            showEmoji={true}
            disableTakeVetting={disableTakeVetting}
          />
        </div>
        <img
          key={`${record.id}_video`}
          src={poster}
          alt={`thumb poster for take ${record.id}`}
          onClick={() => {
            record && onClick && onClick(record);
          }}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
        <span className={styles.takeDuration}>
          {record?.length && `${selectedTakeLength()}`}
        </span>
      </div>

      {Footer && <Footer record={record} />}
    </div>
  );
};
interface MediaGalleryProps {
  data?: RaRecord[];
  Footer?: React.ComponentType<any>;
  getTakeUrl?: (record: RaRecord) => string;
  filter?: (record: RaRecord) => boolean;
  setSelectedTakeId: (id: string) => void;
  selectedVideoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  disableTakeVetting: boolean;
}

const MediaThumbs: React.FC<MediaGalleryProps> = ({
  data,
  Footer,
  getTakeUrl,
  filter = () => true,
  setSelectedTakeId,
  selectedVideoRef,
  disableTakeVetting,
}) => {
  const videoSession = useRecordContext();

  const notify = useNotify();
  const styles = galleryStyles();
  const [isTranscribeClicked, setTranscribeClicked] = useState<boolean>(false);
  const [isTranscriptionOpen, setIsTranscriptionOpen] =
    useState<boolean>(false);
  const [sceneIntelligenceDialogOpen, setSceneIntelligenceDialogOpen] =
    useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<any>(null);

  const onSelect = (record?: RaRecord) => {
    if (record) {
      setSelectedVideo(record);
    }
  };

  const filteredData = useMemo(
    () =>
      data
        ? data
            ?.filter(filter)
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )
        : [],
    [data, filter],
  );

  useEffect(
    () =>
      setSelectedVideo((prevVideo: any) =>
        !prevVideo || !filteredData.find(take => take.id === prevVideo.id)
          ? filteredData[0]
          : prevVideo,
      ),
    [filteredData],
  );

  useEffect(() => {
    selectedVideo && setSelectedTakeId(selectedVideo.id);
  }, [selectedVideo, setSelectedTakeId]);

  return (
    <>
      {selectedVideo && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div key={selectedVideo.id} className={styles.videoContainer}>
            <GalleryItem
              itemType={ItemType.TAKE}
              record={selectedVideo}
              onToggleItem={() => null}
              size={'sm'}
              showTakeNavigation={false}
              disableTakeVetting={disableTakeVetting}
            />
          </div>

          {selectedVideo?.type === 'VIDEO' && (
            <>
              <Button
                disabled={!selectedVideo.id || isTranscribeClicked}
                onClick={() => {
                  if (!selectedVideo?.transcription) {
                    transcribeVideoTake(selectedVideo.id);
                    notify('requested video transcription', { type: 'info' });
                  } else {
                    setTranscribeClicked(true);
                    setIsTranscriptionOpen(true);
                  }
                }}
                label={
                  !selectedVideo?.transcription ? 'Transcribe' : 'Transcription'
                }
              >
                <AssistantIcon />
              </Button>

              <TranscriptionDialog
                open={isTranscriptionOpen}
                onClose={() => {
                  setTranscribeClicked(false);
                  setIsTranscriptionOpen(false);
                }}
                text={selectedVideo?.transcription || ''}
              />
            </>
          )}
          {selectedVideo?.type === 'VIDEO' &&
            videoSession?.transcriptionLlmBreakdown && (
              <>
                <Button
                  disabled={!selectedVideo.id}
                  onClick={() => setSceneIntelligenceDialogOpen(true)}
                  label={'SCENE INTELLIGENCE'}
                />

                <SceneIntelligenceDialog
                  selectedTakeId={selectedVideo?.id}
                  open={sceneIntelligenceDialogOpen}
                  onClose={() => setSceneIntelligenceDialogOpen(false)}
                  breakdown={videoSession.transcriptionLlmBreakdown}
                />
              </>
            )}
        </div>
      )}

      <div
        style={{
          marginTop: '2%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {filteredData.map(record => (
          <Thumb
            key={`${record.id}_listThumb`}
            record={record}
            Footer={Footer}
            getTakeUrl={getTakeUrl}
            isSelected={selectedVideo && selectedVideo.id === record.id}
            onClick={onSelect}
            disableTakeVetting={disableTakeVetting}
          />
        ))}
      </div>
    </>
  );
};

export default MediaThumbs;
