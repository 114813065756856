import React from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  FormTab,
  FormTabProps,
  minValue,
  number,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SimpleFormIterator,
} from 'react-admin';
import { Typography, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ChipInput } from '../../ChipInput';

const CastingTypeChoices = [
  { id: 'SUBSCRIBED_CREATORS', name: "Brand's subscribed creators" },
];

const Casting: React.FC<FormTabProps & { target: 'campaignId' }> = ({
  target,
  ...rest
}) => {
  return (
    <FormTab {...rest}>
      <div style={{ display: 'flex' }}>
        <NumberInput source="spamFilterDays" label="Spam filter (days)" />
        <Tooltip title="Skip customers who got any campaign in the last X days">
          <InfoIcon />
        </Tooltip>
      </div>
      <ArrayInput source="castingCriteria">
        <SimpleFormIterator>
          <NumberInput
            source="tier"
            label="Tier"
            isRequired
            validate={[
              required('Must set tier number'),
              number('Tier must be a number'),
              minValue(1),
            ]}
          />
          <Typography variant="h6">Batch Settings</Typography>
          <NumberInput
            source="batchSize"
            label="Size Limit"
            validate={[required(), number(), minValue(1)]}
            isRequired
            defaultValue={100}
          />
          <RadioButtonGroupInput
            source="castingType"
            label="Type"
            isRequired
            choices={CastingTypeChoices}
            defaultValue="SUBSCRIBED_CREATORS"
          />
          <FormDataConsumer>
            {({ scopedFormData, formData, getSource, ...rest }) => {
              return (
                getSource && (
                  <div>
                    <ChipInput
                      source={getSource('excludeKeywords')}
                      label="Exclude Keywords"
                    />
                    <BooleanInput
                      source={getSource('excludePreviousBatches')}
                      defaultValue={true}
                      label="Exclude Previous Batches"
                    />
                  </div>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  );
};

export default Casting;
