import {
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useState } from 'react';
import { SimpleForm, useNotify } from 'react-admin';
import { Control, FieldValues } from 'react-hook-form';
import BrandCampaignEmailsInput from './CopyEmailSelectInput';

import { CloseOutlined } from '@mui/icons-material';

export type InputComponentProps = {
  control: Control<FieldValues, any>;
};
const emailFromOptions = [
  { id: 'CAMPAIGN_TEMPLATE', name: 'Template' },
  { id: 'CAMPAIGN', name: 'Campaign' },
];

type Props = {
  open: boolean;
  onSubmit: (data: FieldValues) => void;
  onCancel: () => void;
};

const CreateEmailFromDialog = ({ open, onSubmit, onCancel }: Props) => {
  const [createFromType, setCreateFromType] =
    useState<string>('CAMPAIGN_TEMPLATE');

  const notify = useNotify();
  const isFormInvalid = (data: FieldValues) => {
    switch (createFromType) {
      case 'CAMPAIGN_TEMPLATE':
        return !!data.emailId && !!data.campaignTemplateId;
      case 'CAMPAIGN':
        return !!data.emailId && !!data.campaignId;
    }
    return false;
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container display="flex" justifyContent={'space-between'}>
          <p>Create email from...</p>
          <CloseOutlined
            onClick={() => {
              onCancel();
              setCreateFromType('CAMPAIGN_TEMPLATE');
            }}
            style={{ cursor: 'pointer' }}
          />
        </Grid>
      </DialogTitle>
      <SimpleForm
        onSubmit={data => {
          if (!isFormInvalid(data)) {
            notify('Please fill all form fields', { type: 'error' });
            return;
          }

          onSubmit(data);
        }}
      >
        <FormControl style={{ width: '150px' }}>
          <InputLabel id="create-email-from-select-label">
            Create from
          </InputLabel>
          <Select
            labelId="create-email-from-select-label"
            id="create-email-from-select"
            value={createFromType}
            label="Create from"
            placeholder="Create from"
            onChange={e => setCreateFromType(e.target.value)}
          >
            {emailFromOptions.map(option => (
              <MenuItem value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={2} mt="5px">
          <BrandCampaignEmailsInput
            showCampaigns={createFromType === 'CAMPAIGN'}
          />
        </Grid>
      </SimpleForm>
    </Dialog>
  );
};
export default CreateEmailFromDialog;
