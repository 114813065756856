import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  FormTab,
  Button,
  ReferenceManyField,
  Datagrid,
  EditButton,
  TextField,
  FunctionField,
  Labeled,
  useNotify,
  useRefresh,
  useRecordContext,
  FormTabProps,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Add, Send } from '@mui/icons-material';
import { sendTestEmail } from '../../../providers/dataProvider';
import { emailProvidersChoices } from '../../../consts';
import UserEmailsChipInput from '../../UserEmailsChipInput';

const TestEmailDialog = (props: any) => {
  const {
    refresh,
    email,
    setCurrEmail,
    campaign,
    isTestDialogOpen,
    setTestDialogOpen,
  } = props;
  const [addressees, setAddressees] = React.useState<string[]>([]);
  const [isSending, setIsSending] = React.useState<Boolean>(false);
  const notify = useNotify();

  const onUserEmailChange = (value: string | null) => {
    if (!value) {
      setAddressees([]);
      return;
    }

    setAddressees(prev => {
      if (prev.includes(value)) {
        return prev.filter(email => email !== value);
      }
      return [...prev, value];
    });
  };
  return email === null ? (
    <></>
  ) : (
    <Dialog
      open={isTestDialogOpen}
      onClose={() => {
        setTestDialogOpen(false);
        setCurrEmail(null);
        refresh();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Ready to send?</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Labeled label="Campaign">
            <TextField record={campaign} source="title" />
          </Labeled>
          <Labeled label="Brand">
            <TextField record={campaign} source="brand.name" />
          </Labeled>
          <Labeled label="Type">
            <TextField record={email} source="type" />
          </Labeled>
          <Labeled label="Subject">
            <TextField record={email} source="subject" />
          </Labeled>
          <Labeled label="Content Preview">
            <div
              dangerouslySetInnerHTML={{
                __html: email.preview,
              }}
              style={{ border: '1px solid black' }}
            />
          </Labeled>
          <UserEmailsChipInput
            accountId={campaign?.brand.accountId}
            onChange={onUserEmailChange}
            selectedEmails={addressees}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          label="Cancel"
          onClick={() => {
            setTestDialogOpen(false);
            refresh();
          }}
        />
        <Button
          label="Send"
          disabled={!!(addressees.length === 0 || isSending)}
          onClick={async () => {
            try {
              setIsSending(true);
              await sendTestEmail(email.id, addressees);
              setTestDialogOpen(false);
              setCurrEmail(null);
              refresh();
              notify(`Test Email Sent`, { type: 'success' });
            } catch (err: any) {
              const message =
                err.body &&
                err.body.message &&
                err.body.message !== 'Internal server error'
                  ? err.body.message
                  : 'Failed to send test email';
              notify(message, { type: 'error' });
            } finally {
              setIsSending(false);
            }
          }}
        >
          {isSending ? <CircularProgress /> : <Send />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TestEmailButton = (props: any) => {
  const { setTestDialogOpen, email, setCurrEmail } = props;
  return (
    <Button
      label="Test"
      onClick={() => {
        setTestDialogOpen(true);
        setCurrEmail(email);
      }}
    >
      <Send />
    </Button>
  );
};

const AddEmailButton = (props: any) => {
  const record = useRecordContext(props);
  const { target } = props;

  return (
    <Button
      component={Link}
      variant="contained"
      to={`/email/create?${target}=${record?.id}`}
      label="Create Email"
      title="Create Email"
    >
      <Add />
    </Button>
  );
};

export const EmailFormTab: React.FC<
  FormTabProps & { target: 'campaignId' | 'campaignTemplateId' }
> = ({ target, ...props }) => {
  const record = useRecordContext(props);
  const { brand: { emailProvider = '' } = {} } = record || { brand: {} };

  const emailProviderName = React.useMemo(
    () =>
      emailProvidersChoices.find(ep => ep.id === emailProvider)?.name || 'N/A',
    [emailProvider],
  );

  const [isTestDialogOpen, setTestDialogOpen] = React.useState<boolean>(false);
  const [currEmail, setCurrEmail] = React.useState(null);
  const refresh = useRefresh();

  return (
    <FormTab {...props}>
      <TestEmailDialog
        isTestDialogOpen={isTestDialogOpen}
        setTestDialogOpen={setTestDialogOpen}
        campaign={record}
        email={currEmail}
        setCurrEmail={setCurrEmail}
        refresh={refresh}
        {...props}
      />
      {target === 'campaignId' && (
        <Typography variant="h5">{emailProviderName}</Typography>
      )}
      <ReferenceManyField reference="email" target={target}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="type" />
          <EditButton />
          <FunctionField
            render={(innerRecord: any) => (
              <TestEmailButton
                email={innerRecord}
                setCurrEmail={setCurrEmail}
                setTestDialogOpen={setTestDialogOpen}
              />
            )}
          ></FunctionField>
        </Datagrid>
      </ReferenceManyField>
      <AddEmailButton target={target} />
    </FormTab>
  );
};

export default EmailFormTab;
