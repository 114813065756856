import React, { useState } from 'react';
import {
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  useRefresh,
} from 'react-admin';
import SetupIncentiveButton from './SetupIncentiveButton';
import PullIncentiveButton from './PullIncentiveButton';

// Icons
import CouponIcon from '@mui/icons-material/LocalActivity';
import {
  AmazonGiftCardSupportedLocales,
  IncentiveProvision,
  IncentiveTypeChoices,
} from './IncentiveSetup';
import { SingleTextInput } from '../../CustomInputs/TextField';
import { useFormContext, useFormState } from 'react-hook-form';

export const CouponMode = [
  { id: 'FIXED', name: 'Fixed - one for all' },
  { id: 'MULTI', name: 'Multi - one per incentive' },
];

const AmazonGiftCardInputs: React.FC<{ getSource: any }> = ({ getSource }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <NumberInput
      source={getSource('settings.value')}
      label={'Value'}
      min={0}
      max={9999}
      isRequired={true}
      validate={[required()]}
    />
    <SelectInput
      source={getSource('settings.locale')}
      label="Currency"
      choices={AmazonGiftCardSupportedLocales}
      defaultValue="US"
    />
  </div>
);
const CustomCouponInputs: React.FC<{ getSource: any; scopedFormData: any }> = ({
  getSource,
  scopedFormData,
}) => {
  const formState = useFormState();
  const record = useRecordContext();
  const { getValues } = useFormContext();
  const [customCoupons, setCustomCoupons] = useState('');
  const refresh = useRefresh();
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
      <SelectInput
        source={getSource('settings.mode')}
        label="Mode"
        choices={CouponMode}
      />
      <div>
        {getValues(getSource('settings.mode')) === 'MULTI'
          ? 'Coupons Loaded: ' +
            (getValues(getSource('settings.couponCount')) || 0)
          : 'Fixed Coupon Code: ' +
            (getValues(getSource('settings.fixedCouponCode')) ||
              'Not Configured')}
      </div>
      <SingleTextInput
        multiline={getValues(getSource('settings.mode')) === 'MULTI'}
        value={customCoupons}
        title={'Enter Coupon Codes'}
        labelId={'Enter Coupon Codes'}
        onChange={event => setCustomCoupons(event.target.value)}
        rows={5}
      />
      <SetupIncentiveButton
        label="Generate coupons"
        disabled={formState.isDirty}
        campaignId={record.id as string}
        incentiveSettingIdSource={getSource('id')}
        couponsList={customCoupons?.split('\n')}
        onSetupDone={() => refresh()}
      >
        <CouponIcon />
      </SetupIncentiveButton>
    </div>
  );
};

const ShopifyInputs: React.FC<{
  formData: any;
  rest: any;
  showButtons: boolean;
  getSource: any;
  metadata: any;
}> = ({ formData, rest, showButtons, getSource, metadata }) => {
  const record = useRecordContext();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SelectInput
        source={getSource('settings.discountType')}
        label="Discount type"
        choices={[
          { id: 'fixed_amount', name: 'Fixed amount' },
          { id: 'percentage', name: 'Percentage discount' },
        ]}
      />
      <NumberInput
        source={getSource('settings.discountValue')}
        label={`Value (${
          formData.incentiveSetting?.settings?.discountType === 'percentage'
            ? '%'
            : '$'
        })`}
        min={0}
        max={
          formData.incentiveSetting?.settings?.discountType === 'percentage'
            ? 100
            : 9999
        }
      />
      <NumberInput
        source={getSource('settings.discountAmount')}
        label="Amount of coupons"
        min={1}
        max={9999}
      />
      <TextInput
        source={getSource('settings.discountCodePrefix')}
        label="Discount code prefix"
      />
      {showButtons ? (
        // @ts-ignore
        metadata?.discountJobStatus &&
        metadata?.discountJobStatus !== 'DONE' ? (
          // @ts-ignore
          <PullIncentiveButton
            record={record}
            stepIdSource={getSource('stepId')}
          />
        ) : (
          <SetupIncentiveButton
            label="Create coupons"
            disabled={
              // @ts-ignore
              !(record.incentiveSetting?.type === 'SHOPIFY') &&
              // @ts-ignore
              !record.incentiveSetting?.metadata?.discountJobStatus
            }
            campaignId={record.id as string}
            incentiveSettingIdSource={getSource('id')}
            stepIdSource={getSource('stepId')}
            {...rest}
          >
            <CouponIcon />
          </SetupIncentiveButton>
        )
      ) : (
        <div />
      )}
    </div>
  );
};

const OtherInputs: React.FC<{ getSource: any }> = ({ getSource }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TextInput
      source={getSource('settings.desc')}
      label="Incentive Description"
    />
  </div>
);

const IncentiveListSetup: React.FC<{
  formData: any;
  getSource: any;
  showButtons?: boolean;
  [key: string]: any;
}> = ({ showButtons = true, formData, getSource, ...restProps }: any) => {
  const stepChoices = (formData.steps ?? []).map((step: any) => ({
    id: step.id,
    name: `${step.name} (${step.type})`,
  }));
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ArrayInput source="incentiveSetting">
        <SimpleFormIterator>
          <RadioButtonGroupInput
            source="type"
            label="Type"
            choices={IncentiveTypeChoices}
            defaultValue={'AMAZON_GIFT_CARD'}
            {...restProps}
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              return (
                <>
                  {
                    <SelectInput
                      source={getSource!('incentiveProvision')}
                      label="Condition"
                      choices={IncentiveProvision}
                      onChange={event => {
                        scopedFormData.stepId = '';
                      }}
                    />
                  }
                  {scopedFormData.incentiveProvision === 'ONE_VETTED' ? (
                    <SelectInput
                      source={getSource!('stepId')}
                      label="Step"
                      choices={stepChoices}
                    />
                  ) : (
                    <div />
                  )}
                  {scopedFormData.type === 'SHOPIFY' ? (
                    <ShopifyInputs
                      formData={formData}
                      rest={rest}
                      getSource={getSource}
                      metadata={scopedFormData.metadata}
                      showButtons={showButtons}
                    />
                  ) : scopedFormData.type === 'AMAZON_GIFT_CARD' ? (
                    <AmazonGiftCardInputs getSource={getSource} />
                  ) : scopedFormData.type === 'CUSTOM' ? (
                    <CustomCouponInputs getSource={getSource} scopedFormData />
                  ) : scopedFormData.type === 'OTHER' ? (
                    <OtherInputs getSource={getSource} />
                  ) : (
                    <div />
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );
};

export default IncentiveListSetup;
