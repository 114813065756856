import React from 'react';
import { Tooltip } from '@mui/material';
import flagEmoji from 'emoji-flags';

type Props = {
  userCountryCode: string | undefined;
  includeLabel?: boolean;
};

const CountryDisplay: React.FC<Props> = (props) => {
  const { userCountryCode } = props;
  const contryDetails = userCountryCode && flagEmoji.countryCode(userCountryCode);

  if (!contryDetails) return <span>N/A</span>;

  const includeLabel = props?.includeLabel;

  return includeLabel ? (
    <span>
      {contryDetails.name} {contryDetails.emoji}
    </span>
  ) : (
    <Tooltip title={contryDetails.name}>
      <span>{contryDetails.emoji}</span>
    </Tooltip>
  );
};

export default CountryDisplay;
