import React from 'react';
import {
  Create,
  SimpleForm,
  required,
  NumberInput,
  number,
  minValue,
  Toolbar,
  SaveButton,
  useRedirect,
  TextInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import { useQuery } from '../utils/general';

export const BatchCreate: React.FC = (props: any) => {
  const query = useQuery();
  const redirect = useRedirect();
  const campaignId = query.get('campaignId');

  return (
    <Create
      mutationOptions={{
        onSuccess: () => {
          if (campaignId) redirect(`/campaigns/${campaignId}/3`);
        },
      }}
      {...props}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable {...props} />
          </Toolbar>
        }
      >
        <TextInput source="campaignId" disabled defaultValue={campaignId} />
        <Typography variant="h6">Batch Settings</Typography>
        <NumberInput
          source="maxBatchSize"
          label="Size Limit"
          validate={[required(), number(), minValue(1)]}
          isRequired
          defaultValue={100}
        />
      </SimpleForm>
    </Create>
  );
};
