import { useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  List,
  ReferenceArrayInput,
  Pagination,
  ReferenceInput,
  SelectInput,
  usePermissions,
} from 'react-admin';
import { ChipInput } from '../components/ChipInput';
import { EDITING_USAGE_CHOICES } from '../components/VideoSessions/consts';
import { TAKE_STATUS_CHOICES } from '../components/VideoSessions/QuickEditTake';
import SessionFooter from '../components/VideoSessions/SessionFooter';
import MediaGallery from '../components/VideoSessions/MediaGallery';
import { VideoSessionBulkActionButtons } from '../components/VideoSessions/VideoSessionBulkActionButtons';
import { ItemType } from '../components/GalleryItem';
import { Role } from './users';

export const VideoSessionList = (props: any) => {
  const { permissions: role } = usePermissions();
  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [excludedBrandIds, setExcludedBrandIds] = useState<number[]>([]);

  const handleExcludedBrandsChange = (newExcludedBrandIds: any[]) => {
    setExcludedBrandIds(newExcludedBrandIds);
  };

  const VideoSessionFilters = [
    <ReferenceInput source="brandId" reference="brands" alwaysOn>
      <AutocompleteInput
        optionText="name"
        onChange={e => setSelectedBrand(parseInt(e) ?? null)}
      />
    </ReferenceInput>,
    <ReferenceArrayInput
      source="excludedBrandIds"
      sort={{ field: 'name', order: 'ASC' }}
      filter={{}}
      reference="brands"
      alwaysOn
    >
      <AutocompleteArrayInput
        label="Excluded Brands"
        multiple
        defaultValue={excludedBrandIds}
        onChange={handleExcludedBrandsChange}
        optionText="name"
        style={{ maxWidth: '200px' }}
      />
    </ReferenceArrayInput>,
    <ReferenceInput
      source="campaignId"
      reference="campaigns"
      filter={selectedBrand ? { brandId: selectedBrand } : {}}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={100}
      alwaysOn
    >
      <SelectInput optionText="title" />
    </ReferenceInput>,
    <SelectInput
      source="takeStatus"
      format={val => (!val ? '' : val)}
      label="Status"
      choices={[...TAKE_STATUS_CHOICES]}
      alwaysOn
    />,
    <SelectInput
      source="editingUsage"
      format={val => (!val ? '' : val)}
      label="Editing Usage"
      choices={[...EDITING_USAGE_CHOICES]}
      alwaysOn
    />,

    <ChipInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="retakesOnly" alwaysOn sx={{ mr: 15 }} />,
  ].concat(
    role === Role.ADMIN
      ? [
          <BooleanInput
            label="Include from archived brands"
            source="includeDeletedBrands"
            alwaysOn
          />,
        ]
      : [],
  );

  return (
    <List
      sx={{ mt: 2 }}
      {...props}
      filters={VideoSessionFilters}
      filterDefaultValues={{
        quality: 'eco',
        consentGiven: null,
        includeHidden: true,
        includeUnconfirmedTakes: true,
      }}
      sort={{
        order: 'DESC',
        field: 'createdAt',
      }}
      perPage={24}
      pagination={<Pagination rowsPerPageOptions={[12, 24, 48]} />}
      actions={
        <div
          style={{
            display: 'flex',
          }}
        >
          <VideoSessionBulkActionButtons />
        </div>
      }
    >
      <MediaGallery itemType={ItemType.TAKE} Footer={SessionFooter} />
    </List>
  );
};

export { VideosessionShow } from '../components/VideoSessions/SessionShow';
