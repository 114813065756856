import { Button, Show, useRecordContext } from 'react-admin';
import { Grid } from '@mui/material';

import { useEffect, useState } from 'react';
import { StepDisplay } from './StepDisplay';
import DownloadIcon from '@mui/icons-material/GetApp';
import { ToggleShowHideButton } from './ToggleShowHideButton';
import { nullsLastSorter } from '../../utils/general';
import useDownloadWithStream from '../../hooks/useDownloadWithStream';
import CopySession from './CopySession';
import { BrandVettingMethod } from '../../types';
import { FORCE_ENABLE_TAKE_VETTING } from './consts';

const actionsRowStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  margin: '1% 0.5% 0.5% -1%',
};

const VideoTakesDisplay: React.FC = () => {
  const videoSession = useRecordContext<IVideoSession>();

  const { downloadVideoSessionsWithStream } = useDownloadWithStream();

  const [sessionTakes, setSessionTakes] = useState<{
    [key: string]: {
      takes: any[];
      sequence: number | null;
      name?: string;
      videoSceneId?: string;
    };
  }>({});

  useEffect(() => {
    if (!videoSession) return;

    const takesByStep = videoSession.videoScenes.reduce(
      (
        acc: {
          [key: number]: {
            takes: any[];
            sequence: number | null;
            name?: string;
          };
        },
        videoScene: any,
      ) => {
        return {
          ...acc,
          [videoScene.stepId]: {
            takes: [
              ...(acc[videoScene.stepId]?.takes || []),
              ...(videoScene.takes || []),
            ],
            sequence: videoScene.step?.funnelSequence,
            name: videoScene.step?.name,
          },
        };
      },
      {},
    );
    setSessionTakes(takesByStep);
  }, [videoSession]);

  if (!videoSession) return null;

  return (
    <Grid item xs={12}>
      <div style={actionsRowStyle}>
        <ToggleShowHideButton
          isHidden={videoSession?.isHidden}
          id={videoSession?.id}
          showLabel="Show session in Dashboard"
          hideLabel="Hide session in Dashboard"
          resource="videosessions"
          entityName="Video session"
        />
        <Button
          style={{ paddingBottom: '0' }}
          label="Download"
          onClick={() => downloadVideoSessionsWithStream([videoSession])}
        >
          <DownloadIcon />
        </Button>
      </div>
      <div style={actionsRowStyle}>
        <CopySession record={videoSession} />
      </div>
      <h3 style={{ padding: '8px 16px' }}>
        <a
          style={{ color: 'inherit' }}
          href={`/#/brands/${videoSession.campaign.brandId}`}
        >
          {videoSession.campaign.brand.name}
        </a>{' '}
        -{' '}
        <a
          style={{ color: 'inherit' }}
          href={`/#/campaigns/${videoSession.campaignId}`}
        >
          {videoSession.campaign.title}
        </a>
      </h3>
      {Object.keys(sessionTakes)
        .sort((k1, k2) =>
          nullsLastSorter(sessionTakes[k1].sequence, sessionTakes[k2].sequence),
        )
        .map((stepId: any) => (
          <StepDisplay
            campaignId={videoSession?.campaignId}
            key={stepId}
            sequence={sessionTakes[stepId].sequence}
            takes={sessionTakes[stepId].takes}
            stepName={sessionTakes[stepId].name}
            sessionId={videoSession.id}
            disableTakeVetting={
              videoSession.campaign.brand?.vettingMethod ===
                BrandVettingMethod.BRAND && !FORCE_ENABLE_TAKE_VETTING
            }
          />
        ))}
    </Grid>
  );
};

export const VideosessionShow: React.FC<any> = props => {
  return (
    <Show {...props} sx={{ backgroundColor: 'transparent !important' }}>
      <Grid container spacing={2}>
        <VideoTakesDisplay />
      </Grid>
    </Show>
  );
};
