import { useNotify, useUpdate } from 'ra-core';
import { useEffect } from 'react';
import { ShowToggleProps } from '../../ToggleShowHideButton';

export const useUpdateShow = ({
  isHidden,
  id,
  entityName,
  resource,
}: Omit<ShowToggleProps, 'label'>) => {
  const notify = useNotify();
  const [update, { isLoading, isError, error, isSuccess, data: resourceData }] =
    useUpdate(resource, {
      id,
      data: { isHidden: !isHidden },
    });

  useEffect(() => {
    if (!isLoading) {
      if (isError && error) {
        notify(`${entityName} update failed`, {
          type: 'error',
        });
      }
      if (isSuccess) {
        notify(`${entityName} updated successfully`, {
          type: 'success',
        });
      }
    }
  }, [entityName, error, isError, isLoading, isSuccess, notify]);

  return {
    updateVideoSession: update,
    resourceData,
  };
};
