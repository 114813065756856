import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputProps, useInput } from 'react-admin';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
} from '@mui/material';

interface ChipInputProps {
  spaceSubmit?: boolean;
  validate?: (value: string) => string;
  valuesSeparator?: string;
}

const defaultChipInputProps = {
  spaceSubmit: false,
  valuesSeparator: ' ',
};

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const DEFUALT_INPUT_SUBMITION_KEYS = ['Enter'];

export const ChipInput = (props: ChipInputProps & InputProps) => {
  const effectiveProps = { ...defaultChipInputProps, ...props };
  const { label, spaceSubmit, validate, valuesSeparator } = effectiveProps;

  // React admin input
  const {
    field: { value, onChange },
  } = useInput(props);

  if (value && !Array.isArray(value)) {
    throw new Error(
      `ChipInput requires an array value but received: ${JSON.stringify(
        value,
      )}`,
    );
  }

  // Handle text input
  const [textValue, setTextValue] = React.useState<string>('');

  // Handle errors
  const [textError, setTextError] = React.useState<string>('');

  // Handle submition keys
  const inputSubmitionKeys = spaceSubmit
    ? DEFUALT_INPUT_SUBMITION_KEYS.concat('Space')
    : DEFUALT_INPUT_SUBMITION_KEYS;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') event.preventDefault();

    if (inputSubmitionKeys.indexOf(event.key) > -1 && textValue.length > 0) {
      const newValues = textValue.split(valuesSeparator);

      let effectiveValidate: (val: string) => string = (val: string) => '';

      if (validate) {
        effectiveValidate = validate;
      }

      const errorsMap = newValues
        .map((val: string) => ({ [val]: effectiveValidate(val) }))
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});

      setTextError(
        Object.keys(errorsMap)
          .filter((key: string) => errorsMap[key].length > 0)
          .reduce(
            (prev: string, curr: string) => [prev, errorsMap[curr]].join('# '),
            '',
          ),
      );

      onChange(
        Array.from(
          new Set<string>(
            (value || []).concat(
              Object.keys(errorsMap).filter(
                (key: string) => errorsMap[key].length === 0,
              ),
            ),
          ),
        ),
      );

      setTextValue(
        Object.keys(errorsMap)
          .filter((key: string) => errorsMap[key].length > 0)
          .join(valuesSeparator),
      );
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const clearAll = (event: React.ChangeEvent<any>) => {
    onChange([]);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      <FormControl
        variant="standard"
        {...(textError && textError.length > 0 ? { error: true } : {})}
      >
        <Grid justifyContent={'center'}>
          <InputLabel htmlFor="component-text">{label}</InputLabel>
          <Input
            id="component-text"
            value={textValue}
            onChange={handleTextChange}
            onKeyDown={handleKeyPress}
            aria-describedby="component-error-text"
          />
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={clearAll}
          >
            <DeleteIcon />
          </IconButton>
          <FormHelperText id="component-error-text">{textError}</FormHelperText>
        </Grid>
      </FormControl>
      <Grid container direction="row">
        {value &&
          value.map((data: any) => {
            return (
              <ListItem key={`${data}_li`}>
                <Chip
                  id={data}
                  key={`${data}_chip`}
                  label={data}
                  onDelete={() => {
                    onChange(
                      (value || []).filter((item: any) => item !== data),
                    );
                  }}
                />
              </ListItem>
            );
          })}
      </Grid>
    </Grid>
  );
};
