import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const multiTextFieldHeadStyle = makeStyles({
  multiTextFieldHeadStyle: {
    'margin-right': '4px',
  },
});

const multiTextFieldValueStyle = makeStyles({
  multiTextFieldValueStyle: { 'font-style': 'italic' },
});

interface CreatorDisplayProps {
  creator: {
    id: string | number;
    fullname: string;
    email: string;
  };
}

export const CreatorDisplay: React.FC<CreatorDisplayProps> = ({ creator }) => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <span className={multiTextFieldHeadStyle().multiTextFieldHeadStyle}>
              Id:
            </span>
            <span
              className={multiTextFieldValueStyle().multiTextFieldValueStyle}
            >
              {creator?.id ? creator?.id : '-'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={multiTextFieldHeadStyle().multiTextFieldHeadStyle}>
              Name:
            </span>
            <span
              className={multiTextFieldValueStyle().multiTextFieldValueStyle}
            >
              {creator?.fullname ? creator?.fullname : '-'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className={multiTextFieldHeadStyle().multiTextFieldHeadStyle}>
              Email:
            </span>
            <span
              className={multiTextFieldValueStyle().multiTextFieldValueStyle}
            >
              {creator?.email ? creator?.email : '-'}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
