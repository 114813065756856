export const DimensionChoices = [
  { id: '9X16', name: '9X16' },
  { id: '16X9', name: '16X9' },
  { id: '1X1', name: '1X1' },
  { id: '4X5', name: '4X5' },
  { id: '5X4', name: '5X4' },
];

export const VariationsChoices = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
  { id: 'F', name: 'F' },
  { id: 'G', name: 'G' },
];

export const platforms = Object.freeze([
  'FACEBOOK',
  'TIKTOK',
  'YOUTUBE',
  'SNAPCHAT',
  'WEBSITE',
]);

export const platformsChoices = platforms.map(platform => ({
  id: platform,
  name: `${platform.charAt(0)}${platform.slice(1).toLocaleLowerCase()}`,
}));
