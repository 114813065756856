import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  BooleanField,
  ReferenceInput,
  SelectInput,
  UrlField,
  BooleanInput,
  FunctionField,
  AutocompleteInput,
  TextInput,
  usePermissions,
} from 'react-admin';
import { SupportedLocalesChoices } from '../../../consts';
import { Role } from '../../../resources/users';

export const CampaignList: React.FC = (props: any) => {
  const { permissions: role } = usePermissions();

  return (
    <List
      {...props}
      filters={[
        <ReferenceInput source="brandId" reference="brands" alwaysOn>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>,
        <SelectInput
          source="language"
          choices={SupportedLocalesChoices}
          optionValue="shortId"
          optionText="shortEngName"
          alwaysOn
        />,
        <BooleanInput source="isActive" />,

        <TextInput source="q" alwaysOn label="Search" />,
      ].concat(
        role === Role.ADMIN
          ? [
              <BooleanInput
                label="Include from archived brands"
                source="includeDeletedBrands"
                alwaysOn
              />,
            ]
          : [],
      )}
      perPage={25}
      sort={{ field: 'createdAt', order: 'desc' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="brandId" reference="brands">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="title" />
        <TextField source="language" />
        <BooleanField source="isActive" />
        <BooleanField source="avoidDesktop" />
        <FunctionField
          label="Stats"
          render={(record: any) => {
            const getPercent = (metric: number) =>
              `${
                Math.round((metric * 100 * 100) / record.stats.delivered) / 100
              }%`;
            return record && record.stats && record.stats.delivered > 0 ? (
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li>{record.stats.delivered} - delivered</li>
                <li>
                  {record.stats.uniqueOpens} (
                  {getPercent(record.stats.uniqueOpens)}) - opened
                </li>
                <li>
                  {record.stats.uniqueClicks} (
                  {getPercent(record.stats.uniqueClicks)}) - clicked
                </li>
                <li>
                  {record.stats.videoSubmissions} (
                  {getPercent(record.stats.videoSubmissions)}) - submitted
                  videos
                </li>
                <li>{record.stats.unsubscribes} - unsubscribed</li>
                <li>{record.stats.spamReports} - reported spam </li>
              </ul>
            ) : null;
          }}
        />
        <DateField source="createdAt" />
        <UrlField source="link" target="_blank" label="Camera Link" />
      </Datagrid>
    </List>
  );
};
