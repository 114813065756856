import { Autocomplete, TextField } from '@mui/material';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix, faBlockQuestion } from '@fortawesome/pro-light-svg-icons';
import { SelectInput } from 'react-admin';

interface IconFromFaResponse {
  id: string;
  label: string;
  familyStylesByLicense: {
    free: { style: string }[];
    pro: { style: string }[];
  };
}
const getStyleId = (style: string) => {
  switch (style) {
    case 'brands':
      return 'fab';
    case 'regular':
      return 'far';
    case 'light':
      return 'fal';
    case 'thin':
      return 'fat';
    case 'duonote':
      return 'fad';
    default:
      return 'fas';
  }
};

const getIconStyles = (styles: string[]) =>
  styles?.map(style => ({ id: getStyleId(style), name: style })) || [];
type FontAwesomeOption = {
  id: string;
  name: string;
  styleId?: IconPrefix;
  styles?: string[];
};

interface Props {
  source: string;
}
export const FontAwesomeIconSelect: React.FC<Props> = ({ source }) => {
  const { setValue, getValues } = useFormContext();
  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState<FontAwesomeOption[]>([]);
  const [isLoadingIcons, setIsLoadingIcons] = useState(false);

  const getFontAwesomeIcons = (value: string) => {
    !isLoadingIcons && setIsLoadingIcons(true);
    let result: FontAwesomeOption[] = [];
    try {
      axios
        .post(
          'https://api.fontawesome.com',
          {
            oparetionName: 'fetchIcons',
            query: `query {search( version: "6.2.0", query: "${
              value || 'a'
            }", first: 30 ) { id,label,familyStylesByLicense{
            free {
              style
            }
            pro {
              style
            }
          
          }
        }
      }`,
            variables: {},
          },
          {
            headers: {
              'content-type': 'application/json',
            },
          },
        )
        .then(res => {
          const { data } = res as AxiosResponse<{
            data: { errors: any[]; search: IconFromFaResponse[] };
          }>;
          if (data?.data?.errors) throw new Error('Failed to fetch icons');
          if (!data?.data?.search) return [];
          result = data?.data?.search.map(icon => ({
            id: icon.id,
            name: icon.label,
            styles: _.union(
              icon.familyStylesByLicense.free.map(
                ({ style }: { style: string }) => style,
              ),
              icon.familyStylesByLicense.pro.map(
                ({ style }: { style: string }) => style,
              ),
            ),
          }));
          setOptions(result);
          return result;
        })
        .catch(err => {
          console.error(`Failed to fetch icons: ${JSON.stringify(err)}`);
        })
        .finally(() => setIsLoadingIcons(false));
    } catch (error) {
      console.error(
        'Failed to fetch font awesome icons: ',
        JSON.stringify(error),
      );
      setIsLoadingIcons(false);
    }

    return result;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOptions = useCallback(_.debounce(getFontAwesomeIcons, 250), []);

  useLayoutEffect(() => {
    getFontAwesomeIcons(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentValue = getValues(source);
  return (
    <>
      <Autocomplete
        id="font-awesome-icon-select"
        fullWidth
        options={options}
        loading={isLoadingIcons}
        defaultValue={getValues(source)}
        inputValue={inputValue}
        blurOnSelect={false}
        clearOnBlur={false}
        onInputChange={(event, value, reason) => {
          if ((event && event.type === 'blur') || reason === 'clear') {
            setInputValue('');
            setOptions([]);
          } else {
            setInputValue(value);
            if (!value) {
              setOptions([]);
              return;
            }
          }
          debounceOptions(value);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(e, value) => {
          setValue(source, value || null, {
            shouldDirty: true,
          });
        }}
        getOptionLabel={option => option?.name}
        renderOption={(props, option, { selected }) => {
          return (
            <li
              {...props}
              key={`${option.id}_${option.name}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={
                  getIconStyles(option.styles)?.[0]
                    ? [
                        getIconStyles(option.styles)?.[0]?.id as IconPrefix,
                        option.id,
                      ]
                    : faBlockQuestion
                }
                style={{
                  marginLeft: '-5px',
                  marginRight: '8px',
                  width: '20px',
                  height: '20px',
                }}
              />
              <span>{option.name}</span>
            </li>
          );
        }}
        renderInput={params => <TextField {...params} label="Icons" />}
      />
      {currentValue?.styles && (
        <SelectInput
          source={`${source}.styleId`}
          choices={getIconStyles(currentValue.styles)}
          value={currentValue?.styleId}
        />
      )}
    </>
  );
};
