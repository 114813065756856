import React, { useState } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EmailField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import SessionFooter from '../components/VideoSessions/SessionFooter';
import MediaGallery from '../components/VideoSessions/MediaGallery';
import { Grid } from '@mui/material';
import { SingleCampaignModal } from '../components/Campaign/SingleCampaignModal';
import { CreatorBatchList } from '../components/Creator/CreatorBatchList';
import { ItemType } from '../components/GalleryItem';

const CreatorFilters = [
  <ReferenceInput source="brandId" reference="brands" alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <TextInput source="email" alwaysOn />,
];

export const CreatorList: React.FC = (props: any) => (
  <List filters={CreatorFilters} perPage={25} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="fullname" />
      <EmailField source="email" />
      <TextField source="phone" />
      <NumberField source="_count.brands" label="Brands" />
      <NumberField source="_count.subscribed" label="Subscribed" />
    </Datagrid>
  </List>
);

export const CreatorShow: React.FC = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="fullname" />
        <EmailField source="email" />
        <TextField source="phone" />
        <Grid container spacing={2} mb={1} mt={2} ml={0.1}>
          <Button
            variant="outlined"
            label="Send Campaign"
            onClick={() => setIsOpen(true)}
          />
          <SingleCampaignModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        </Grid>

        <ArrayField source="brands">
          <Datagrid style={{ marginBottom: '2rem' }}>
            <ReferenceField source="brandId" reference="brands">
              <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isSubscribed" />
            <DateField source="subscribedAt" showTime />
            <BooleanField source="welcomeEmailSent" />
            <BooleanField source="blacklist" />
          </Datagrid>
        </ArrayField>

        <CreatorBatchList />

        <ReferenceManyField
          reference="videosessions"
          target="creatorId"
          label="Video Sessions"
          filter={{ includeHidden: true, includeUnconfirmedTakes: true }}
        >
          <MediaGallery itemType={ItemType.TAKE} Footer={SessionFooter} />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
