import { Grid } from '@mui/material';
import { FormDataConsumer } from 'ra-core';
import { RichTextInput } from 'ra-input-rich-text';
import { BooleanInput } from 'react-admin';

const CustomTosForm = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <BooleanInput
                  source="overrideGlobalTOS"
                  defaultValue={!!formData.customTOS}
                  label="Override Global TOS"
                />

                {formData.overrideGlobalTOS && (
                  <RichTextInput
                    source="customTOS"
                    fullWidth
                    label="Custom TOS"
                  />
                )}
              </div>
            </Grid>
            {formData.overrideGlobalTOS && (
              <Grid item xs={6}>
                <h3 style={{ textDecorationLine: 'underline' }}>
                  Custom TOS Preview:
                </h3>
                <span
                  dangerouslySetInnerHTML={{ __html: formData.customTOS }}
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    </FormDataConsumer>
  );
};
export default CustomTosForm;
