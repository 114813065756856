import React from 'react';
import {
  Create,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  useRedirect,
} from 'react-admin';
import { useQuery } from '../utils/general';

const INTEGRATION_CHOICES = [{ id: 'WEBHOOKS', name: 'Webhooks' }];

export const IntegrationCreate: React.FC = (props: any) => {
  const query = useQuery();
  const redirect = useRedirect();
  const brandId = query.get('brandId');
  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: () => {
          if (brandId) redirect(`/brands/${brandId}/3`);
        },
      }}
    >
      <SimpleForm defaultValues={{ brandId }}>
        <ReferenceInput
          source="brandId"
          reference="brands"
          label="Brand"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="vendor"
          choices={INTEGRATION_CHOICES}
          isRequired
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
