import {
  Button,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CachedIcon from '@mui/icons-material/Cached';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  applyBrandKitOnCampaign,
  duplicateCampaign,
  reloadFromTemplates,
} from '../../../providers/dataProvider';

const CampaignEditActions = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();

  return (
    <TopToolbar>
      <Button
        disabled={!record || !record.link}
        href={record && `${record.link}?preview=true`}
        label="Camera"
        // @ts-ignore
        target="_blank"
      >
        <OpenInNewIcon />
      </Button>
      <Button
        label="Duplicate"
        disabled={!record || !record.id}
        onClick={async () => {
          try {
            const response = await duplicateCampaign(record.id as string);
            if (response.json && response.json.id) {
              redirect(`/campaigns/${response.json.id}`);
            }
            notify(`Campaign duplicated!`, { type: 'success' });
          } catch (err) {
            notify(`Failed to duplicate campaign`, { type: 'error' });
          }
        }}
      >
        <FileCopyIcon />
      </Button>
      <Button
        label="Reload from templates"
        disabled={!record || !record.id}
        onClick={async () => {
          try {
            await reloadFromTemplates(record && record.id);
            refresh();
            notify(`Reload succeeded!`, { type: 'success' });
          } catch (err) {
            notify(`Failed to reload from templates`, { type: 'error' });
          }
        }}
      >
        <CachedIcon />
      </Button>
      <Button
        label="Apply brand kit"
        disabled={!record || !record.id}
        onClick={async () => {
          try {
            const campaignId: string = record.id as string;
            await applyBrandKitOnCampaign(campaignId);
            refresh();
            notify(`Apply succeeded!`, { type: 'success' });
          } catch (err) {
            notify(`Failed to apply brand kit`, { type: 'error' });
          }
        }}
      >
        <CachedIcon />
      </Button>
    </TopToolbar>
  );
};

export default CampaignEditActions;
