import makeStyles from '@mui/styles/makeStyles';

export const stickyToolbar = makeStyles({
  root: {
    '& .MuiPaper-root': {
      overflow: 'initial',
    },
    '& .RaToolbar-desktopToolbar': {
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
    },
  },
});
