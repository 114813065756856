import { useGetList } from 'ra-core';
import { TextField } from 'react-admin';
import { Comments } from '../Comments/Comments';

type Props = {
  adId: string;
  versionId: string;
  feedbackContentClass: string;
};
export const CommentsDisplay = ({
  adId,
  versionId,
  feedbackContentClass,
}: Props) => {
  const { data: versionComments, isLoading } = useGetList(
    `videocreatives/${adId}/versions/${versionId}/comments`,
  );

  if (isLoading) return <div>Loading...</div>;

  return versionComments && versionComments?.length > 0 ? (
    <div
      style={{
        width: '100%',
        maxHeight: window.innerHeight - 250,
        overflow: 'hidden',
        overflowY: 'auto',
      }}
    >
      {versionComments
        .sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        )

        .map((comment: any) => (
          <Comments
            key={`comment_${comment.id}`}
            comment={comment}
            feedbackContentClass={feedbackContentClass}
          />
        ))}
    </div>
  ) : (
    <TextField
      className={feedbackContentClass}
      emptyText="No note was published for this version yet"
    />
  );
};
