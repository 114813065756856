import { minValue, required, useRecordContext } from 'ra-core';
import { ChangeEvent, useEffect } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

export const VettedInvitationSetup = ({
  setEnabled,
  enabled = false,
}: {
  enabled?: boolean;
  setEnabled: (enabled: boolean) => void;
}) => {
  const record = useRecordContext();

  const { setValue } = useFormContext();

  useEffect(() => {
    // init state for setup toggler when data changes
    setEnabled(!!Object.keys(record?.nextCampaignMetadata || {}).length);
    setValue(
      'enabled',
      Object.keys(record?.nextCampaignMetadata || {}).length > 0,
    );
  }, [record?.nextCampaignMetadata, setEnabled, setValue]);

  return (
    <>
      <BooleanInput
        source="nextCampaignMetadata.enabled"
        label="Continuing campaign enabled"
        checked={enabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setEnabled(e.target.checked);
          setValue('enabled', e.target.checked, {
            shouldDirty: true,
          });
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <p style={{ marginRight: '10px' }}>When users are</p>
          <SelectInput
            source="nextCampaignMetadata.onlyFirstTimeVetted"
            label=""
            format={(value: string) =>
              typeof value === 'string'
                ? value
                : record?.nextCampaignMetadata?.onlyFirstTimeVetted
                ? 'first'
                : 'any'
            }
            choices={[
              { id: 'first', name: 'vetted for the first time' },
              { id: 'any', name: 'vetted for any submission' },
            ]}
            disabled={!enabled}
            isRequired={enabled}
            validate={enabled ? undefined : [required()]}
          />
          ,
        </div>

        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <p style={{ marginRight: '10px' }}>invite them to</p>
          <div
            style={{
              alignSelf: 'start',
              marginTop: '-15px',
            }}
          >
            <ReferenceInput
              source="nextCampaignMetadata.campaignId"
              reference="campaigns"
              filter={{
                isActive: true,
              }}
              sort={{ field: 'createdAt', order: 'DESC' }}
              perPage={100}
              alwaysOn
            >
              <AutocompleteInput
                optionText="title"
                label="Next Campaign"
                style={{
                  width: '300px',
                }}
                disabled={!enabled}
                isRequired={enabled}
                validate={
                  !enabled
                    ? undefined
                    : [required(), (value: any) => value ?? false]
                }
              />
            </ReferenceInput>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <p style={{ marginRight: '10px' }}>after waiting for</p>
          {/* [Enter number of days] */}
          <NumberInput
            source="nextCampaignMetadata.daysDelay"
            label="Delay"
            style={{ maxWidth: '100px' }}
            disabled={!enabled}
            isRequired={enabled}
            min={0}
            validate={enabled ? undefined : [required(), minValue(0)]}
          />
        </div>
        <p style={{ marginLeft: '10px' }}>days from sending last incentive.</p>
      </div>
    </>
  );
};
