import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useGetList } from 'ra-core';
import { ChangeEvent, useState } from 'react';
import { isImgUrl, isVideoURL } from '../utils/general';
import { uploadFile, uploadFileWithURL } from '../providers/dataProvider';

const DIRECT_UPLOAD_FILE_MAX_SIZE_KB = 20 * 1024;
const FileUploadDialog: React.FC<{
  onClose: (url?: string) => void;
  tag?: string;
  accept?: string;
  maxFileSizeKB?: number;
}> = ({ onClose, tag, accept = '*/*', maxFileSizeKB, ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const perPage = 10;
  const { data, isLoading, isError, total } = useGetList(
    'upload',
    {
      filter: { tag },
      pagination: { page, perPage },
    },
    { enabled: isOpen },
  );

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileSizeKB = file.size / 1024;
      if (maxFileSizeKB && fileSizeKB > maxFileSizeKB) {
        alert(`File size is greater than maximum limit of ${maxFileSizeKB} KB`);
      } else {
        try {
          setIsUploading(true);
          let fileURL: string = '';
          if (fileSizeKB <= DIRECT_UPLOAD_FILE_MAX_SIZE_KB) {
            const res = await uploadFile(file, tag);
            fileURL = res.json.url;
          } else {
            fileURL = await uploadFileWithURL(file, tag);
          }
          onClose(fileURL);
          setIsOpen(false);
        } finally {
          setIsUploading(false);
        }
      }
    }
  };
  return (
    <div>
      <IconButton color="primary" onClick={() => setIsOpen(true)}>
        <UploadFileIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          onClose();
        }}
      >
        <DialogTitle id="trans-dialog-title">File Upload</DialogTitle>
        <DialogContent>
          <FormControl>
            <Button component="label" startIcon={<UploadFileIcon />}>
              {isUploading ? <CircularProgress /> : 'Upload'}
              <input
                hidden
                accept={accept}
                type="file"
                onChange={handleFileChange}
              />
            </Button>
          </FormControl>
          {isLoading ? (
            <span>Loading</span>
          ) : isError ? (
            <span>Error</span>
          ) : (
            <div>
              <Typography variant="h6" sx={{ marginBottom: '15px' }}>
                Recent uploads
              </Typography>
              <Grid container>
                {data?.map((d: any) => (
                  <Grid container key={d.id}>
                    <Grid item xs={3}>
                      <Tooltip title={d.name}>
                        <Typography noWrap>{d.name}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'center' }}>
                      {isImgUrl(d.url) && (
                        <img
                          src={d.url}
                          alt={d.name}
                          style={{ maxHeight: '50px' }}
                        />
                      )}
                      {isVideoURL(d.url) && (
                        <video
                          src={d.url}
                          style={{ maxHeight: '250px' }}
                          controls
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <button
                        onClick={e => {
                          e.preventDefault();
                          onClose(d.url);
                          setIsOpen(false);
                        }}
                      >
                        Select
                      </button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  disabled={total ? page * perPage >= total : true}
                  onClick={() => setPage(page + 1)}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FileUploadDialog;
