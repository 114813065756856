import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  currentIndex: number;
  sortedData: any[];
  onNextClick: () => void;
  onPrevClick: () => void;
};
const NavigationArrows = ({
  currentIndex,
  sortedData,
  onNextClick,
  onPrevClick,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <div>
        {currentIndex > 0 && (
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
            }}
            onClick={onPrevClick}
          />
        )}
      </div>

      <div>
        {currentIndex < sortedData.length - 1 && (
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{
              width: '20px',
              height: '20px',
              cursor: 'pointer',
            }}
            onClick={onNextClick}
          />
        )}
      </div>
    </div>
  );
};

export default NavigationArrows;
