import React from 'react';
import { Tooltip } from '@mui/material';
import { ReactComponent as facebookSvg } from '../assets/platforms/facebook.svg';
import { ReactComponent as snapchatSvg } from '../assets/platforms/snapchat.svg';
import { ReactComponent as tiktokSvg } from '../assets/platforms/tiktok.svg';
import { ReactComponent as websiteSvg } from '../assets/platforms/website.svg';
import { ReactComponent as youtubeSvg } from '../assets/platforms/youtube.svg';

type Props = {
  platform: string;
};

const PlatformToSvg: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  FACEBOOK: facebookSvg,
  SNAPCHAT: snapchatSvg,
  TIKTOK: tiktokSvg,
  WEBSITE: websiteSvg,
  YOUTUBE: youtubeSvg,
};

const PlatformDisplay: React.FC<Props> = props => {
  const { platform } = props;
  const Element = PlatformToSvg[platform?.toLocaleUpperCase()];

  if (Element === undefined) return <span>N/A</span>;

  return (
    <Tooltip
      title={`${platform.charAt(0)}${platform
        .substring(1)
        .toLocaleLowerCase()}`}
    >
      <Element height={'95%'} />
    </Tooltip>
  );
};

export default PlatformDisplay;
