import { Link } from 'react-router-dom';
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FormDataConsumer,
  FormTab,
  FunctionField,
  maxLength,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  useRecordContext,
  RadioButtonGroupInput,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import { Card, Typography } from '@mui/material';
import { facingModeChoices, SupportedLocalesChoices } from '../consts';
import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import {
  CastBrandVaribalesList,
  CastCampaignVaribalesList,
  CastIncentiveVaribalesList,
  VarsLegendItems,
} from '../components/EmailTemplates/CastVariables';
import {
  cleanEmptyValues,
  EmptyTextualFieldProps,
  useQuery,
} from '../utils/general';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash.get';
import FileUploadDialog from '../components/FileUploadDialog';
import StepTextAlignment from '../components/InteractionTextAlignment';
import { FontAwesomeIconSelect } from '../components/FontAwesomeIconSelector/FontAwesomeIconSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StepPreviewWithNavigation from '../components/Campaign/Steps/StepPreviewWithNavigation';

const typeChoices = [
  { id: 'VIDEO', name: 'Video' },
  { id: 'CAMERA', name: 'Camera' },
  { id: 'REMINDER', name: 'Reminder' },
  { id: 'TEXT', name: 'Text' },
  { id: 'CREATOR_FORM', name: 'Creator Form' },
  { id: 'LANDING_PAGE', name: 'Landing page' },
  { id: 'DIRECTIONS', name: 'Direction page' },
  { id: 'CARDS', name: 'Cards' },
];

const titleTypeChoices = [
  { id: 'LOGO', name: 'Logo' },
  { id: 'TEXT', name: 'Free text' },
];

const iconAndTextSizeChoices = [
  { id: 'SMALL', name: 'Small' },
  { id: 'MEDIUM', name: 'Medium' },
  { id: 'LARGE', name: 'Large' },
];

const TYPES_WITH_PREDEFINIED_BUTTONS = ['CAMERA', 'CREATOR_FORM'];

const HighlightsInput: React.FC<{
  getSource: (src: string) => string;
  [key: string]: any;
}> = (props: any) => {
  const record = useRecordContext(props);
  const { getSource } = props;

  useEffect(() => {
    if (record && record?.props) {
      if (!record?.props?.highlights || !Array.isArray(record.props.highlights))
        record.props.highlights = [];
    }
  }, [record]);

  return (
    <ArrayInput
      source={getSource('highlights')}
      label="Highlights"
      validate={(value: Array<{ text: string; order: number }>) =>
        value && value.length > 5 ? 'Max 5 highlights are allowed' : undefined
      }
    >
      <SimpleFormIterator>
        <TextInput
          source="text"
          label="Text"
          fullWidth
          multiline
          validate={[maxLength(100)]}
          {...EmptyTextualFieldProps}
        />
        <NumberInput source="order" label="Order" />
        <BooleanInput
          source="keepOnScreen"
          label="Keep highlights on screen while recording"
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const StepPropsInput: React.FC<{
  getSource?: (src: string) => string;
}> = ({ getSource = src => `props.${src}` }) => {
  const { setValue, getValues } = useFormContext();

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StepTextAlignment getSource={getSource} />
            {formData.type === 'VIDEO' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput
                  source={getSource('url')}
                  label="Video URL"
                  fullWidth
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <FileUploadDialog
                  onClose={url =>
                    setValue(getSource('url'), url, { shouldDirty: true })
                  }
                  tag="videos.video"
                  accept="video/*"
                  maxFileSizeKB={50000000}
                />
                <NumberInput
                  source={getSource('showButtonsAfter')}
                  label="Show buttons after (% of video)"
                  defaultValue={0}
                />
                <HighlightsInput getSource={getSource} />
              </div>
            )}
            {formData.type === 'CAMERA' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SelectInput
                  source={getSource('facingMode')}
                  label="Facing Mode"
                  choices={facingModeChoices}
                  defaultValue="user"
                  isRequired
                  validate={[required()]}
                  {...rest}
                  {...EmptyTextualFieldProps}
                />

                <TextInput
                  source={getSource('question')}
                  label="Step question"
                  multiline
                  {...EmptyTextualFieldProps}
                />
                <BooleanInput
                  source={getSource('keepQuestionOnScreen')}
                  defaultValue={true}
                  label="Show question on record start"
                />
                <BooleanInput
                  source={getSource('skipUploadPreview')}
                  defaultValue={false}
                  label={`Skip preview after recording`}
                />
                <HighlightsInput getSource={getSource} />
              </div>
            )}
            {formData.type === 'REMINDER' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput
                  source={getSource('iconURL')}
                  label="Icon URL"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <CardIconPreview />
                <FileUploadDialog
                  onClose={url =>
                    setValue(getSource('iconURL'), url, { shouldDirty: true })
                  }
                  tag="reminder.icon"
                  accept="image/*"
                  maxFileSizeKB={2 * 1024 * 1024}
                />
                <TextInput
                  source={getSource('title')}
                  label="Title"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('eventName')}
                  label="Event Name"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('details')}
                  label="Detail"
                  multiline
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <NumberInput
                  source={getSource('durationMinutes')}
                  label="Duration (Minutes)"
                  {...rest}
                />
              </div>
            )}
            {formData.type === 'TEXT' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput
                  source={getSource('iconURL')}
                  label="Icon URL"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />

                <CardIconPreview />

                <SelectInput
                  source={getSource('imageSize')}
                  defaultValue={'SMALL'}
                  label="Image size"
                  choices={iconAndTextSizeChoices}
                />

                <FileUploadDialog
                  onClose={url =>
                    setValue(getSource('iconURL'), url, { shouldDirty: true })
                  }
                  tag="text.icon"
                  accept="image/*"
                  maxFileSizeKB={2 * 1024 * 1024}
                />

                <TextInput
                  source={getSource('title')}
                  label="Title"
                  multiline
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('text')}
                  label="Text"
                  multiline
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
              </div>
            )}
            {formData.type === 'CREATOR_FORM' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <TextInput
                    source={getSource('title')}
                    label="Title"
                    {...EmptyTextualFieldProps}
                  />
                  <TextInput
                    source={getSource('subtitle')}
                    label="Subtitle"
                    {...EmptyTextualFieldProps}
                  />
                  <TextInput
                    source={getSource('submitCTA')}
                    label="Submit CTA"
                    {...EmptyTextualFieldProps}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <BooleanInput
                    source={getSource('fullname.show')}
                    label="Full name"
                    defaultValue={false}
                  />
                  <BooleanInput
                    source={getSource('fullname.required')}
                    label="Required"
                    disabled={!get(formData, getSource('fullname.show'))}
                    defaultValue={false}
                  />
                  <TextInput
                    source={getSource('fullname.placeholder')}
                    label="Placeholder"
                    disabled={!get(formData, getSource('fullname.show'))}
                    defaultValue="Full Name"
                    {...EmptyTextualFieldProps}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <BooleanInput
                    source={getSource('email.show')}
                    label="Email"
                    defaultValue={false}
                  />
                  <BooleanInput
                    source={getSource('email.required')}
                    label="Required"
                    disabled={!get(formData, getSource('email.show'))}
                    defaultValue={false}
                  />
                  <TextInput
                    source={getSource('email.placeholder')}
                    label="Placeholder"
                    disabled={!get(formData, getSource('email.show'))}
                    defaultValue="Email address"
                    {...EmptyTextualFieldProps}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <BooleanInput
                    source={getSource('phone.show')}
                    label="Phone"
                    defaultValue={false}
                  />
                  <BooleanInput
                    source={getSource('phone.required')}
                    label="Required"
                    disabled={!get(formData, getSource('phone.show'))}
                    defaultValue={false}
                  />
                  <TextInput
                    source={getSource('phone.placeholder')}
                    label="Placeholder"
                    disabled={!get(formData, getSource('phone.show'))}
                    defaultValue="Phone Number"
                    {...EmptyTextualFieldProps}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <BooleanInput
                    source={getSource('consent.show')}
                    label="Consent"
                    defaultValue={true}
                  />
                  <TextInput
                    source={getSource('consent.placeholder')}
                    label="Placeholder"
                    disabled={!get(formData, getSource('consent.show'))}
                    fullWidth
                    {...EmptyTextualFieldProps}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <ArrayInput
                    source={getSource('choice.options')}
                    label="Choice"
                    {...rest}
                  >
                    <SimpleFormIterator>
                      <TextInput
                        source="name"
                        label="Name"
                        {...EmptyTextualFieldProps}
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                  <div
                    style={{ minWidth: '200px', margin: '30px 0px 0px 20px' }}
                  >
                    <BooleanInput
                      source={getSource('choice.required')}
                      label="Required"
                      defaultValue={false}
                    />
                    <TextInput
                      source={getSource('choice.placeholder')}
                      label="Placeholder"
                      disabled={
                        (get(formData, getSource('choice.options'))?.length ||
                          0) < 1
                      }
                      {...EmptyTextualFieldProps}
                    />
                  </div>
                </div>
                <BooleanInput
                  source={getSource('skipIfEmailExists')}
                  label="Skip if email exists"
                  defaultValue={false}
                />
              </div>
            )}
            {formData.type === 'LANDING_PAGE' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">General</Typography>
                <BooleanInput
                  source={getSource('skipInvitation')}
                  defaultValue={false}
                  label="Skip Invitation"
                  {...rest}
                />
                <Typography variant="h6">Invitation</Typography>
                <TextInput
                  source={getSource('invitation.title')}
                  label="Title"
                  multiline
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('invitation.text')}
                  label="Text"
                  multiline
                  {...rest}
                />
                <TextInput
                  source={getSource('invitation.incentive')}
                  label="Incentive (i.e. $50 / 10%)"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('invitation.creatorsImgURL')}
                  label="Creators Image URL"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('invitation.creatorsCaption')}
                  label="Creators Image Caption"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <TextInput
                  source={getSource('invitation.acceptInvitationCTA')}
                  label="Accept Invitation CTA"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <Typography variant="h6">Guidance</Typography>
                <TextInput
                  source={getSource('guidance.title')}
                  label="Title"
                  isRequired
                  multiline
                  validate={[required()]}
                  {...rest}
                />
                <TextInput
                  source={getSource('guidance.text')}
                  label="Text"
                  isRequired
                  multiline
                  validate={[required()]}
                  {...rest}
                />
                <ArrayInput
                  source={getSource('guidance.cards')}
                  label="Cards"
                  {...rest}
                >
                  <SimpleFormIterator>
                    <TextInput
                      source="iconURL"
                      label="Icon URL"
                      fullWidth
                      isRequired
                      validate={[required()]}
                      multiline
                      {...rest}
                      {...EmptyTextualFieldProps}
                    />
                    <CardIconPreview />
                    <FileUploadDialogWrapper
                      field="iconURL"
                      setValue={setValue}
                      tag="guidance.cards.icon"
                      accept="image/*"
                      maxFileSizeKB={500}
                    />
                    <TextInput
                      source="text"
                      label="Text"
                      fullWidth
                      isRequired
                      validate={[required()]}
                      multiline
                      {...rest}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  source={getSource('guidance.examplesCaption')}
                  label="Video Examples Caption"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <ArrayInput
                  source={getSource('guidance.examples')}
                  label="Video Examples"
                  {...rest}
                >
                  <SimpleFormIterator>
                    <TextInput
                      source="url"
                      label="URL"
                      fullWidth
                      {...EmptyTextualFieldProps}
                    />
                    <FileUploadDialogWrapper
                      field="url"
                      setValue={setValue}
                      tag="guidance.examples.video"
                      accept="video/*"
                      maxFileSizeKB={100 * 1024}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  source={getSource('guidance.helpText')}
                  label="Help text"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <SelectInput
                  source={getSource('guidance.helpTextLocation')}
                  style={{ width: '45%' }}
                  label="Help text location"
                  defaultValue={'LOW'}
                  choices={[
                    { id: 'UP', name: 'Upper part' },
                    { id: 'LOW', name: 'Lower part' },
                  ]}
                />
                <BooleanInput
                  source={getSource('guidance.showSocialProof')}
                  label="Show social proof"
                />
              </div>
            )}
            {formData.type === 'DIRECTIONS' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <RadioButtonGroupInput
                  source={getSource('titleType')}
                  label="Title type"
                  choices={titleTypeChoices}
                  defaultValue={'LOGO'}
                />
                {getValues(getSource('titleType')) === 'TEXT' && (
                  <TextInput
                    source={getSource('headerText')}
                    label="Header text"
                    isRequired
                    multiline
                    validate={[required()]}
                    {...rest}
                  />
                )}
                <SelectInput
                  source={getSource('titleSize')}
                  defaultValue={'MEDIUM'}
                  label="Title size"
                  choices={iconAndTextSizeChoices.filter(c => c.id !== 'SMALL')}
                />
                <BooleanInput
                  source={getSource('shouldHighlightIncentive')}
                  label="Should highlight incentive"
                />
                <TextInput
                  source={getSource('title')}
                  label="Title"
                  isRequired
                  multiline
                  validate={[required()]}
                  {...rest}
                />
                <TextInput
                  source={getSource('text')}
                  label="Text"
                  multiline
                  {...rest}
                />
                <SelectInput
                  source={getSource('bulletTextAlignment')}
                  label="Bullets alignment"
                  choices={[
                    { id: 'LEFT', name: 'Left' },
                    { id: 'CENTER', name: 'Center' },
                    { id: 'RIGHT', name: 'Right' },
                  ]}
                  defaultValue={'LEFT'}
                />
                <ArrayInput
                  source={getSource('bullets')}
                  label="Bullets"
                  {...rest}
                >
                  <SimpleFormIterator>
                    <FontAwesomeIconSelect source={'icon'} />
                    <CardIconPreview />
                    <TextInput
                      source="text"
                      label="Text"
                      fullWidth
                      isRequired
                      validate={[required()]}
                      multiline
                      {...rest}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <SelectInput
                  source={getSource('exampleLocation')}
                  label="Examples position"
                  choices={[
                    { id: 'UP', name: 'Upper part' },
                    { id: 'DOWN', name: 'Lower part' },
                  ]}
                />
                <TextInput
                  source={getSource('examplesCaption')}
                  label="Examples caption"
                  multiline
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <ArrayInput
                  source={getSource('examples')}
                  label="Examples"
                  {...rest}
                >
                  <SimpleFormIterator>
                    <TextInput
                      source="url"
                      label="URL"
                      fullWidth
                      {...EmptyTextualFieldProps}
                    />
                    <FileUploadDialogWrapper
                      field="url"
                      setValue={setValue}
                      tag="examples.video"
                      accept="video/*,image/*"
                      maxFileSizeKB={100 * 1024}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <BooleanInput
                  source={getSource('showSocialProof')}
                  label="Show social proof"
                />
                <TextInput
                  source={getSource('creatorsImgURL')}
                  label="Creators Image URL"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <FileUploadDialog
                  onClose={url =>
                    setValue(getSource('creatorsImgURL'), url, {
                      shouldDirty: true,
                    })
                  }
                  tag="creatorsImgURL.icon"
                  accept="image/*"
                  maxFileSizeKB={2 * 1024 * 1024}
                />
                <TextInput
                  source={getSource('creatorsCaption')}
                  label="Creators Image Caption"
                  {...rest}
                  {...EmptyTextualFieldProps}
                />
                <BooleanInput
                  source={getSource('showPoweredBy')}
                  label="Show powered by"
                />
                <BooleanInput source={getSource('showTOS')} label="Show TOS" />
              </div>
            )}
            {formData.type === 'CARDS' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TextInput
                  source={getSource('title')}
                  label="Title"
                  isRequired
                  validate={[required()]}
                  {...rest}
                />
                <TextInput
                  source={getSource('subtitle')}
                  label="Subtitle"
                  {...rest}
                />
                <ArrayInput source={getSource('cards')} label="Cards" {...rest}>
                  <SimpleFormIterator>
                    <TextInput
                      source="iconURL"
                      label="Icon URL"
                      fullWidth
                      isRequired
                      validate={[required()]}
                    />
                    <CardIconPreview />
                    <FileUploadDialogWrapper
                      field="iconURL"
                      setValue={setValue}
                      tag="cards.icon"
                      accept="image/*"
                      maxFileSizeKB={500}
                    />
                    <TextInput
                      source="text"
                      label="Text"
                      fullWidth
                      isRequired
                      validate={[required()]}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            )}
          </div>
          <StepPreviewWithNavigation formData={formData} />
        </div>
      )}
    </FormDataConsumer>
  );
};

const FileUploadDialogWrapper: React.FC<{
  setValue: (
    source: string,
    value: string,
    options?: { shouldDirty: boolean },
  ) => void;
  field: string;
  source?: string;
  accept?: string;
  tag?: string;
  maxFileSizeKB?: number;
}> = ({ field, setValue, source, accept, tag, maxFileSizeKB }) => {
  if (!source) return <div />;

  return (
    <FileUploadDialog
      onClose={(url?: string) => {
        if (url) setValue(`${source}.${field}`, url, { shouldDirty: true });
      }}
      tag={tag}
      accept={accept}
      maxFileSizeKB={maxFileSizeKB}
    />
  );
};

const CardIconPreview = (props: any) => {
  const { getValues } = useFormContext();
  const card = getValues(props.source);
  const url = card?.iconURL || card?.props?.iconURL;
  const faIcon = card?.icon || card?.props?.icon;

  if (!(url || faIcon)) {
    return <div />;
  }

  return (
    <div>
      <span>Preview: </span>
      {faIcon ? (
        <FontAwesomeIcon
          icon={[faIcon.styleId, faIcon.id]}
          style={{ width: '30px', height: '30px' }}
        />
      ) : (
        <img src={url} alt="iconURL" style={{ maxHeight: '25px' }} />
      )}
    </div>
  );
};

const AddButtonButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      style={{ marginTop: '20px' }}
      component={Link}
      variant="contained"
      to={`/buttons/create?stepId=${record.id}&${
        record.campaignId
          ? `campaignId=${record.campaignId}`
          : `campaignTemplateId=${record.campaignTemplateId}`
      }`}
      label="Create Button"
    >
      <AddIcon />
    </Button>
  );
};

const AddSubtitleButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      style={{ marginTop: '20px' }}
      component={Link}
      variant="contained"
      to={`/subtitles/create?stepId=${record.id}`}
      label="Create Subtitle"
    >
      <AddIcon />
    </Button>
  );
};

const StepTitle: React.FC = () => {
  const record = useRecordContext();

  const linkProps = useMemo(() => {
    if (record && record.campaignId) {
      return {
        to: `/campaigns/${record.campaignId}/1`,
        title: `Campaign ${record.campaignId} (Step "${record.name}")`,
      };
    }
    if (record && record.campaignTemplateId) {
      return {
        to: `/templates/${record.campaignTemplateId}/1`,
        title: `Template ${record.campaignTemplateId} (Step "${record.name}")`,
      };
    }
    return { to: `#`, title: `Step ${(record && record.name) || 'missing'}` };
  }, [record]);
  return <Link to={linkProps.to}>{linkProps.title}</Link>;
};

const Translations = () => {
  const campaignTemplateId = useWatch({ name: 'campaignTemplateId' });

  if (!campaignTemplateId)
    return <div>Translations is only availables on step templates</div>;

  return (
    <Grid container spacing={2}>
      {/* ArrayInput with the same source (lang.id = "en") creates bugs - does not allow to remove items; Look at the prev commit  */}
      {SupportedLocalesChoices.filter(lang => lang.shortId !== 'en').map(
        lang => (
          <Grid item key={lang.shortId}>
            <Card style={{ padding: '10px' }}>
              <Typography variant="h6">{lang.shortEngName}</Typography>
              <StepPropsInput
                getSource={src => `translations.${lang.shortId}.${src}`}
              />
            </Card>
          </Grid>
        ),
      )}
    </Grid>
  );
};

export const StepEdit = (props: any) => {
  const [isLegendVisible, setLegendVisibility] = React.useState<Boolean>(false);

  return (
    <Edit
      transform={cleanEmptyValues}
      redirect={false}
      mutationMode="pessimistic"
      title={<StepTitle />}
      {...props}
    >
      <TabbedForm>
        <FormTab label="summary">
          <Typography variant="h5">Step details</Typography>
          <TextInput source="name" isRequired validate={[required()]} />
          <TextInput source="campaignId" disabled />
          <ReferenceInput
            source="campaignTemplateId"
            reference="templates"
            {...EmptyTextualFieldProps}
          >
            <SelectInput
              optionText="name"
              disabled
              {...EmptyTextualFieldProps}
            />
          </ReferenceInput>
          <SelectInput
            source="type"
            choices={typeChoices}
            isRequired
            validate={[required()]}
          />
          {isLegendVisible ? (
            <Grid
              display="flex"
              direction={'column'}
              justifyContent={'space-around'}
            >
              <Grid container display="flex" direction={'row'} wrap="wrap">
                <h3>Variables:</h3>
                <Button
                  onClick={() => setLegendVisibility(false)}
                  label="Hide"
                />
              </Grid>
              <h4>General Variables:</h4>
              <Grid container display="flex" direction={'row'} wrap="wrap">
                {VarsLegendItems([
                  ...CastBrandVaribalesList,
                  ...CastCampaignVaribalesList,
                  ...CastIncentiveVaribalesList,
                ])}
              </Grid>
            </Grid>
          ) : (
            <Button
              onClick={() => setLegendVisibility(true)}
              label="Show Variables"
            />
          )}
          <StepPropsInput />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              TYPES_WITH_PREDEFINIED_BUTTONS.includes(formData.type) && (
                <ReferenceInput
                  source="nextStepId"
                  reference="steps"
                  filter={
                    formData.campaignId
                      ? { campaignId: formData.campaignId }
                      : { campaignTemplateId: formData.campaignTemplateId }
                  }
                  label="Next Step"
                >
                  <SelectInput optionText="name" {...EmptyTextualFieldProps} />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <BooleanInput source="isRoot" isRequired validate={[required()]} />
          <NumberInput source="funnelSequence" />
        </FormTab>
        <FormTab label="Buttons">
          <ReferenceManyField reference="buttons" target="stepId">
            <Datagrid>
              <TextField source="id" />
              <TextField source="text" />
              <ReferenceField
                source="destStepId"
                reference="steps"
                label="Destination Step"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="destURL" label="Destination URL" />
              <BooleanField source="isPrimary" />
              <TextField source="purpose" />
              <TextField source="platforms" label="Platforms" />
              <EditButton />
              <DeleteButton mutationMode="optimistic" redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <AddButtonButton />
        </FormTab>
        <FormTab label="Subtitles">
          <ReferenceManyField reference="subtitles" target="stepId">
            <Datagrid>
              <NumberField source="start" />
              <NumberField source="end" />
              <FunctionField
                label="Text"
                render={(record: any) => (
                  <span>{record.lines.map((l: any) => l.text).join(' ')}</span>
                )}
              />
              <EditButton />
              <DeleteButton mutationMode="optimistic" redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <AddSubtitleButton />
        </FormTab>
        <FormTab label="Translations">
          <Translations />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const StepCreate = (props: any) => {
  const query = useQuery();
  const campaignId = query.get('campaignId');
  const campaignTemplateId = query.get('campaignTemplateId');

  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (response: any) => {
    notify(`Step created`);
    const { id, type, campaignId, campaignTemplateId } = response;
    const redirectTo = ['VIDEO', 'TEXT', 'CARDS'].includes(type)
      ? `/steps/${id}/1`
      : campaignId
      ? `/campaigns/${campaignId}/1`
      : `/templates/${campaignTemplateId}/1`;
    redirect(redirectTo);
    refresh();
  };

  const [isLegendVisible, setLegendVisibility] = React.useState<Boolean>(false);

  return (
    <Create
      {...props}
      mutationOptions={{ onSuccess }}
      title="Create Step"
      transform={cleanEmptyValues}
    >
      <SimpleForm
        defaultValues={{ campaignId, isRoot: false, campaignTemplateId }}
      >
        <Typography variant="h5">Step creation</Typography>
        <TextInput source="name" isRequired validate={[required()]} />
        <TextInput source="campaignId" disabled defaultValue={campaignId} />
        <ReferenceInput
          source="campaignTemplateId"
          reference="templates"
          disabled
          {...EmptyTextualFieldProps}
        >
          <SelectInput optionText="name" disabled {...EmptyTextualFieldProps} />
        </ReferenceInput>
        <SelectInput
          source="type"
          choices={typeChoices}
          isRequired
          validate={[required()]}
          {...EmptyTextualFieldProps}
        />
        {isLegendVisible ? (
          <Grid
            display="flex"
            direction={'column'}
            justifyContent={'space-around'}
          >
            <Grid container display="flex" direction={'row'} wrap="wrap">
              <h3>Variables:</h3>
              <Button onClick={() => setLegendVisibility(false)} label="Hide" />
            </Grid>
            <h4>General Variables:</h4>
            <Grid container display="flex" direction={'row'} wrap="wrap">
              {VarsLegendItems([
                ...CastBrandVaribalesList,
                ...CastCampaignVaribalesList,
                ...CastIncentiveVaribalesList,
              ])}
            </Grid>
          </Grid>
        ) : (
          <Button
            onClick={() => setLegendVisibility(true)}
            label="Show Variables"
          />
        )}
        <StepPropsInput />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            TYPES_WITH_PREDEFINIED_BUTTONS.includes(formData.type) && (
              <ReferenceInput
                source="nextStepId"
                reference="steps"
                filter={
                  formData.campaignId
                    ? { campaignId: formData.campaignId }
                    : { campaignTemplateId: formData.campaignTemplateId }
                }
                label="Next Step"
              >
                <SelectInput optionText="name" {...EmptyTextualFieldProps} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <BooleanInput source="isRoot" />
        <NumberInput source="funnelSequence" />
      </SimpleForm>
    </Create>
  );
};
