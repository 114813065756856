import { ReactElement } from 'react';
import { RaRecord, useRecordContext } from 'react-admin';
import get from 'lodash/get';

export const WithFile = (props: WithFileProps) => {
  const record = useRecordContext() || props.record;
  const { source, render } = props;
  const fileBlob = get(record, source || 'src');

  return fileBlob &&
    record &&
    record.rawFile &&
    record.rawFile.name &&
    record.rawFile.type
    ? render(
        record.rawFile.name,
        record.rawFile.type,
        fileBlob,
        record.rawFile,
        record,
      )
    : null;
};

export interface WithFileProps {
  source?: string;
  render: (
    fileName: string,
    fileType: string,
    fileBlob: string,
    rawFile: File,
    record: RaRecord,
  ) => ReactElement;
  label?: string;
  record?: RaRecord;
}
