import React from 'react';
import { Button, useNotify } from 'react-admin';
import { setupIncentive } from '../../../providers/dataProvider';
import { useFormContext } from 'react-hook-form';

const SetupIncentiveButton: React.FC<{
  campaignId: string;
  children: React.ReactElement;
  disabled: boolean;
  label: string;
  incentiveSettingIdSource?: string;
  couponsList?: string[];
  onSetupDone?: () => void;
}> = props => {
  const { getValues } = useFormContext();
  const incentiveSettingId = getValues(
    props.incentiveSettingIdSource as string,
  );
  const couponsList = props.couponsList;
  const { disabled, label, children, campaignId, onSetupDone } = props;
  const [setupFinished, setSetupFinished] = React.useState<boolean>(false);
  const notify = useNotify();

  return (
    <div style={{ marginTop: '20px' }}>
      <Button
        label={label}
        variant="outlined"
        disabled={setupFinished || disabled}
        onClick={async () => {
          try {
            await setupIncentive(campaignId, incentiveSettingId, couponsList);
            setSetupFinished(true);
            onSetupDone && onSetupDone();
            notify('Incentive was successfully setup', { type: 'success' });
          } catch (err: any) {
            console.error(err);
            notify(`Failed to setup incentive: ${err?.toString()}`, {
              type: 'error',
            });
          }
        }}
      >
        {children}
      </Button>
    </div>
  );
};

export default SetupIncentiveButton;
