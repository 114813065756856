import { regex } from 'react-admin';
import { useLocation } from 'react-router-dom';

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
export const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function downloadFromURL(url: string, fileName = 'video') {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

export function bulkDownloadFromURLWithDelay(
  urls: { url: string; fileName?: string }[],
) {
  urls.forEach(({ url, fileName }, i) => {
    // delay is required to give time to the browser to download the files
    setTimeout(() => downloadFromURL(url, fileName), i * 1000);
  });
}

export function downloadBlob(blob: Blob, fileName: string) {
  // Create blob link to download
  const url = window.URL.createObjectURL(blob);
  downloadFromURL(url, fileName);
}

export const validateURL = regex(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  'Must be a valid URL',
);

export const transformEmptyObjectToNull = (value: { [key: string]: any }) => {
  const response = { ...value };

  function nullify(obj: any) {
    for (let key in obj) {
      if (JSON.stringify(obj[key]) === '{}' || obj[key] === '') {
        obj[key] = null;
      } else if (
        typeof obj[key] == 'object' &&
        !Date.parse(obj[key]) &&
        !(obj[key] instanceof File)
      ) {
        obj[key] = nullify(obj[key]);
      }
    }
    return obj;
  }
  nullify(response);
  return response;
};

export const cleanEmptyValues = (
  data: any,
  keepEmptyArrays = false,
  keepEmptyObject = false,
): any => {
  switch (typeof data) {
    case 'undefined':
      return null;
    case 'object':
      if (Array.isArray(data))
        return !keepEmptyArrays && data.length === 0
          ? null
          : data.map((item: any) => {
              return cleanEmptyValues(item, keepEmptyArrays, keepEmptyObject);
            });

      if (Date.parse(data) || data instanceof File) return data;

      if (data === null) return null;

      const keys = Object.keys(data);
      if (keys.length === 0 && !keepEmptyObject) return null;

      return Object.keys(data)
        .map((key: any) => ({
          [key]: cleanEmptyValues(data[key], keepEmptyArrays, keepEmptyObject),
        }))
        .filter((entry: { [key: string | number | symbol]: any }) => {
          const entryKey = Object.keys(entry)[0];
          return (
            keepEmptyObject ||
            (entry[entryKey] !== null && entry[entryKey] !== undefined)
          );
        })
        .reduce((prev, curr) => ({ ...prev, ...curr }), {});
    case 'string':
      return data.length === 0 ? null : data;
    default:
      return data;
  }
};

export const removeEmptyObjectFields = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== '')
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyObjectFields(v) : v]),
  );
};

export const EmptyTextualFieldProps = {
  format: (v?: string | null) => (!v ? '' : v),
  parse: (v?: string | null) => (!v || v === '' ? null : v),
};

export const formatDateTimeEnGB = (date: Date) =>
  `${date.toLocaleDateString('en-GB')} ${date.toLocaleTimeString('en-GB')}`;

export function isImgUrl(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function isVideoURL(url: string) {
  return /\.(avi|mkv|mov|mp4|webm)$/.test(url);
}

export function isAudioFile(name: string) {
  return /\.(mp3|mp4|wav)$/i.test(name);
}

export function isDocumentFile(name: string) {
  return /\.(pdf|doc|docx)$/i.test(name);
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const nullsLastSorter = (value1: number | null, value2: number | null) =>
  value1 === null ? 1 : value2 === null ? -1 : value1 - value2;
