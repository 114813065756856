import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  List,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  usePermissions,
} from 'react-admin';
import makeStyles from '@mui/styles/makeStyles';
import VideoAdFooter from '../components/VideoAds/VideoAdFooter';
import MediaGallery from '../components/VideoSessions/MediaGallery';
import { platformsChoices } from '../components/VideoAds/consts';
import { VideoAdsBulkActionButtons } from '../components/VideoAds/VideoAdsBulkActionButtons';
import { startOfMonth, endOfMonth } from 'date-fns';
import { ChipInput } from '../components/ChipInput';
import { ItemType } from '../components/GalleryItem';
import { Role } from './users';

const styles = makeStyles({
  listStyle: { 'margin-top': '10px' },
  platformSelectInput: { 'min-width': 'fit-content', width: 'fit-content' },
});

export const VideoAdsList = (props: any) => {
  const { permissions: role } = usePermissions();
  const now = new Date();
  const VideoAdsFilters = [
    <ReferenceInput key="brandId" source="brandId" reference="brands" alwaysOn>
      <AutocompleteInput key="brandId_input" optionText="name" />
    </ReferenceInput>,
    <SelectArrayInput
      key="platforms"
      label="Platforms"
      source="platforms"
      choices={platformsChoices}
      optionText="name"
      alwaysOn
    />,
    <DateInput key="fromDate" source="fromDate" label="from" alwaysOn />,
    <DateInput key="toDate" source="toDate" label="to" alwaysOn />,
    <ChipInput key="query" source="q" label="Search" alwaysOn />,
    <NumberInput key="sessionId" source="videoSessionId" alwaysOn />,
  ].concat(
    role === Role.ADMIN
      ? [
          <BooleanInput
            label="Include from archived brands"
            source="includeDeletedBrands"
            alwaysOn
          />,
        ]
      : [],
  );

  return (
    <List
      {...props}
      filters={VideoAdsFilters}
      sort={{
        field: 'createdAt',
        order: 'desc',
      }}
      filterDefaultValues={{
        fromDate: startOfMonth(now),
        toDate: endOfMonth(now),
      }}
      perPage={25}
      className={styles().listStyle}
      actions={<VideoAdsBulkActionButtons />}
    >
      <MediaGallery
        itemType={ItemType.VIDEOAD}
        Footer={VideoAdFooter}
        takeHeaderControls={false}
      />
    </List>
  );
};
