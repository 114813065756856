import {
  Button,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  applyBrandKitOnTemplate,
  duplicateTemplate,
} from '../../providers/dataProvider';

const TemplatesActions = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const record = useRecordContext<{ id: string }>();
  return (
    <TopToolbar>
      <Button
        label="Duplicate"
        disabled={!record?.id}
        onClick={async () => {
          try {
            const response = await duplicateTemplate(record.id);
            if (response.json && response.json.id) {
              redirect(`/templates/${response.json.id}`);
            }
            notify(`Step Template duplicated!`, { type: 'success' });
          } catch (err) {
            notify(`Failed to duplicate step template`, { type: 'error' });
          }
        }}
      >
        <FileCopyIcon />
      </Button>
      <Button
        label="Apply brand kit"
        disabled={!record?.id}
        onClick={async () => {
          try {
            // eslint-disable-next-line no-console
            console.log(record);
            const response = await applyBrandKitOnTemplate(record.id);
            if (response.json && response.json.id) {
              redirect(`/templates/${response.json.id}`);
            }
            notify(`Apply succeeded!`, { type: 'success' });
          } catch (err) {
            notify(`Failed to apply brand kit`, { type: 'error' });
          }
        }}
      >
        <FileCopyIcon />
      </Button>
    </TopToolbar>
  );
};

export default TemplatesActions;
