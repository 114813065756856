import AddIcon from '@mui/icons-material/Add';
import LockResetIcon from '@mui/icons-material/LockReset';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { find, groupBy, isEmpty, map } from 'lodash';
import React, { useState } from 'react';
import {
  BooleanInput,
  BulkDeleteButton,
  Button,
  Confirm,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  EmailField,
  List,
  PasswordInput,
  RaRecord,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useListContext,
  useNotify,
  usePermissions,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
  useUpdateMany,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { ChipInput } from '../components/ChipInput';
import { updateUserPassword } from '../providers/dataProvider';
import { cleanEmptyValues } from '../utils/general';
import { Role } from './users';

const BulkActions = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { data, selectedIds, onToggleItem } = useListContext();

  const selectedRecords = map(selectedIds, id => find(data, { id }));
  const { true: deleted, false: notDeleted } = groupBy(
    selectedRecords,
    'isDeleted',
  );

  const [open, setOpen] = useState(false);
  const [restoreMany, { isLoading }] = useUpdateMany(
    'accounts',
    {
      ids: map(deleted, ({ id }) => `${id}/restore`),
    },
    {
      mutationMode: 'optimistic',
      onSuccess: () => {
        notify('Accounts restored', { type: 'info' });
        deleted.forEach(({ id }) => onToggleItem(id));
        refresh();
      },
      onError: () => {
        notify('Error: Accounts restore failed', { type: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
    },
  );

  return (
    <>
      {!isEmpty(notDeleted) && (
        <BulkDeleteButton
          confirmTitle="Archive accounts"
          confirmContent={`Are you sure you want to archive ${notDeleted.length} and all brands related to them?`}
          mutationMode="pessimistic"
          label="Archive"
        />
      )}
      {!isEmpty(deleted) && (
        <>
          <Button label="Restore" onClick={() => setOpen(true)} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Restore accounts?"
            content={`Are you sure you want to restore ${deleted.length} accounts?`}
            onConfirm={() => restoreMany()}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </>
  );
};

export const AccountList: React.FC = (props: any) => {
  const { permissions: role } = usePermissions();

  return (
    <List
      filters={[<ChipInput source="q" label="Search" alwaysOn />].concat(
        role === Role.ADMIN ? (
          <BooleanInput source="includeDeleted" alwaysOn />
        ) : (
          []
        ),
      )}
      {...props}
    >
      <Datagrid bulkActionButtons={<BulkActions />} rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField label="Archived" source="isDeleted" />
      </Datagrid>
    </List>
  );
};

const InviteUserButton = () => {
  const record = useRecordContext();

  if (!record) return null;
  return (
    <Button
      component={Link}
      variant="contained"
      to={`/users/create?accountId=${record.id}`}
      label="Invite User"
      title="Invite User"
    >
      <AddIcon />
    </Button>
  );
};

const UpdatePassowrdDialog = ({
  userId,
  isOpen,
  onClose,
}: {
  userId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const notify = useNotify();

  const onSubmit = async (record: any) => {
    try {
      const data = {
        newPassword: record.password,
      };

      await updateUserPassword(userId, data);
      notify('Password updated', { type: 'success' });
      onClose();
    } catch (err) {
      console.error(err);
      notify('Password update failed', { type: 'error' });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Update Password</DialogTitle>
      <SimpleForm onSubmit={onSubmit} redirect={false} toolbar={false}>
        <DialogContent>
          <PasswordInput
            source="password"
            label="New Password"
            defaultValue=""
            isRequired
            validate={[required()]}
          />
        </DialogContent>
        <DialogActions>
          <SaveButton />
          <Button label="Cancel" onClick={onClose} />
        </DialogActions>
      </SimpleForm>
    </Dialog>
  );
};

const EditPasswordButton = ({
  onClick,
}: {
  onClick: (record: RaRecord) => void;
}) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Button label="Set Password" onClick={() => onClick(record)}>
      <LockResetIcon />
    </Button>
  );
};

export const AccountEdit: React.FC = (props: any) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [recordId, setRowRecordId] = useState<string>('0');

  const handleEditPassword = (record: any) => {
    setRowRecordId(record.id);
    setShowDialog(true);
  };
  return (
    <Edit {...props} transform={cleanEmptyValues}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="id" />
        <TextInput source="name" isRequired validate={[required()]} />
        <ReferenceManyField reference="users" target="accountId">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <DateField source="lastLogin" showTime />
            <EditButton label="Edit" />
            <EditPasswordButton
              onClick={record => handleEditPassword(record)}
            />
          </Datagrid>
        </ReferenceManyField>
        <InviteUserButton />
      </SimpleForm>
      <UpdatePassowrdDialog
        isOpen={showDialog}
        userId={recordId}
        onClose={() => setShowDialog(false)}
      />
    </Edit>
  );
};

const CustomToolbar = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const record = useRecordContext<{ isDeleted: boolean; id: string }>();
  const [open, setOpen] = useState(false);
  const [restore, { isLoading }] = useUpdate(
    'accounts',
    {
      id: `${record.id}/restore`,
      data: { id: record.id },
    },
    {
      onSuccess: () => {
        redirect('/accounts');
        notify('Account restored', { type: 'info' });
      },
      onError: () => {
        notify('Error: Account restore failed', { type: 'error' });
      },
      onSettled: () => {
        setOpen(false);
      },
      mutationMode: 'pessimistic',
    },
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      {record.isDeleted ? (
        <>
          <Button label="Restore" onClick={handleClick} />
          <Confirm
            isOpen={open}
            loading={isLoading}
            title="Restore account?"
            content="Are you sure you want to restore this item?"
            onConfirm={() => restore()}
            onClose={handleDialogClose}
          />
        </>
      ) : (
        <DeleteButton
          mutationOptions={{
            onSettled: (_, err) => {
              if (!err) {
                notify('Account archived', { type: 'info' });
              }
            },
          }}
          confirmContent="Are you sure you want to archive this account?"
          confirmTitle="Archive Account"
          mutationMode="pessimistic"
          label="Archive"
        />
      )}
    </Toolbar>
  );
};

export const AccountCreate: React.FC = (props: any) => (
  <Create {...props} transform={cleanEmptyValues}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" isRequired validate={[required()]} />
      <ReferenceInput
        source="packagePlanId"
        reference="package-plans"
        isRequired
        validate={[required()]}
        perPage={100}
      >
        <SelectInput optionText="name" validate={[required()]} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
