import {
  Button,
  RaRecord,
  SaveButton,
  SimpleForm,
  Toolbar,
  useGetList,
  useNotify,
} from 'react-admin';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import IconCancel from '@mui/icons-material/Cancel';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { copyVideoSession } from '../../providers/dataProvider';
import { useNavigate } from 'react-router-dom';

const CopySession: React.FC<{
  record: RaRecord;
}> = ({ record }) => {
  const notify = useNotify();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [targetBrandId, setTargetBrandId] = useState<string | null>(null);
  const [targetCampaignId, setTargetCampaignId] = useState<string | null>(null);

  const sessionId = useMemo(() => {
    return record?.id.toString();
  }, [record]);

  const { data: brands, isLoading: isBrandLoading } = useGetList('brands', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'desc' },
  });

  const { data: campaigns, isLoading: isCampaignLoading } = useGetList(
    'campaigns',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'desc' },
      filter: { brandId: targetBrandId },
    },
  );

  const handleCloseClick = () => {
    setShowDialog(false);
    setTargetBrandId(null);
    setTargetCampaignId(null);
  };

  const onSubmit = async () => {
    try {
      const response = await copyVideoSession({
        campaignId: targetCampaignId as string,
        videoSessionId: sessionId,
      });
      notify(`Video Session Duplicated (${response.id})`, {
        type: 'success',
      });
      handleCloseClick();
      navigate('/videosessions');
    } catch (err) {
      console.error(err);
      notify('Video session failed to duplicate', { type: 'error' });
    }
  };
  return (
    <>
      <Button
        style={{ paddingBottom: '0' }}
        label="Copy video session"
        onClick={() => setShowDialog(true)}
      >
        <CopyAllIcon />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <DialogTitle>Copy Video Session ({sessionId})</DialogTitle>
        <SimpleForm
          record={record}
          onSubmit={onSubmit}
          redirect={false}
          toolbar={
            <Toolbar
              style={{
                display: 'flex',
                width: '92%',
                justifyContent: 'flex-end',
              }}
            >
              <Button label="Cancel" onClick={handleCloseClick}>
                <IconCancel />
              </Button>
              <SaveButton alwaysEnable />
            </Toolbar>
          }
        >
          <DialogContent style={{ width: '90%' }}>
            <Grid container>
              <p>
                Choose the brand and the campaign to which you want to duplicate
                and copy this video session to.
              </p>
              <p>
                This ability is useful only when you want to use a video session
                for demo purposes inside another brand.
              </p>
              <Grid item xs={12}>
                <Paper sx={{ padding: '15px', marginTop: '10px' }}>
                  <Typography variant="body1">Choose target brand:</Typography>
                  <FormControl fullWidth>
                    <InputLabel id="target-brand-select-label">
                      Target brand
                    </InputLabel>
                    <Select
                      labelId="target-brand-select-label"
                      id="target-brand-select"
                      value={targetBrandId}
                      label="Brand"
                      onChange={e => setTargetBrandId(e.target.value)}
                    >
                      {!isBrandLoading &&
                        brands &&
                        brands?.length > 0 &&
                        brands?.map(c => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ padding: '15px', marginTop: '10px' }}>
                  <Typography variant="body1">
                    Choose target campaign:
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="target-campaign-select-label">
                      Target Campaign
                    </InputLabel>
                    <Select
                      labelId="target-campaign-select-label"
                      id="target-campaign-select"
                      value={targetCampaignId}
                      label="Campaign"
                      onChange={e => setTargetCampaignId(e.target.value)}
                      disabled={!targetBrandId}
                    >
                      {!isCampaignLoading &&
                        campaigns &&
                        campaigns?.length > 0 &&
                        campaigns?.map(c => (
                          <MenuItem key={c.id} value={c.id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        </SimpleForm>
      </Dialog>
    </>
  );
};

export default CopySession;
