import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';

interface CustomSelectProps {
  title: string;
  labelId: string;
  className?: string;
  style?: React.CSSProperties;
  onChange: (event: any) => void;
  options: any;
  value?: any;
  variant?: 'standard' | 'filled' | 'outlined';
}
export const SingleSelect: React.FC<CustomSelectProps> = ({
  title,
  labelId,
  className = '',
  style = {
    marginBottom: '10%',
    height: '55px',
    alignItems: 'flex-end',
  },
  onChange,
  options,
  value = '',
  variant = 'outlined',
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={labelId}>{title}</InputLabel>
      <Select
        className={className}
        id={labelId}
        style={{
          ...style,
          ...(!!value ? { backgroundColor: 'rgba(147, 250, 165)' } : {}),
        }}
        onChange={e => onChange(e)}
        labelId={labelId}
        variant={variant}
        value={value}
      >
        <MenuItem value="" style={{ height: '2rem' }}></MenuItem>
        {Array.isArray(options)
          ? options.map(option => (
              <MenuItem value={option.id} key={`${option.id}_${option.name}`}>
                {option.name}
              </MenuItem>
            ))
          : Object.keys(options).map(key => (
              <MenuItem value={key} key={`${labelId}_${key}`}>
                {options[key]}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export const MultiSelect: React.FC<CustomSelectProps> = ({
  title,
  labelId,
  className = '',
  style = {
    marginBottom: '10%',
    height: '55px',
    alignItems: 'flex-end',
  },
  onChange,
  options,
  value = [],
  variant = 'outlined',
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={labelId}>{title}</InputLabel>
      <Select
        className={className}
        id={labelId}
        labelId={labelId}
        multiple
        value={value}
        displayEmpty
        renderValue={(selected: string[]) =>
          selected.length ? (
            selected.length > 1 ? (
              `${selected.length} Selected`
            ) : (
              [options[selected[0]]]
            )
          ) : (
            <></>
          )
        }
        onChange={e => onChange(e)}
        style={{
          ...style,
          ...(!!value.length ? { backgroundColor: 'rgba(147, 250, 165)' } : {}),
        }}
        variant={variant}
      >
        {Array.isArray(options)
          ? options.map(option => (
              <MenuItem key={`${labelId}_${option?.id}`} value={option?.id}>
                <Checkbox checked={value?.indexOf(option.id) > -1} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))
          : Object.keys(options).map(key => (
              <MenuItem key={`${labelId}_${key}`} value={key}>
                <Checkbox checked={value?.indexOf(key) > -1} />
                <ListItemText primary={options[key]} />
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
