import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';

interface TextInputProps {
  title: string;
  labelId: string;
  value?: any;
  onChange: (event: any) => void;
  type?: 'text' | 'number' | 'color';
  withAutoComplete?: boolean;
  options?: any;
  style?: React.CSSProperties;
  maxLength?: number;
  tagLimit?: number;
  freeSolo?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  multiline?: boolean;
  rows?: number;
  required?: boolean;
  onInputChange?: (value: string) => void;
  onInputBlur?: () => void;
}

export const SingleTextInput: React.FC<TextInputProps> = ({
  title,
  labelId,
  onChange,
  value = '',
  type = 'text',
  withAutoComplete = false,
  options = null,
  style,
  maxLength = Infinity,
  variant = 'standard',
  multiline = false,
  rows,
  required = false,
}) => {
  return withAutoComplete ? (
    <FormControl fullWidth>
      <Autocomplete
        disablePortal
        id={labelId}
        value={value}
        style={{
          borderRadius: '5px',
          border: '1px solid gray',
          padding: '0 3%',
          ...(!!value
            ? {
                backgroundColor: 'rgba(147, 250, 165)',
              }
            : {}),
        }}
        onChange={(event, newValue) => onChange(newValue)}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            variant={variant}
            label={title}
            required
            style={{
              borderRadius: '5px',
              border: '1px solid gray',
              padding: '0 3%',
              ...(style ? style : {}),
              ...(!!value.length
                ? {
                    backgroundColor: 'rgba(147, 250, 165)',
                  }
                : {}),
            }}
            inputProps={{ ...params.inputProps, maxLength: maxLength }}
          />
        )}
      />
    </FormControl>
  ) : (
    <FormControl fullWidth>
      <TextField
        type={type}
        label={title}
        id={labelId}
        variant={variant}
        style={{
          borderRadius: '5px',
          border: '1px solid gray',
          padding: '0 3%',
          ...(style ? style : {}),
          ...(!!value?.length
            ? {
                backgroundColor: 'rgba(147, 250, 165)',
              }
            : {}),
        }}
        rows={rows}
        required={required}
        value={value || ''}
        inputProps={{ maxLength: maxLength }}
        onChange={(e: any) => onChange(e)}
        multiline={multiline}
      />
    </FormControl>
  );
};

export const MultiAutocompleteInput: React.FC<
  TextInputProps & {
    onDelete: (option: any) => void;
  }
> = ({
  title,
  labelId,
  value = [],
  options = [],
  onChange,
  onDelete,
  maxLength,
  tagLimit,
  freeSolo = false,
  variant = 'standard',
  onInputChange,
  onInputBlur,
}) => {
  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        freeSolo={freeSolo}
        limitTags={tagLimit}
        options={options.filter(
          ({ id }: { id: string }) => !value.includes(id),
        )}
        value={value}
        {...(!freeSolo && {
          getOptionLabel: (option: any) => option.name,
        })}
        style={{
          borderRadius: '5px',
          border: '1px solid gray',
          padding: '5px',
          ...(!!value.length
            ? {
                backgroundColor: 'rgba(147, 250, 165)',
              }
            : {}),
        }}
        renderTags={value =>
          value.map((option, index) => (
            <Chip
              key={`${labelId}_${option}_${index}`}
              variant="outlined"
              label={
                options?.find((product: any) => product.id === option)?.name ||
                option
              }
              size="small"
              onDelete={() => onDelete(option)}
            />
          ))
        }
        onChange={(e: any, values: any[]) =>
          onChange(
            freeSolo
              ? values
              : values.map(value =>
                  typeof value === 'number' ? value : value.id,
                ),
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            variant={variant}
            label={title}
            onChange={e => onInputChange?.(e.target.value)}
            onBlur={onInputBlur}
            inputProps={
              freeSolo
                ? { ...params.inputProps, maxLength: maxLength }
                : { ...params.inputProps }
            }
          />
        )}
      />
    </FormControl>
  );
};
