import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  AutocompleteInput,
  Button,
  ReferenceInput,
  SimpleForm,
  required,
  useCreate,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Send } from '@mui/icons-material';

const useStyles = makeStyles({
  dialog: {
    '& .MuiDialog-paper': {
      width: '1600px',
    },
  },
});

const SendEmailButton = (props: any) => {
  const notify = useNotify();
  const { email, campaignId, onSuccess, fullName } = props;
  const firstName = fullName?.split(' ')?.[0];
  const lastName = fullName?.split(' ')?.[1];

  const [sendBatch, { isLoading: sendBatchIsLoading }] = useCreate(
    'batches/:id/send',
    {
      data: { campaignLaunchDate: 'now' },
    },
  );

  const [create, { isLoading }] = useCreate(
    'batches',
    {
      data: {
        campaignId,
        maxBatchSize: 1,
        members: [
          {
            email,
            firstName,
            lastName,
          },
        ],
      },
    },
    {
      onSuccess: ({ id }) => {
        notify(`Campaign Email Sent`, { type: 'success' });
        sendBatch(`batches/${id}/send`, {
          data: { campaignLaunchDate: 'now' },
        });
        onSuccess();
      },
      onError: (err: { body: { message?: string } }) => {
        const message =
          err.body &&
          err.body.message &&
          err.body.message !== 'Internal server error'
            ? err.body.message
            : 'Failed to send campaign email';
        notify(message, { type: 'error' });
      },
    },
  );
  return (
    <Button
      label="Send"
      onClick={() =>
        create('batches', {
          data: {
            campaignId,
            maxBatchSize: 1,
            members: [
              {
                email,
                firstName,
                lastName,
              },
            ],
          },
        })
      }
      style={{ marginLeft: '1rem', marginBottom: '1rem' }}
    >
      {isLoading || sendBatchIsLoading ? <CircularProgress /> : <Send />}
    </Button>
  );
};

export const SingleCampaignModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const record = useRecordContext();

  const [selectedBrand, setSelectedBrand] = useState<number | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="form-dialog-title">Send Single Campaign</DialogTitle>

      <DialogContent>
        <SimpleForm
          redirect={false}
          toolbar={
            <SendEmailButton
              email={record.email}
              fullName={record?.fullname}
              brandId={selectedBrand}
              campaignId={selectedCampaign}
              onSuccess={() => {
                onClose();
                setSelectedBrand(null);
                setSelectedCampaign(null);
              }}
            />
          }
        >
          <ReferenceInput
            source="brandId"
            reference="brands"
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText="name"
              onChange={e => setSelectedBrand(parseInt(e) ?? null)}
              style={{ width: '100%' }}
            />
          </ReferenceInput>

          <ReferenceInput
            source="campaignId"
            reference="campaigns"
            filter={{
              isActive: true,
              ...(selectedBrand ? { brandId: selectedBrand } : {}),
            }}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={100}
            alwaysOn
          >
            <AutocompleteInput
              optionText="title"
              style={{ width: '100%' }}
              onChange={e => setSelectedCampaign(e ?? null)}
              isRequired
              validate={required()}
            />
          </ReferenceInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  );
};
