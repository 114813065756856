import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { map, isEmpty } from 'lodash';

type Breakdown = {
  [key: string]: {
    type: string;
    subtype: string;
    quote: string;
    id: string;
    takeId: string;
  }[];
};
export const getIsEmptyForSelectedTake = ({
  takeId,
  breakdown,
}: {
  takeId?: string;
  breakdown: Breakdown;
}) => {
  if (!takeId) return false;

  return !Object.values(breakdown).some(breakdowns =>
    breakdowns.some(breakdown => breakdown.takeId === takeId),
  );
};

const SceneIntelligenceDialog: React.FC<{
  open: boolean;
  onClose: Function;
  breakdown: Breakdown;
  selectedTakeId?: string;
}> = ({ open, onClose, breakdown, selectedTakeId }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="trans-dialog-title"
      fullWidth
    >
      <DialogTitle id="trans-dialog-title">Scene Intelligence</DialogTitle>
      <DialogContent>
        {getIsEmptyForSelectedTake({ takeId: selectedTakeId, breakdown }) ? (
          <div style={{ textAlign: 'center', fontSize: 18 }}>
            No scene intelligence for selected take 😕
          </div>
        ) : (
          map(breakdown, (breakdowns, key) => {
            if (isEmpty(breakdowns)) return;

            return (
              <div key={key}>
                {map(breakdowns, ({ quote, subtype, type, takeId }, idx) => {
                  if (selectedTakeId && takeId !== selectedTakeId) return null;

                  return (
                    <div key={idx}>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <b>{type}</b> <b>{subtype}</b>
                      </div>
                      <p style={{ whiteSpace: 'pre-line' }}>{quote}</p>
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </DialogContent>
    </Dialog>
  );
};
export default SceneIntelligenceDialog;
