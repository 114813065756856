import {
  BulkExportButton,
  CreateButton,
  ExportButton,
  TopToolbar,
  useListContext,
} from 'react-admin';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import useDownloadWithStream from '../../hooks/useDownloadWithStream';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  flex: {
    display: 'flex',
  },
}));

export const VideoAdsBulkActionButtons: React.FC = () => {
  const [format, setFormat] = useState('');
  const classes = useStyles();
  const listContext = useListContext();
  const { selectedIds } = listContext;
  const { downloadVideoAdsWithStream } = useDownloadWithStream();

  return selectedIds && selectedIds.length > 0 ? (
    <div className={classes.flex}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Format</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={format}
          onChange={event => setFormat(event.target.value as string)}
        >
          <MenuItem value="">Any</MenuItem>
          <MenuItem value="mp4">mp4</MenuItem>
          <MenuItem value="mov">mov</MenuItem>
        </Select>
      </FormControl>
      <BulkExportButton
        label="Download"
        exporter={downloadVideoAdsWithStream}
      />
    </div>
  ) : (
    <TopToolbar>
      <CreateButton label="Upload" />
      <ExportButton />
    </TopToolbar>
  );
};
