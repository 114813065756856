import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const BatchMembersPanel: React.FC = ({
  record: { members = [] } = {},
}: any) => (
  <TableContainer component={Paper}>
    <Table aria-label="sample table">
      <TableHead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map(
          (member: { email: string; firstName: string; lastName: string }) => (
            <TableRow key={member.email}>
              <TableCell>{member.email}</TableCell>
              <TableCell>{member.firstName}</TableCell>
              <TableCell>{member.lastName}</TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default BatchMembersPanel;
