import { FunctionField } from 'react-admin';

const JsonField = (props: any) => (
  <FunctionField
    {...props}
    render={(record: any) => JSON.stringify(record[props.source], null, 2)}
  />
);

export default JsonField;
