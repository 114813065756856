// TODO: refactor this component to have an injectable header
// TODO: rename component to Card

import React, { useEffect, useMemo, useState } from 'react';
import { Button, RaRecord } from 'react-admin';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { galleryStyles } from './VideoSessions/MediaGallery';
import QuickEditTake from './VideoSessions/QuickEditTake';
import { Typography } from '@mui/material';
import { UploadingState } from '../consts';
import { getAssetUploadText } from '../utils/assets';

interface Props {
  itemType: ItemType;
  Footer?: React.ComponentType<any>;
  getTakeUrl?: (record: RaRecord) => string;
  record: RaRecord;
  isSelected?: boolean;
  onToggleItem: (id: any) => void;
  size?: 'xs' | 'sm' | 'lg';
  controlsList?: string;
  selectedVideoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  showTakeNavigation?: boolean;
  takeHeaderControls?: boolean;
  refreshTakes?: () => void;
  disableTakeVetting: boolean;
}

export enum ItemType {
  TAKE = 'TAKE',
  VIDEOAD = 'VIDEOAD',
}

const GalleryItem: React.FC<Props> = ({
  itemType,
  Footer,
  record,
  onToggleItem,
  isSelected = false,
  size = 'lg',
  controlsList,
  selectedVideoRef,
  showTakeNavigation = true,
  takeHeaderControls = true,
  refreshTakes,
  disableTakeVetting,
}) => {
  const styles = galleryStyles();
  const [takeIdx, setTakeIdx] = useState<number>(0);

  const recordTakes = useMemo(() => {
    const takes = [];
    if (record.videoScenes && record.videoScenes.length > 0) {
      const sortedBySequence = record.videoScenes.sort(
        (s1: any, s2: any) => s2.step?.funnelSequence - s1.step?.funnelSequence,
      );
      for (const scene of sortedBySequence) {
        if (scene.takes && scene.takes.length > 0) {
          const latestTake = scene.takes.reduce((acc: any, take: any) => {
            if (acc.createdAt < take.createdAt) {
              return take;
            }
            return acc;
          }, scene.takes[0]);
          takes.push(latestTake);
        }
      }
    }
    return takes;
  }, [record]);

  useEffect(() => {
    if (recordTakes && recordTakes.length > 1) {
      setTakeIdx(recordTakes.length - 1);
    }
  }, [recordTakes, setTakeIdx]);

  const item = useMemo(
    () =>
      recordTakes?.length
        ? recordTakes[takeIdx]
        : record.latestVersion || record,
    [record, takeIdx, recordTakes],
  );

  const url = useMemo(() => {
    switch (itemType) {
      case ItemType.TAKE:
        return item?.url;
      case ItemType.VIDEOAD:
        return item?.url;
      default:
        return item?.url;
    }
  }, [item, itemType]);

  const poster = useMemo(() => item?.posterURL, [item]);

  const getClassBySize = (size: string) => {
    switch (size) {
      case 'xs':
        return styles.videoContainerXS;
      case 'sm':
        return styles.videoContainerSmall;
      default:
        return styles.videoContainerLarge;
    }
  };

  return (
    <div key={record.id} className={styles.tile}>
      <div
        key={`${record.id}_checkbox`}
        className={[styles.videoContainer, getClassBySize(size)].join(' ')}
      >
        <div className={styles.actionsContainer}>
          {onToggleItem && (
            <input
              key={record.id}
              type="checkbox"
              className={styles.checkbox}
              onChange={() => onToggleItem(record.id)}
              checked={isSelected}
            />
          )}

          {takeHeaderControls && (
            <div className={styles.btns}>
              <QuickEditTake
                record={item}
                showEmoji
                disableTakeVetting={disableTakeVetting}
              />

              {showTakeNavigation && (
                <>
                  <Button
                    disabled={takeIdx === 0}
                    onClick={() => setTakeIdx(takeIdx - 1)}
                  >
                    <ArrowBackIcon />
                  </Button>
                  <Button
                    disabled={
                      !recordTakes || takeIdx === recordTakes.length - 1
                    }
                    onClick={() => setTakeIdx(takeIdx + 1)}
                  >
                    <ArrowForwardIcon />
                  </Button>
                </>
              )}
            </div>
          )}
        </div>

        {item?.type === 'IMAGE' ? (
          <img
            src={poster || item?.url}
            className={styles.image}
            alt="take imag"
          />
        ) : (
          <>
            <video
              ref={r =>
                selectedVideoRef ? (selectedVideoRef.current = r) : null
              }
              key={`${record.id}_video`}
              className={styles.video}
              src={url}
              poster={poster}
              preload="metadata"
              controls
              controlsList={controlsList}
              height="100%"
              width="100%"
            >
              {item?.subtitlesURL && <track src={item.subtitlesURL} default />}
            </video>
            {item?.assetId && !url && !poster && (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                  gap: '10px',
                }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    width: 'fit-content',
                    margin: '0 auto',
                  }}
                >
                  {getAssetUploadText(item?.assetUploadState)}
                </Typography>
                {item?.assetUploadState !== UploadingState.UPLOADED && (
                  <Button
                    style={{ width: 'fit-content', margin: '0 auto' }}
                    label={`Press to refresh`}
                    onClick={() => refreshTakes && refreshTakes()}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {Footer && <Footer currentTakeIndex={takeIdx} record={record} />}
    </div>
  );
};

export default GalleryItem;
