import React from 'react';
import { Link } from 'react-router-dom';
import {
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DeleteButton,
  EditButton,
  FormTab,
  FormTabProps,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Link as LinkIcon, Add } from '@mui/icons-material';

const AddStepButton = ({ target }: any) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      style={{ marginTop: '20px' }}
      component={Link}
      variant="contained"
      to={`/steps/create?${target}=${record.id}`}
      label="Create step"
      title="Create step"
    >
      <Add />
    </Button>
  );
};

const StepsFormTab: React.FC<
  FormTabProps & { target: 'campaignId' | 'campaignTemplateId' }
> = ({ target, ...rest }) => (
  <FormTab {...rest}>
    <ReferenceManyField reference="steps" target={target}>
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="type" sortable={false} />
        <FunctionField
          label="Properties"
          render={(record: any) => (
            <div>
              {record.type === 'VIDEO' && (
                <a href={record.props.url} target="_blank" rel="noreferrer">
                  <LinkIcon />
                </a>
              )}
              {record.type === 'REMINDER' && (
                <span>{record.props.eventName}</span>
              )}
              {record.type === 'TEXT' && <span>{record.props.title}</span>}
              {record.type === 'CREATOR_FORM' &&
                Object.keys(record.props)
                  .filter(field => record.props[field].show)
                  .map(
                    field =>
                      `${field}${record.props[field].required ? '*' : ''}`,
                  )
                  .join()}
            </div>
          )}
        />
        <ReferenceManyField
          reference="buttons"
          target="stepId"
          label="Buttons"
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="text" />
          </SingleFieldList>
        </ReferenceManyField>

        <BooleanField source="isRoot" sortable={false} />
        <ReferenceField
          source="nextStepId"
          reference="steps"
          sortable={false}
          label="Next Step"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="funnelSequence" sortable={false} />
        <EditButton />
        <DeleteButton mutationMode="optimistic" redirect={false} />
      </Datagrid>
    </ReferenceManyField>
    <AddStepButton target={target} />
  </FormTab>
);

export default StepsFormTab;
