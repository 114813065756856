import { Grid } from '@mui/material';
import { format } from 'date-fns';
import { TextField, DateField } from 'react-admin';

type CommentsProps = {
  comment: any;
  feedbackContentClass: string;
};

export const Comments = ({ comment, feedbackContentClass }: CommentsProps) => {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '2%',
        marginBottom: '5%',
        ...(!comment.parentId && { borderBottom: '1px solid gray' }),
      }}
    >
      <Grid
        item
        style={{
          display: 'flex',
        }}
      >
        <TextField
          record={comment}
          className={feedbackContentClass}
          source="user.firstName"
          style={{
            marginRight: '4%',
            fontWeight: 'bold',
          }}
        />
        <TextField
          record={comment}
          className={feedbackContentClass}
          source="user.lastName"
          style={{ fontWeight: 'bold' }}
        />
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          paddingBottom: '10px',
        }}
      >
        {comment?.start && (
          <p style={{ margin: 0 }}>
            Timestamp: {format(new Date(comment.start), 'mm:ss')}
          </p>
        )}
      </Grid>
      <Grid item>
        <DateField
          record={comment}
          className={feedbackContentClass}
          source="createdAt"
          showTime
        />
      </Grid>
      <Grid item>
        <TextField
          record={comment}
          className={feedbackContentClass}
          style={comment?.isSystem ? { color: 'green' } : {}}
          source="text"
        />
      </Grid>
      {comment?.children?.length > 0 && (
        <Grid item style={{ paddingLeft: '15%' }}>
          {comment.children
            .sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )
            .map((child: any) => (
              <Comments
                key={`child_${child.id}`}
                comment={child}
                feedbackContentClass={feedbackContentClass}
              />
            ))}
        </Grid>
      )}
    </div>
  );
};
