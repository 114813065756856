import { Button, useGetOne, useNotify } from 'react-admin';
import { useState, useEffect } from 'react';
import {
  MultiAutocompleteInput,
  SingleTextInput,
} from '../../CustomInputs/TextField';
import { generateQuestions } from '../../../providers/dataProvider';
import { Box, CircularProgress } from '@mui/material';
import { MultiSelect, SingleSelect } from '../../CustomInputs/Select';
import { EmotionalScale, ValueScale, Tones } from '../../../consts';

export const GptGenerator: React.FC<{
  brandId: string;
  campaignId: string;
  guidelines: any[];
  incentiveSetting: any[];
}> = ({ brandId, campaignId, incentiveSetting }) => {
  const nofity = useNotify();
  const { data: brand } = useGetOne('brands', { id: brandId });
  const [brandName, setBrandName] = useState('');
  const [brandDescription, setBrandDescription] = useState('');
  const [valuePropositions, setValuePropositions] = useState<string[]>([]);
  const [targetAudience, setTargetAudience] = useState('');
  const [theme, setTheme] = useState('');
  const [tones, setTones] = useState<string[]>([]);
  const [emotionalScale, setEmotionalScale] = useState('');
  const [valueScale, setValueScale] = useState('');
  const [incentive, setIncentive] = useState<string[]>([]);
  const [questions, setQuestions] = useState([]);
  const [isLoadingUrl, setIsLoadingUrl] = useState<boolean>(false);

  useEffect(() => {
    setBrandName(brand?.name);
    setBrandDescription(brand?.description);
    setValuePropositions(
      brand?.guidelines?.map((guideline: any) => guideline.guideline?.text),
    );
    setTargetAudience('');
    setTheme('');
    setEmotionalScale((brand: any) => {
      switch (brand?.brandKit?.emotionalScale) {
        case '0':
          return 'EMOTIONAL';
        case '0.5':
          return 'NO_PREFERENCE';
        case '1':
          return 'SCIENTIFIC';
        default:
          return 'NO_PREFERENCE';
      }
    });
    setValueScale((brand: any) => {
      switch (brand?.brandKit?.valueScale) {
        case '0':
          return 'VALUE_DIFF';
        case '0.5':
          return 'NO_PREFERENCE';
        case '1':
          return 'PRICE';
        default:
          return 'NO_PREFERENCE';
      }
    });
    setIncentive(
      incentiveSetting?.map((incentive: any) =>
        JSON.stringify({
          type: incentive.type,
          value: incentive.settings?.value,
        }),
      ),
    );
  }, [brand, brand?.guidelines, incentiveSetting]);

  const handleSave = async () => {
    try {
      setIsLoadingUrl(true);
      setQuestions([]);
      if (!brandDescription) {
        nofity('Missing brand description', { type: 'error' });
        setIsLoadingUrl(false);
        return;
      }
      const response = await generateQuestions(
        campaignId,
        brandName,
        brandDescription,
        valuePropositions,
        targetAudience,
        theme,
        tones,
        emotionalScale,
        valueScale,
        incentive,
      );
      setQuestions(response.output.questions.join('\n'));
      setIsLoadingUrl(false);
    } catch (error) {
      setIsLoadingUrl(false);
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '80%',
      }}
    >
      <SingleTextInput
        value={brandName}
        title={'Brand Name'}
        labelId={'Brand Name'}
        onChange={event => setBrandName(event.target.value)}
        style={{
          marginBottom: '0%',
          height: '55px',
          paddingLeft: '5px',
        }}
      />
      <SingleTextInput
        value={brandDescription}
        title={'Brand Description'}
        labelId={'Brand Description'}
        onChange={event => setBrandDescription(event.target.value)}
        style={{
          marginBottom: '0%',
          height: '55px',
          paddingLeft: '5px',
        }}
      />
      <MultiAutocompleteInput
        freeSolo={true}
        value={valuePropositions}
        title={'Value Propositions'}
        labelId={'Value Propositions'}
        onChange={value => {
          setValuePropositions(value);
        }}
        onDelete={(value: string[]) => {
          setValuePropositions(
            valuePropositions.filter(
              (valueProposition: any) => valueProposition !== value,
            ),
          );
        }}
      />
      <SingleTextInput
        value={targetAudience}
        title={'Target Audience'}
        labelId={'Target Audience'}
        onChange={event => setTargetAudience(event.target.value)}
        style={{
          marginBottom: '0%',
          height: '55px',
          paddingLeft: '5px',
        }}
      />
      <SingleTextInput
        value={theme}
        title={'Theme'}
        labelId={'Theme'}
        onChange={event => setTheme(event.target.value)}
        style={{
          marginBottom: '0%',
          height: '55px',
          paddingLeft: '5px',
        }}
      />
      <MultiSelect
        title="Tones"
        labelId="videoTones"
        onChange={event => {
          setTones(event.target.value);
        }}
        options={Tones}
        value={tones}
        style={{
          marginBottom: '0%',
          height: '55px',
          alignItems: 'flex-end',
        }}
      />

      <SingleSelect
        value={emotionalScale}
        title={'Emotional Scale'}
        labelId={'Emotional Scale'}
        onChange={event => setEmotionalScale(event.target.value)}
        options={EmotionalScale}
        style={{
          marginBottom: '0%',
          height: '55px',
          alignItems: 'flex-end',
        }}
      />

      <SingleSelect
        value={valueScale}
        title={'Value Scale'}
        labelId={'Value Scale'}
        onChange={event => setValueScale(event.target.value)}
        options={ValueScale}
        style={{
          marginBottom: '0%',
          height: '55px',
          alignItems: 'flex-end',
        }}
      />
      <MultiAutocompleteInput
        freeSolo={true}
        value={incentive}
        title={'Incentives'}
        labelId={'Incentives'}
        onChange={event => {
          setIncentive(event);
        }}
        onDelete={(value: string[]) => {
          setIncentive(
            incentive.filter((incentive: any) => incentive !== value),
          );
        }}
      />
      {isLoadingUrl ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Button label="Generate" onClick={handleSave} />
      )}

      <SingleTextInput
        multiline={true}
        value={questions}
        title={'Questions'}
        labelId={'Questions'}
        onChange={event => {
          setIncentive(event);
        }}
      />
    </div>
  );
};

export default GptGenerator;
