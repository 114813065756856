import { Button, Identifier } from 'react-admin';
import { useUpdateShow } from './hooks/useUpdateShow';
import { Visibility, VisibilityOff } from '@mui/icons-material';
export type ShowToggleProps = {
  isHidden: boolean;
  id: Identifier;
  showLabel?: string;
  hideLabel?: string;
  entityName: string;
  resource: string;
};

export const ToggleShowHideButton = ({
  isHidden,
  id,
  entityName,
  resource,
  showLabel = 'show',
  hideLabel = 'hide',
}: ShowToggleProps) => {
  const { updateVideoSession: update, resourceData } = useUpdateShow({
    isHidden,
    id,
    entityName,
    resource,
  });

  const isShown = resourceData ? !resourceData.isHidden : !isHidden;

  return (
    <Button
      style={{ paddingBottom: '0' }}
      label={isShown ? hideLabel : showLabel}
      onClick={() => update()}
    >
      {isShown ? <VisibilityOff /> : <Visibility />}
    </Button>
  );
};
