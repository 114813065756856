import React from 'react';
import { Tooltip } from '@mui/material';
import { ReactComponent as macSvg } from '../assets/os/mac.svg';
import { ReactComponent as windowsSvg } from '../assets/os/windows.svg';
import { ReactComponent as androidSvg } from '../assets/os/android.svg';

type Props = {
  osName: string;
};

const osAssets: Record<string, React.FunctionComponent> = {
  mac: macSvg,
  ios: macSvg,
  android: androidSvg,
  windows: windowsSvg,
};

const OsDisplay: React.FC<Props> = (props) => {
  const { osName } = props;
  const Element = osAssets[osName?.toLocaleLowerCase()];

  if (Element === undefined) return <span>N/A</span>;

  return (
    <Tooltip title={osName}>
      <Element />
    </Tooltip>
  );
};

export default OsDisplay;
