import React from 'react';
import {
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  SelectInput,
  minLength,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import { SupportedLocalesChoices } from '../../../consts';
import { cleanEmptyValues } from '../../../utils/general';
import { stickyToolbar } from '../../../resources/toolbar';

const brandIdValidators = [required()];
const campaignNameValidators = [
  required(),
  minLength(1, "Campaign name can't be empty"),
];

export const CampaignCreate: React.FC = (props: any) => (
  <Create
    {...props}
    transform={cleanEmptyValues}
    className={stickyToolbar().root}
  >
    <SimpleForm>
      <Typography variant="h5">Campaign creation</Typography>
      <ReferenceInput
        source="brandId"
        reference="brands"
        isRequired
        validate={brandIdValidators}
        perPage={100}
      >
        <AutocompleteInput optionText="name" validate={brandIdValidators} />
      </ReferenceInput>
      <TextInput
        label="Name your campaign"
        source="title"
        validate={campaignNameValidators}
      />
      <DateInput source="startDate" />
      <DateInput source="endDate" />
      <NumberInput
        label="Maximum incoming videos"
        source="videoLimit"
        isRequired
        defaultValue={1000}
        validate={[required()]}
      />
      <SelectInput
        source="language"
        defaultValue="en"
        choices={SupportedLocalesChoices}
        optionValue="shortId"
        optionText="shortEngName"
        isRequired
        validate={[required()]}
      />
      <BooleanInput source="autoModerate" defaultValue={false} />
      <BooleanInput source="avoidDesktop" defaultValue={false} />
      <BooleanInput
        source="isAutoConsent"
        label="Should give automatic consent"
        defaultValue={true}
      />
      <Typography variant="h6">Templates</Typography>
      <ReferenceInput
        source="campaignTemplateId"
        reference="templates"
        label="Step Template"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
