import StepPreview from '../../../resources/step-preview';
import { useGetList, useRecordContext } from 'ra-core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationArrows from '../../NavigationArrows';

const StepPreviewWithNavigation = ({ formData }: { formData: any }) => {
  const record = useRecordContext();
  const navigate = useNavigate();

  const {
    data: steps,
    isLoading,
    isError,
  } = useGetList(
    'steps',
    { filter: { campaignId: record?.campaignId } },
    {
      enabled: !!record?.campaignId,
    },
  );
  const sortedSteps = useMemo(() => {
    if (steps) {
      return steps.sort(
        (a, b) =>
          (a.funnelSequence || a.isRoot) - (b.funnelSequence || b.isRoot),
      );
    }
    return [];
  }, [steps]);

  const currentStepIndex = useMemo(() => {
    return sortedSteps.findIndex(step => step.id === record?.id);
  }, [record?.id, sortedSteps]);

  return (
    <div
      style={
        formData.id
          ? { display: 'flex', flexDirection: 'column' }
          : { display: 'none' }
      }
    >
      {!isLoading &&
        !isError &&
        !!sortedSteps?.length &&
        currentStepIndex !== -1 && (
          <NavigationArrows
            currentIndex={currentStepIndex}
            sortedData={sortedSteps}
            onNextClick={() =>
              navigate(`/steps/${sortedSteps[currentStepIndex + 1].id}`)
            }
            onPrevClick={() =>
              navigate(`/steps/${sortedSteps[currentStepIndex - 1].id}`)
            }
          />
        )}
      <a
        href={`${process.env.REACT_APP_CAMERA_URL}/${formData.campaignId}/step/${formData.id}?preview=true`}
        target="_blank"
        rel="noreferrer"
      >
        Open preview
      </a>
      <StepPreview
        step={formData}
        width={375}
        enableFakeCamera={true}
        enablePreview={true}
      />
    </div>
  );
};

export default StepPreviewWithNavigation;
