import { Grid } from '@mui/material';
import { ReferenceInput, SelectInput } from 'react-admin';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const BrandCampaignEmailsInput = ({
  showCampaigns = false,
}: {
  showCampaigns?: boolean;
}) => {
  const { control, watch, setValue } = useFormContext();
  const campaignTemplateId = watch('campaignTemplateId');
  const campaignId = watch('campaignId');

  useEffect(() => {
    function resetFields() {
      setValue('emailId', null);
      setValue('campaignTemplateId', null);
    }
    return () => resetFields();
  }, [setValue, showCampaigns]);
  return (
    <Grid item xs={12}>
      {!showCampaigns && (
        <ReferenceInput
          width="70%"
          reference="templates"
          source="campaignTemplateId"
          control={control}
        >
          <SelectInput fullWidth label="Templates" optionText="name" />
        </ReferenceInput>
      )}
      {showCampaigns && (
        <ReferenceInput
          width="70%"
          reference="campaigns"
          source="campaignId"
          filter={{ campaignTemplateId }}
          control={control}
        >
          <SelectInput fullWidth label="Campaigns" optionText="title" />
        </ReferenceInput>
      )}
      <ReferenceInput
        width="70%"
        reference="email"
        filter={showCampaigns ? { campaignId } : { campaignTemplateId }}
        source="emailId"
        control={control}
      >
        <SelectInput
          fullWidth
          label="Emails"
          optionText="name"
          disabled={showCampaigns ? !campaignId : !campaignTemplateId}
        />
      </ReferenceInput>
    </Grid>
  );
};

export default BrandCampaignEmailsInput;
