import React, { useEffect, useMemo } from 'react';
import { Button, useNotify, useRecordContext } from 'react-admin';
import SendIcon from '@mui/icons-material/Send';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import MuiTextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  getCampaignById,
  sendBatch,
  testBatch,
} from '../../../providers/dataProvider';
import UserEmailsChipInput from '../../UserEmailsChipInput';

const SendBatchButton = (props: any) => {
  const [open, setOpen] = React.useState<boolean>(false); // Controls modal
  const [campaignLaunchDate, setCampaignLaunchDate] = React.useState<
    string | null
  >(null);
  const [testEmail, setTestEmail] = React.useState<string>('');
  const record = useRecordContext(props);
  const [campaign, setCampaign] = React.useState<any>({});
  const [error, setError] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    async function getCampaign() {
      try {
        if (record.campaignId) {
          const res = await getCampaignById(record.campaignId);
          setCampaign(res.json);
        }
      } catch (e: any) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }

    getCampaign();
  }, [record?.campaignId]);

  const notify = useNotify();

  const senderText = useMemo(() => {
    let text = 'N/A';
    if (campaign?.brand?.emailSender) {
      const { fromName, fromEmail, verified } = campaign?.brand.emailSender;
      text = `${fromName} - ${fromEmail}`;
      if (campaign?.brand.emailProvider === 'CAST_EMAIL') {
        text += verified ? 'Verified' : 'Not Verified';
      }
    }
    return text;
  }, [campaign]);

  const isCampaignRequestConfigured = useMemo(
    () =>
      campaign?.emails?.find((e: any) => e.type === 'CAMPAIGN_REQUEST') !==
      undefined,
    [campaign],
  );
  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Campaign not found</span>;
  }

  const { brand: { name: brandName = '' } = {} } = campaign;

  const onSendClick = async (sendAt: string) => {
    try {
      await sendBatch(record.id.toString(), sendAt);
      notify(`Batch Sent`, { type: 'success' });
      setOpen(false);
    } catch (err: any) {
      console.error(err);
      const message =
        (err.body && err.body.message) ||
        `Failed to create email campaign. Check the console for errors`;
      notify(message, { type: 'error' });
    }
  };

  return (
    <div>
      <Button
        label="Test &amp; Send"
        onClick={() => setOpen(true)}
        disabled={!['CREATED', 'ERROR'].includes(record.status)}
      >
        <SendIcon />
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Ready to send?</DialogTitle>
        <DialogContent>
          <ul>
            <li key="campaign">
              <b>Campaign:</b> {campaign.title}
            </li>
            <li key="brand">
              <b>Brand:</b> {brandName}
            </li>
            <li key="emails">
              <b>Emails:</b>
              <ul>
                <li key="sender">Sender: {senderText}</li>
                {campaign.emails.map((email: any) => (
                  <li key={email.id}>
                    <div>Type: {email.type}</div>
                    <div>Subject: {email.subject}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: email.preview,
                      }}
                      style={{ border: '1px solid black' }}
                    />
                  </li>
                ))}
              </ul>
            </li>
            <li key="batch">
              <b>Batch:</b> "Batch No. {record.sequence}" of size{' '}
              {record.members.length}
            </li>
            <li key="steps">
              <b>Steps:</b>{' '}
              <ol>
                {campaign.steps
                  .sort(
                    (a: any, b: any) =>
                      (a?.funnelSequence || 0) - (b?.funnelSequence || 0),
                  )
                  .map((step: any) => (
                    <li key={step.id}>
                      {step.name} ({step.type})
                    </li>
                  ))}
              </ol>
            </li>
            <li key="start-date">
              <b>Start date:</b> {campaign.startDate?.substring(0, 10) || 'N/A'}
            </li>
            <li key="end-date">
              <b>End date:</b> {campaign.endDate?.substring(0, 10) || 'N/A'}
            </li>
            <li key="max-vids">
              <b>Max Videos:</b> {campaign.videoLimit || 'N/A'}
            </li>
          </ul>
          <MuiTextField
            id="datetime-local"
            label="Launch Campaign At (EST)"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => setCampaignLaunchDate(`${e.target.value}-05:00`)}
          />
          <br />

          <UserEmailsChipInput
            accountId={campaign?.brand.accountId}
            onChange={value => setTestEmail(value || '')}
            selectedEmails={testEmail?.length > 0 ? [testEmail] : []}
          />
        </DialogContent>
        <DialogActions>
          <Button label="Cancel" onClick={() => setOpen(false)} />
          <Button
            label="Test"
            disabled={
              !(
                isCampaignRequestConfigured &&
                testEmail &&
                testEmail.length > 0
              )
            }
            onClick={async () => {
              try {
                await testBatch(record.id.toString(), testEmail || '');
                notify('Test Email Sent', { type: 'success' });
              } catch (err) {
                console.error(err);
                notify(
                  `Failed to send test email. Check the console for errors`,
                  { type: 'error' },
                );
              }
            }}
          />
          <Button
            label="Schedule Send"
            disabled={!(isCampaignRequestConfigured && campaignLaunchDate)}
            onClick={() => onSendClick(campaignLaunchDate || '')}
          >
            <ScheduleSendIcon />
          </Button>
          <Button
            label="Send Now"
            disabled={!isCampaignRequestConfigured}
            onClick={() => onSendClick('now')}
          >
            <SendIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SendBatchButton;
