import {
  Create,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  required,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { Typography } from '@mui/material';
import { isHexColor } from '../consts';
import { EmptyTextualFieldProps, useQuery } from '../utils/general';

/*
  This component was used in the past to style Camera subtitles, but the feature does not work as it should.
  Support was removed in this PR - https://github.com/CastLTD/admin/pull/277
*/
export const SubtitlesStyleInput = (props: any) => {
  const { isOverride = false } = props;
  const sourcePrefix = props.sourcePrefix || props.source;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        maxWidth: '700px',
      }}
    >
      {!isOverride && (
        <TextInput
          source={`${sourcePrefix ? sourcePrefix + '.' : ''}backgroundColor`}
          label="Background Color"
          validate={isHexColor()}
          {...EmptyTextualFieldProps}
        />
      )}
      <TextInput
        source={`${sourcePrefix ? sourcePrefix + '.' : ''}color`}
        label="Font Color"
        validate={isHexColor()}
        {...EmptyTextualFieldProps}
      />
      {!isOverride && (
        <NumberInput
          source={`${sourcePrefix ? sourcePrefix + '.' : ''}width`}
          label="Width (%)"
        />
      )}
      {!isOverride && (
        <NumberInput
          source={`${sourcePrefix ? sourcePrefix + '.' : ''}padding`}
          label="Padding (px)"
        />
      )}
      <NumberInput
        source={`${sourcePrefix ? sourcePrefix + '.' : ''}lineHeight`}
        label="Line Height (px)"
      />
      <NumberInput
        source={`${sourcePrefix ? sourcePrefix + '.' : ''}fontSize`}
        label="Font Size (px)"
      />
      <NumberInput
        source={`${sourcePrefix ? sourcePrefix + '.' : ''}fontWeight`}
        label="Font Weight"
      />
      {!isOverride && (
        <NumberInput
          source={`${sourcePrefix ? sourcePrefix + '.' : ''}borderRadius`}
          label="Border Radius (px)"
        />
      )}
      <NumberInput
        source={`${sourcePrefix ? sourcePrefix + '.' : ''}letterSpacing`}
        label="Letter Spacing (px)"
      />
      {!isOverride && (
        <NumberInput
          source={`${sourcePrefix ? sourcePrefix + '.' : ''}opacity`}
          label="Opacity"
          step={0.01}
          min={0}
          max={1}
        />
      )}
    </div>
  );
};

const LinesInput = (props: any) => (
  <ArrayInput source="lines">
    <SimpleFormIterator>
      <TextInput source="text" label="Text" fullWidth multiline />
      <Typography variant="h6">Override style</Typography>
      <SubtitlesStyleInput isOverride />
    </SimpleFormIterator>
  </ArrayInput>
);

export const SubtitleEdit = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (response: any) => {
    notify(`Changes saved`);
    redirect(`/steps/${response.data.stepId}/2`);
    refresh();
  };
  return (
    <Edit onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
      <SimpleForm>
        <ReferenceInput source="stepId" reference="steps" disabled label="Step">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="start" isRequired validate={[required()]} />
        <NumberInput source="end" isRequired validate={[required()]} />
        <LinesInput />
      </SimpleForm>
    </Edit>
  );
};

export const SubtitleCreate = (props: any) => {
  const query = useQuery();
  const stepId = query.get('stepId');

  const redirect = stepId ? `/steps/${stepId}/2` : false;
  return (
    <Create {...props}>
      <SimpleForm defaultValues={{ stepId }} redirect={redirect}>
        <ReferenceInput source="stepId" reference="steps" disabled label="Step">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="start" isRequired validate={[required()]} />
        <NumberInput source="end" isRequired validate={[required()]} />
        <LinesInput />
      </SimpleForm>
    </Create>
  );
};
