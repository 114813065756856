import axios, { AxiosRequestHeaders } from 'axios';
import { UploadAssetParams } from '../types';
import { onAssetsCompleteUpload } from '../providers/dataProvider';
import { FILE_CHUNK_SIZE, UploadingState } from '../consts';
import mime from 'mime';
import { hoursToSeconds } from 'date-fns';

const daysToSeconds = (days: number): number => {
  return hoursToSeconds(days * 24);
};
const fileHeaders = (file: File): AxiosRequestHeaders => {
  const mimeType = mime.getType(file.name) || file.type;

  return {
    'Content-Type': mimeType,
    'Content-Disposition': `attachment; filename="${file.name}"`,
    'Cache-Control': `public, max-age=${daysToSeconds(
      30,
    )}, s-maxage=${daysToSeconds(180)}, must-revalidate`,
  };
};

export const uploadToS3 = async (signedData: UploadAssetParams, file: File) => {
  const { presignedURL, presignedUrls, id: assetId, uploadId } = signedData;

  if (presignedURL) {
    await axios.put(presignedURL, file, {
      headers: fileHeaders(file),
    });
  }

  if (!!presignedUrls?.length) {
    const totalParts = presignedUrls?.length || 0;
    if (totalParts > 0) {
      const uploadPromises = Array.from({ length: totalParts }).map((_, i) =>
        uploadPart(i, file, presignedUrls || []),
      );
      const parts = await Promise.all(uploadPromises);

      await onAssetsCompleteUpload(assetId, {
        uploadId: uploadId as string,
        parts: parts,
      });
    }
  }
};

export const uploadPart = async (
  partIndex: number,
  file: File,
  presignedUrls: string[],
  abortController?: AbortController,
) => {
  const axiosAssetUpload = axios.create();
  const start = partIndex * FILE_CHUNK_SIZE;
  const end = (partIndex + 1) * FILE_CHUNK_SIZE;
  const blobPart = file?.slice(start, end);
  const presignedUrl = presignedUrls[partIndex];
  const data = await axiosAssetUpload.put(presignedUrl, blobPart, {
    signal: abortController?.signal,
    headers: fileHeaders(file),
  });
  return { eTag: data.headers.etag as string, partNumber: partIndex + 1 };
};

export const getAssetUploadText = (
  uploadingState: keyof typeof UploadingState,
) => {
  switch (uploadingState) {
    case UploadingState.UPLOADED_GENERATING_THUMBNAIL:
      return 'Generating thumbnail...';
    case UploadingState.UPLOADED_WITHOUT_THUMBNAIL:
      return "Couldn't generate thumbnail";
    case UploadingState.UPLOADED:
      return '';
    default:
      return 'Uploading version...';
  }
};
