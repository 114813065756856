import axios from 'axios';

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'dopcfdfs7';
const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || 'n1c3xqkj';
const resourceUrl = 'https://res.cloudinary.com';
const client = axios.create({
  baseURL: `https://api.cloudinary.com/v1_1/${cloudName}`,
});

export const upload = async (
  file: File,
  folder?: string,
  apiKey?: string,
  timestamp?: string,
  signature?: string,
  publicId?: string,
): Promise<{
  secure_url: string;
  public_id: string;
  bytes?: number;
  width?: number;
  height?: number;
  duration?: number;
}> => {
  const formData = new FormData();
  formData.append('file', file);
  if (folder) {
    formData.append('folder', folder);
  }
  if (apiKey) {
    formData.append('api_key', apiKey);
  }
  if (timestamp) {
    formData.append('timestamp', timestamp);
  }
  if (signature) {
    formData.append('signature', signature);
  } else {
    formData.append('upload_preset', uploadPreset);
  }

  if (publicId) {
    formData.append('public_id', publicId);
  }

  try {
    const res = await client.post<{ secure_url: string }>(
      '/video/upload',
      formData,
    );
    // @ts-ignore
    return res.data;
  } catch (err: any) {
    let detail = null;
    if (err.response) {
      const { data, status, headers } = err.response;
      detail = `Responded with ${status}, data: ${JSON.stringify(
        data,
      )}, headers: ${JSON.stringify(headers)}`;
    } else if (err.request) {
      detail = `Request: ${JSON.stringify(err.request)}`;
    } else {
      detail = err.message;
    }
    throw new Error(`Failed to upload video. ${detail}`);
  }
};

export const GIF_UPLOADS_FOLDER = `gif_source_videos`;

export const gifUrlBuilder = (
  videoFullUrl: string,
  videoPublicId: string,
  options: {
    video_sampling?: string | number;
    duration?: { start?: number; end?: number };
    scale?: { width?: number; height?: number };
    loop?: boolean;
  },
) => {
  const fullUrlParts = videoFullUrl.split('/');
  const version = fullUrlParts[fullUrlParts.indexOf(GIF_UPLOADS_FOLDER) - 1];
  const videoSampling = options?.video_sampling
    ? `vs_${options.video_sampling}`
    : 'vs_40';
  const trim = [];
  if (options?.duration?.start && options?.duration?.start > 0)
    trim.push(`so_${options?.duration?.start}`);
  if (options?.duration?.end && options?.duration?.end > 0)
    trim.push(`eo_${options?.duration?.end}`);

  const scale = ['c_scale'];
  if (options?.scale?.width && options?.scale?.width > 0)
    scale.push(`w_${options?.scale?.width}`);
  if (options?.scale?.height && options?.scale?.height > 0)
    scale.push(`h_${options?.scale?.height}`);

  const loop = options?.loop ? 'e_loop' : null;

  const urlParts = [];

  if (trim.length > 0) urlParts.push(trim.join(','));
  if (scale.length > 1) urlParts.push(scale.join(','));
  if (loop) urlParts.push(loop);
  if (videoSampling) urlParts.push(videoSampling);

  urlParts.push(version);
  urlParts.push(videoPublicId);

  return encodeURI(
    `${resourceUrl}/${cloudName}/video/upload/${urlParts.join('/')}.gif`,
  );
};
