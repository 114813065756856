import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  FormTab,
  FunctionField,
  List,
  ReferenceInput,
  required,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import StepsFormTab from '../components/Campaign/Steps/StepsFormTab';
import TemplatesActions from '../components/Templates/TemplateActions';
import EmailFormTab from '../components/Campaign/RequestTemplate/EmailFormTab';
import { Typography } from '@mui/material';
import { cleanEmptyValues } from '../utils/general';

export const TemplateList: React.FC = (props: any) => (
  <List pagination={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <FunctionField
        label="Type"
        render={(record: any) => (record.brandId ? 'My Templates' : 'Library')}
      />
      <TextField source="name" />
      <BooleanField source="isDefault" />
    </Datagrid>
  </List>
);

export const TemplateEdit = (props: any) => (
  <Edit {...props} actions={<TemplatesActions />} transform={cleanEmptyValues}>
    <TabbedForm>
      <FormTab label="summary">
        <TextInput source="id" disabled />
        <TextInput source="name" isRequired validate={[required()]} />
        <ReferenceInput
          source="brandId"
          reference="brands"
          label="Brand"
          allowEmpty
        >
          <AutocompleteInput optionText="name" disabled={true} />
        </ReferenceInput>
        <BooleanInput
          key="isDefault"
          source="isDefault"
          label="Is Default?"
          isRequired
          validate={[required()]}
        />
      </FormTab>
      <StepsFormTab label="Steps" target="campaignTemplateId" />
      <EmailFormTab label="Emails" target="campaignTemplateId" />
    </TabbedForm>
  </Edit>
);

export const TemplateCreate = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (response: any) => {
    notify(`Template created`);
    const { id } = response;
    redirect(`/templates/${id}/1`);
    refresh();
  };

  return (
    <Create
      {...props}
      mutationOptions={{ onSuccess }}
      transform={cleanEmptyValues}
    >
      <SimpleForm
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '30%',
        }}
      >
        <TextInput source="id" disabled />
        <TextInput source="name" isRequired validate={[required()]} />
        <ReferenceInput
          source="brandId"
          reference="brands"
          label="Brand"
          allowEmpty
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput
          key="isDefault"
          source="isDefault"
          label="Is Default?"
          isRequired
          validate={[required()]}
        />
        <Typography variant="h6">Create from campaign</Typography>
        <ReferenceInput
          source="campaignId"
          reference="campaigns"
          label="From campaign"
          allowEmpty
        >
          <AutocompleteInput optionText="title" fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
