import React from 'react';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { Button, useRecordContext } from 'react-admin';

const CreateBatchButton = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <Button
      style={{ marginTop: '20px' }}
      component={Link}
      variant="contained"
      to={`/batches/create?campaignId=${record.id}`}
      label="Create Batch"
      title="Create Batch"
    >
      <EmailIcon />
    </Button>
  );
};

export default CreateBatchButton;
