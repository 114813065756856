import { groupBy } from 'lodash';
import { useNotify } from 'ra-core';
import { Library } from '../consts';
import { generateDownloadStreamUrl } from '../providers/dataProvider';
import { bulkDownloadFromURLWithDelay } from '../utils/general';

const useDownloadWithStream = () => {
  const notify = useNotify();

  const downloadVideoSessionsWithStream = async (
    sessions: { id: string; campaign: { brandId: string } }[],
  ) => {
    const brandSessions = groupBy(sessions, 'campaign.brandId');
    notify('Your download will start shortly', { type: 'success' });
    downloadLibraryItemsWithStream(brandSessions, Library.UGC);
  };

  const downloadVideoAdsWithStream = async (videoAds: IVideoAd[]) => {
    const brandAds = groupBy(videoAds, 'brandId');

    downloadLibraryItemsWithStream(brandAds, Library.AD);
  };

  const downloadLibraryItemsWithStream = async (
    groupedItemsByBrand: { [key: string]: { id: string }[] },
    library: Library,
  ) => {
    const urls: { url: string }[] = [];

    await Promise.all(
      Object.entries(groupedItemsByBrand).map(async ([brandId, records]) => {
        try {
          const url = await generateDownloadStreamUrl({
            brandId,
            entityIds: records.map(({ id }) => ({ id, library })),
          });

          urls.push({ url });
        } catch (error) {
          notify(`download error:: No data found for the given request`, {
            type: 'error',
          });
        }
      }),
    );

    bulkDownloadFromURLWithDelay(urls);
  };

  return {
    downloadVideoSessionsWithStream,
    downloadVideoAdsWithStream,
  };
};
export default useDownloadWithStream;
