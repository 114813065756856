import { RaRecord, useNotify } from 'ra-core';
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  downloadCSV,
} from 'react-admin';
import { AdRequestPanel } from '../components/AdRequests/AdRequestPanel';
import { AdRequest } from '../types';
import { adRequestCsvColumns } from '../consts';
import jsonExport from 'jsonexport/dist';

const EditStatusChoices = [
  { id: 'NOT_EDITED', name: 'NOT_EDITED' },
  { id: 'IN_EDIT', name: 'IN_EDIT' },
  { id: 'EDITED', name: 'EDITED' },
];
export const AdRequestsList = () => {
  const notify = useNotify();
  const filters = [
    <ReferenceInput source="brandId" reference="brands" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>,
    <SelectInput choices={EditStatusChoices} source="status" alwaysOn />,
  ];

  const exporter = (data: AdRequest[]) => {
    const dataForExport = data.map((request: AdRequest) => {
      const {
        videoSession,
        asset,
        videoAd,
        brand,
        user,
        adObjective,
        editingStyle,
        numOfHooks,
        platforms,
        sizes,
        tones,
        createdAt,
        guidelines,
      } = request;
      return {
        videoSession,
        asset,
        videoAd,
        brand,
        user,
        adObjective,
        editingStyle,
        numOfHooks,
        platforms,
        sizes,
        tones,
        createdAt,
        guidelines:
          guidelines?.map(({ text, type }) => `${type}::${text}`).join('\n') ||
          '',
      };
    });
    try {
      jsonExport(
        dataForExport,
        {
          headers: adRequestCsvColumns,
        },
        (err, csv) => {
          if (err) {
            throw err;
          }
          downloadCSV(csv, 'requests');
        },
      );
    } catch (error: any) {
      notify(`Failed to download CSV: ${error?.message || error}`, {
        type: 'error',
      });
    }
  };

  return (
    <List
      filters={filters}
      resource="ad-requests"
      title="Ad requests"
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={exporter}
    >
      <Datagrid expand={AdRequestPanel} bulkActionButtons={false}>
        <ReferenceField
          label="Brand"
          reference="brands"
          source="brand.id"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField
          label="Created at"
          source="createdAt"
          width="50px"
          showTime
        />
        <FunctionField
          label="Submitted by"
          sortable={false}
          render={(record: RaRecord) => (
            <p>
              {record?.user?.firstName || record?.user?.lastName
                ? `${record?.user?.firstName + ' '}${record?.user?.lastName}`
                : `${record?.user?.email}`}
            </p>
          )}
        />
        <FunctionField
          label="Edit request url"
          sortable={false}
          render={(record: RaRecord) => (
            <p>
              <a
                href={`${process.env.REACT_APP_DASHBOARD_URL}/library/assets?brandId=${record.brandId}&adRequestId=${record.id}`}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </p>
          )}
        />
        <TextField label="Request status" source="status" />
      </Datagrid>
    </List>
  );
};
